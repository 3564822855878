import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '../app-info.service';
import { SchemeModel } from '../../../shared/models/scheme-model';
import { CurrentFormModel } from '../../models/current-form-model';
import { TaskModel } from '../../models/task-model';
import { CurrentFormDataModel } from '../../models/form-data-model';

@Injectable({
    providedIn: 'root'
})
export class InstanceService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    startInstance(taskData, isForm = true): Observable<TaskModel> {
        return this.http.post<TaskModel>(this.appInfo.appUrl + "api/instance/startinstance?isForm=" + isForm, JSON.stringify(taskData), this.appInfo.httpOptions);
    }

    getSchemaForm(schemaId, isReadonly) {
        let params = new HttpParams().set("schemaId", schemaId).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);
        return this.http.get(this.appInfo.appUrl + "api/instance/getschemaform", { params: params });
    }

    getCurrentForm(instanceId, processFormId, isReadonly) {
        let params = new HttpParams().set("instanceId", instanceId).set("processFormId", processFormId).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);
        return this.http.get(this.appInfo.appUrl + "api/instance/getcurrentform", { params: params });
    }
    getHistoryCurrentForm(id,instanceId, processFormId, isReadonly) {
        let params = new HttpParams().set("id", id).set("instanceId", instanceId).set("processFormId", processFormId).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);
        return this.http.get(this.appInfo.appUrl + "api/instance/gethistorycurrentform", { params: params });
    }
    saveFormFilesData(objArray, processFormId, deleteFileIds = []): Observable<any> {
        let deleteFileIdsStr = "";
        if (deleteFileIds.length > 0)
            deleteFileIdsStr = "&deleteFileIds=" + deleteFileIds;
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/savefiledata?processFormId=" + processFormId + deleteFileIdsStr, JSON.stringify(objArray), this.appInfo.httpOptions);
    }
    getFormFilesData(objArray): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/getfiledata", JSON.stringify(objArray), this.appInfo.httpOptions);
    }
    saveFormData(data, schemaId, curentCustomerFormId, customerButton, curentCustomer, curentPrice, curentHirePurchase, curentPaymentDescription, paymentProvider): Observable<CurrentFormDataModel> {
        // curentCustomerFormId, customerButton, curentCustomer, curentPrice


        data.CustomerFormId = curentCustomerFormId.toString();
        data.CustomerFormComponent = JSON.stringify(customerButton);
        data.Customer = curentCustomer.toString();
        data.Price = curentPrice.toString();
        data.HirePurchase = curentHirePurchase.toString();
        data.PaymentProvider = paymentProvider.toString();
        data.PaymentDescription = curentPaymentDescription.toString();
        return this.http.post<CurrentFormDataModel>(this.appInfo.appUrl + "api/instance/savedata?schemaId=" + schemaId, JSON.stringify(data), this.appInfo.httpOptions);
    }

    liveOpen(meetingRoomName, startDate, hostUrl, participantUrl, meetingType, processFormId): Observable<any> {
        // curentCustomerFormId, customerButton, curentCustomer, curentPrice

        let data = {
            MeetingRoomName: meetingRoomName,
            StartDate: startDate,
            HostUrl: hostUrl,
            ParticipantUrl: participantUrl,
            MeetingType: meetingType,
            UserId: parseInt(this.appInfo.currentUser.id),
            ProcessFormId: parseInt(processFormId)
        };
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/liveopenandcreate", JSON.stringify(data), this.appInfo.httpOptions);
    }

    getHistory(instanceId) {
        let params = new HttpParams().set("instanceId", instanceId).set("userId", this.appInfo.currentUser.id);
        return this.http.get(this.appInfo.appUrl + "api/instance/gethistory", { params: params });
    }

    changeFormAvability(formDataId, isAvalible) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("formDataId", formDataId).set("isAvalible", isAvalible);
        return this.http.get(this.appInfo.appUrl + "api/instance/changeformavability", { params: params });
    }

    changeMenuFormAvability(formId, pageId, formDataId, isAvalible) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("formId", formId).set("nodeKey", pageId).set("formDataId", formDataId).set("isAvalible", isAvalible);
        return this.http.get(this.appInfo.appUrl + "api/instance/changemenuformavability", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
