import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-radiogroup',
    templateUrl: './radiogroup.component.html',
    styleUrls: ['./radiogroup.component.css']
})
export class DxRadiogroupComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    layouts = [{ "name": "Yatay", "value": "horizontal" }, { "name": "Dikey", "format": "vertical" }];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showItemsPopup: boolean = false;

    constructor(private eventEmitterService: EventEmitterService, public componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
         
        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
                 
                this.changeDetectorRef.detectChanges();
            }
        });

        let _val = this.data.value;
        if (_val) {
            //if (_val.__KEY__) {
            //    this.data.value = this.data.items.find(x => x.__KEY__ == _val.__KEY__);
            //}
            //else {
            this.data.value = this.data.items.find(x => x.text == _val.text);
            // }
        }

    }


    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    onValueChanged(e) {
        
        //value:
        //text: "Radio Button 1"
        if (e.value != "") {

            let componentList = this.componentService.formComponentList;
            if (typeof e.value.disable != "undefined" && e.value.disable != "") {
                e.value.disable.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideDisabled = true;
                        //let _sub = { key: _k.uuid, outsideDisabled: true };
                        //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                    }
                });
            }

            if (typeof e.value.enable != "undefined" && e.value.enable != "") {////
                e.value.enable.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideDisabled = false;
                        //let _sub = { key: _k.uuid, outsideDisabled: false };
                        //this.eventEmitterService.ChangeDisableAndVisible(_sub);
                    } 
                });
            }

            if (typeof e.value.visible != "undefined" && e.value.visible != "") {//////
                e.value.visible.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideVisible = true;
                        let _sub = { key: _k.uuid, outsideVisible: true };
                        this.eventEmitterService.ChangeVisible(_sub);
                    }  
                });
            }

            if (typeof e.value.invisible != "undefined" && e.value.invisible != "") {
                e.value.invisible.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideVisible = false;
                        let _sub = { key: _k.uuid, outsideVisible: false };
                        this.eventEmitterService.ChangeVisible(_sub);
                    }  
                });
            }
            this.changeDetectorRef.detectChanges();
        } 
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

}
