import { Component, OnInit,Input } from '@angular/core';

@Component({
    selector: 'imageviewer-component',
    templateUrl: './imageviewer.component.html',
    styleUrls: ['./imageviewer.component.css']
})
export class DxImageviewerComponent implements OnInit {
    @Input() data: any;
  imgSrc:any;
    fileName:string="";

  showImage:boolean=false;
  displayImgSrc:any;
    constructor() { }

    ngOnInit() {
    }

    showImg(src) {
    this.displayImgSrc = src;
    this.showImage = true;
  }

    onValueChanged = (e: any): void => {
        if (e.value.length) {
            var reader = new FileReader();
            reader.readAsDataURL(e.value[0]);
            reader.onload = (args: any) => {
                if (reader.result) {
                    this.imgSrc = args.target.result;
                    //let questionCode = dataField;
                    let fileName: string = e.value[0].name;
                    this.fileName = fileName;
                    let fileType: string = e.value[0].type;
                    let base64BlobData: any = "";//reader.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension
                    };


                }
            }
        }
    }
}
