import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthGuardService, FormbuilderGuardService } from "./shared/services";

export const AppRoutes: Routes = [
    {
        path: "",
        redirectTo: "home",
        pathMatch: "full"
    },
    {
        path: "",
        component: AdminLayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./pages/home/home.module#HomeModule",
                canActivate: [AuthGuardService],
                runGuardsAndResolvers:"always"
            },
            {
                path: "report",
                loadChildren: "./pages/report/report.module#ReportModule",
                canActivate: [AuthGuardService],
                pathMatch: "full",
                runGuardsAndResolvers: "always"
            },
            {
                path: "profile",
                loadChildren: "./pages/profile/profile.module#ProfileModule",
                canActivate: [AuthGuardService],
                pathMatch: "full",
                runGuardsAndResolvers: "always"
            },
            {
                path: "menu/:id/:formId/:schemaId",
                loadChildren: "./pages/menu/menu.module#MenuModule",
                canActivate: [AuthGuardService],
                pathMatch: "full",
                runGuardsAndResolvers: "always"
            },
            {
                path: "history/:id",
                loadChildren: "./pages/history/history.module#HistoryModule",
                canActivate: [AuthGuardService],
                pathMatch: "full",
                runGuardsAndResolvers: "always"
            },
            {
                //schemaId
                //keyword
                //status 
                //userId 
                //start1 
                //start2 
                //end1 
                //end2  
                path: "search/:schemaId/:keyword/:status/:userId/:start1/:start2/:end1/:end2",
                loadChildren: "./pages/search/search.module#SearchModule",
                canActivate: [AuthGuardService],
                pathMatch: "full"
            },
            //{
            //    path: "reportdesigner",
            //    loadChildren: "./pages/reportdesigner/reportdesigner.module#ReportDesignerModule",
            //    canActivate: [ AuthGuardService ]
            //}
            // ,
            // {//http://localhost:4200/#/formbuilder
            //    path: "formbuilder",
            //    loadChildren:
            //        "./shared/components/formbuilder/formbuilder.module#FormbuilderModule"
            // }
            //,
            //{
            //    path: "components",
            //    loadChildren:
            //        "./pages/components/components.module#ComponentsPageModule"
            //},
            //{
            //    path: "forms",
            //    loadChildren: "./pages/forms/forms.module#Forms"
            //},
            //{
            //    path: "tables",
            //    loadChildren: "./pages/tables/tables.module#TablesModule"
            //},
            //{
            //    path: "maps",
            //    loadChildren: "./pages/maps/maps.module#MapsModule"
            //},
            //{
            //    path: "widgets",
            //    loadChildren: "./pages/widgets/widgets.module#WidgetsModule"
            //},
            //{
            //    path: "charts",
            //    loadChildren: "./pages/charts/charts.module#ChartsModule"
            //},
            //{
            //    path: "calendar",
            //    loadChildren: "./pages/calendar/calendar.module#CalendarModulee"
            //},
            //{
            //    path: "",
            //    loadChildren:
            //        "./pages/pages/user-profile/user-profile.module#UserModule"
            //},
            //{
            //    path: "",
            //    loadChildren: "./pages/pages/timeline/timeline.module#TimelineModule"
            //}
        ]
    },
    {
        path: "",
        children: [
            {
                path: "",
                loadChildren: "./shared/components/components.module#ComponentsModule"

            }
        ]
    },
    //{
    //    path: "",
    //    children: [ 
    //        {
    //           path: "reportdesigner",
    //            loadChildren: "./pages/admin/designer/reportdesigner.component",
    //            canActivate: [FormbuilderGuardService ]
    //        }
    //    ]
    //},
    //{
    //    path: "",
    //    component: AuthLayoutComponent,
    //    children: [
    //        // ,
    //        // {//http://localhost:4200/#/formbuilder
    //        //    path: "formbuilder",
    //        //    loadChildren:
    //        //        "./shared/components/formbuilder/formbuilder.module#FormbuilderModule"
    //        // }
    //        // {
    //        //    path: "formbuilder",
    //        //    loadChildren: "./shared/components/components.module#ComponentsModule"
    //        // }
    //    ]
    //}
];

