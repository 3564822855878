import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AppInfoService, EventEmitterService } from '../../../shared/services';
import { ComponentService } from '../../component.service';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { LocalStorageService } from '../../local-storage.service';

@Component({
  selector: 'popootoolbox-component',
  templateUrl: './popootoolbox.component.html',
  styleUrls: ['./popootoolbox.component.css']
})
export class DxPopootoolboxComponent implements OnInit {
  @Input() data: any;
  @Input() showSettingPopup: boolean;

  historyActions = { save: 1, undo: 2, redo: 3 };
  constructor(public appInfo: AppInfoService,
    public componentService: ComponentService,
    public storage : LocalStorageService,
    private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
    
  }

  saveSetting() {
    this.showSettingPopup = false;
    this.eventEmitterService.applyHistory(this.historyActions.save);
    //this.storage.save(this.model.uuid, JSON.stringify(this.model));
  }

  cancelSetting() {
     this.showSettingPopup = false;
     this.eventEmitterService.applyHistory(this.historyActions.undo);
  }

  undoSetting() {
     this.eventEmitterService.applyHistory(this.historyActions.undo);
     //this.data.showEditPop = true;
     //this.model = JSON.parse(this.storage.get(this.model.uuid));
  }


 
}
