import { Component, OnInit, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AgmCoreModule } from '@agm/core';
import { DevExtremeModule } from '../../devextreme.module';

import { FormBuilder } from "./_formbuilder/formbuilder";
import { FormRender } from "./_formrender/formrender";

import {
    DxLabelComponent,
    DxButtonComponent,
    DxCheckboxComponent,
    DxCodeditorComponent,
    DxEtrDataGridComponent,
    DxCustomDataGridComponent,
    DxDateboxComponent,
    DxEmailComponent,
    DxFileuploadComponent,
    DxImageuploadComponent,
    DxPictureBoxComponent,
    DxMapComponent,
    DxImageviewerComponent,
    DxVideoviewerComponent,
    DxVideouploadComponent,
    DxGridComponent,
    DxNumberComponent,
    DxPasswordComponent,
    DxPhoneComponent,
    DxRadiogroupComponent,
    DxSelectboxComponent,
    DxDatagridSelectComponent,
    DxDataListComponent,
    DxTextboxComponent,
    DxLiveComponent,
    DxPaymentComponent,
    DxTextareaComponent,
    DxAccordionComponent,
    DxHtmleditorComponent,
    DxDatasourceComponent,
    DxDisplayComponent,
    DxFormResultComponent,
    DxGalleryComponent,
    DxMenuComponent,
    DxTabsComponent
} from "."

import { DxLanguageGridComponent, DxSqlcommandComponent, DxPermissionComponent, DxPopootoolboxComponent, DxLanguageButtonComponent } from './_partialcomponents';
import { ComponentService } from './component.service';
import { RenderComponent } from './render.component';
import { ComponentDirective } from './component.directive';
import { SafePipe } from './safepipe.component ';
import { LocalStorageService } from './local-storage.service';

import { JwPaginationComponent } from 'jw-angular-pagination';

@NgModule({
    imports: [
        MonacoEditorModule.forRoot(),
        AgmCoreModule.forRoot({
            // please get your own API key here:AIzaSyDXpgm5y_tUBAAAS7JMPmZgMQvjpUvjh4M
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: atob(typeof localStorage.getItem("GMAPI") != "undefined" ? localStorage.getItem("GMAPI") : '')//'AIzaSyBfeMCzcIXRN-rBUA19hqwFgngihg99VwQ'  QUl6YVN5QmZlTUN6Y0lYUk4tckJVQTE5aHF3RmduZ2loZzk5VndR
            ,
            libraries: ["places"]

        }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        DevExtremeModule
    ],
    declarations: [
        SafePipe,
        FormBuilder,
        FormRender,
        DxLabelComponent,
        DxButtonComponent,
        DxCheckboxComponent,
        DxDateboxComponent,
        DxNumberComponent,
        DxPasswordComponent,
        DxSelectboxComponent,
        DxDatagridSelectComponent,
        DxDataListComponent,
        DxTextareaComponent,
        DxTextboxComponent,
        DxLiveComponent,
        DxPaymentComponent,
        DxGridComponent,
        RenderComponent,
        DxEtrDataGridComponent,
        DxCustomDataGridComponent,
        ComponentDirective,
        DxCodeditorComponent,
        DxRadiogroupComponent,
        DxFileuploadComponent,
        DxImageuploadComponent,
        DxPictureBoxComponent,
        DxMapComponent,
        DxVideouploadComponent,
        DxPhoneComponent,
        DxEmailComponent,
        DxAccordionComponent,
        DxHtmleditorComponent,
        DxLanguageGridComponent,
        DxLanguageButtonComponent,
        DxImageviewerComponent,
        DxVideoviewerComponent,
        DxSqlcommandComponent,
        DxPermissionComponent,
        DxPopootoolboxComponent,
        DxDatasourceComponent,
        DxDisplayComponent,
        DxFormResultComponent,
        DxGalleryComponent,
        DxMenuComponent,
        DxTabsComponent,
        JwPaginationComponent
    ],
    entryComponents: [
        DxLabelComponent,
        DxTextboxComponent,
        DxLiveComponent,
        DxPaymentComponent,
        DxButtonComponent,
        DxCheckboxComponent,
        DxDateboxComponent,
        DxNumberComponent,
        DxPasswordComponent,
        DxSelectboxComponent,
        DxDatagridSelectComponent,
        DxDataListComponent,
        DxTextareaComponent,
        DxGridComponent,
        DxEtrDataGridComponent,
        DxCustomDataGridComponent,
        DxFileuploadComponent,
        DxImageuploadComponent,
        DxPictureBoxComponent,
        DxMapComponent,
        DxVideouploadComponent,
        DxPhoneComponent,
        DxEmailComponent,
        DxRadiogroupComponent,
        DxAccordionComponent,
        DxHtmleditorComponent,
        DxImageviewerComponent,
        DxVideoviewerComponent,
        DxDatasourceComponent,
        DxDisplayComponent,
        DxFormResultComponent,
        DxGalleryComponent,
        DxMenuComponent,
        DxTabsComponent
    ],
    providers: [ComponentService, LocalStorageService],
    exports: [FormBuilder, FormRender, DevExtremeModule]
})
export class RiverFormModule { }

