import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';

import { ComponentItem } from './component.item';
import { ComponentParameters } from './component.parameters';

import {
    DxLabelComponent,
    DxButtonComponent,
    DxCheckboxComponent,
    DxEtrDataGridComponent,
    DxCustomDataGridComponent,
    DxDateboxComponent,
    DxEmailComponent,
    DxFileuploadComponent,
    DxImageuploadComponent,
    DxPictureBoxComponent,
    DxMapComponent,
    DxVideouploadComponent,
    DxGridComponent,
    DxNumberComponent,
    DxPasswordComponent,
    DxPhoneComponent,
    DxRadiogroupComponent,
    DxSelectboxComponent,
    DxDatagridSelectComponent,
    DxDataListComponent,
    DxTextboxComponent,
    DxLiveComponent,
    DxPaymentComponent,
    DxTextareaComponent,
    DxAccordionComponent,
    DxHtmleditorComponent,
    DxImageviewerComponent,
    DxVideoviewerComponent,
    DxDatasourceComponent,
    DxDisplayComponent,
    DxFormResultComponent,
    DxGalleryComponent,
    DxMenuComponent,
    DxTabsComponent
    } from "../formdesigner"
import { ComponentDirective } from './component.directive';


@Component({
    selector: 'render-component',
    template: ` <ng-template component-host></ng-template> `
})
export class RenderComponent implements OnInit, OnDestroy {
    @Input() componentName: any;
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: boolean;

    @ViewChild(ComponentDirective, { static: true }) componentHost: ComponentDirective;
    interval: any;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        
        this.loadComponent();
    }

    ngOnDestroy() {
        console.log("destroyed")
    }

    loadComponent() {
        const currentComponent = this.getCurrent();
        if (currentComponent) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(currentComponent);
            const viewContainerRef = this.componentHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
            (<ComponentParameters>componentRef.instance).data = this.data;
            (<ComponentParameters>componentRef.instance).lang = this.lang;
            (<ComponentParameters>componentRef.instance).isEdit = this.isEdit;
        }
    }

    getCurrent() {
        var components = this.getComponents();
        for (let i = 0; i < components.length; i++) {
            if (components[i].name == this.componentName) return components[i].component;
        }
        return null;
    }

  
    getComponents() {
        return [
           new ComponentItem(DxLabelComponent, "DxLabelComponent"),
            new ComponentItem(DxTextboxComponent, "DxTextboxComponent"),
            new ComponentItem(DxLiveComponent, "DxLiveComponent"),
            new ComponentItem(DxPaymentComponent, "DxPaymentComponent"),
            new ComponentItem(DxButtonComponent, "DxButtonComponent"),
            new ComponentItem(DxCheckboxComponent, "DxCheckboxComponent"),
            new ComponentItem(DxDateboxComponent, "DxDateboxComponent"),
            new ComponentItem(DxNumberComponent, "DxNumberComponent"),
            new ComponentItem(DxPasswordComponent, "DxPasswordComponent"),
            new ComponentItem(DxSelectboxComponent, "DxSelectboxComponent"), 
            new ComponentItem(DxDatagridSelectComponent, "DxDatagridSelectComponent"),
            new ComponentItem(DxDataListComponent, "DxDataListComponent"),
            new ComponentItem(DxTextareaComponent, "DxTextareaComponent"),
            new ComponentItem(DxGridComponent, "DxGridComponent"),
            new ComponentItem(DxEtrDataGridComponent, "DxDataGridComponent"),
            new ComponentItem(DxCustomDataGridComponent, "DxCustomDataGridComponent"),
            new ComponentItem(DxRadiogroupComponent, "DxRadiogroupComponent"),
            new ComponentItem(DxFileuploadComponent, "DxFileuploadComponent"),
            new ComponentItem(DxPhoneComponent, "DxPhoneComponent"),
            new ComponentItem(DxEmailComponent, "DxEmailComponent"),
            new ComponentItem(DxHtmleditorComponent, "DxHtmleditorComponent"),
            new ComponentItem(DxAccordionComponent, "DxAccordionComponent"),
            new ComponentItem(DxImageuploadComponent, "DxImageuploadComponent"),
            new ComponentItem(DxPictureBoxComponent, "DxPictureBoxComponent"),
            new ComponentItem(DxMapComponent, "DxMapComponent"),
            new ComponentItem(DxVideouploadComponent, "DxVideouploadComponent"),
            new ComponentItem(DxImageviewerComponent, "DxImageviewerComponent"),
            new ComponentItem(DxVideoviewerComponent, "DxVideoviewerComponent"),
            new ComponentItem(DxDisplayComponent, "DxDisplayComponent"),
            new ComponentItem(DxDatasourceComponent, "DxDatasourceComponent"),
            new ComponentItem(DxFormResultComponent, "DxFormResultComponent"),
            new ComponentItem(DxGalleryComponent, "DxGalleryComponent"),
            new ComponentItem(DxMenuComponent, "DxMenuComponent"),
            new ComponentItem(DxTabsComponent, "DxTabsComponent")
        ];
    } 
   
}
