import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { AppInfoService, FormService, GenericService } from '../../shared/services';
import { Subscription } from 'rxjs';


declare var globalIpAddress: any;

@Component({
    selector: 'button-component',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})

export class DxButtonComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: any;

    buttonActions = [
        { "text": "Kaydet", "value": 1 },
        { "text": "Kaydet ve Gönder", "value": 2 },
        { "text": "Onayla", "value": 3 },
        { "text": "Reddet", "value": 4 },
        { "text": "Geri Gönder", "value": 5 },
        { "text": "İptal Et", "value": 6 },
        { "text": "Reddet ve Bitir", "value": 7 },
        { "text": "Tamamla", "value": 8 }
    ];

    buttonTypes = [
        { "text": "Mavi", "value": "default" },
        { "text": "Gri", "value": "normal" },
        { "text": "Yeşil", "value": "success" },
        { "text": "Kırmızı", "value": "danger" }
    ];
    possitions = [
        { "text": "Sol", "value": "left" },
        { "text": "Sağ", "value": "right" }
    ];

    buttonModel = {
        //"text": "Kaydet",selectedButton.priceComponen t
        "name": "Kaydet",
        "status": 1,
        "type": "default",
        "possition": "left",
        "language": [
            {
                "buttonId": "0",
                "Code": "trTR",
                "Label": "Kaydet",
                "DefaultLabel": "Kaydet",
            }
        ]
    }


    textComponentList: any = [];
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    usersAndGroupsPopup: boolean = false;
    buttonSettingPopup: boolean = false;
    buttonsPopup: boolean = false;
    showRejectReasonPopup: boolean = false;

    userPermissionAll: boolean = false;
    groupPermissionAll: boolean = false;

    rejectReason: string = "";

    users = [];
    groups = [];

    invokeChangeEventLocal: Subscription;

    userButtons = [];
    customerIp = "1.0.0.0";
    constructor(public appInfo: AppInfoService,
        public changeDetectorRef: ChangeDetectorRef,
        private eventEmitterService: EventEmitterService,
        private genericService: GenericService,
        public formService: FormService,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });


        let fff = this.componentService.currentForm;


        this.textComponentList = this.componentService.getInputComponentList(this.componentService.currentForm.components);


        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == "isReadonly") {
                if (sub.data) this.componentService.currentForm.isReadonly = sub.data;
                else this.componentService.currentForm.isReadonly = false;
                this.changeDetectorRef.detectChanges();
            }
        });

        this.getFormList();

        this.customerIp = globalIpAddress;
        //this.genericService.getIPAddress().subscribe((result: any) => {
        //    this.customerIp = result.ip;
        //});
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        console.log("isReadonly: " + this.componentService.currentForm.isReadonly)
        this.data.uuid = this.componentService.createuuid();


        let dddd = this.componentService.currentFormComponents;

        this.loadUserButtons();
        //  this.defaultLanguag e = this.componentService.getLang(this.lang, this.data.languag e);
        if (this.isEdit) {
            this.groups = this.appInfo.RiverEntity.userGroups;
            this.users = this.appInfo.RiverEntity.users;
        }

        let fff = this.componentService.currentForm;

    }
    formList
    getFormList() {
        this.formService.getAllForms().subscribe(forms => {
            //this.formList = ;
            this.formList = forms;
        })
    }
    formComponentList
    customerFormOnChange() {
        //

        let form = this.formList.find(x => x.id == this.selectedButton.customerFormId);
        if (form && form.forM_JSON) {
            let formjson = JSON.parse(form.forM_JSON);
            formjson.components = formjson.components.filter(f => !f.hide);
            this.formComponentList = this.componentService.getInputComponentList(formjson.components);

        }
    }

    loadUserButtons() {
        if (this.isEdit) {
            this.userButtons = this.data.buttons;
        }
        else {
            this.data.buttons.forEach(btn => {
                let userId = this.appInfo.currentUser.id;

                if (btn.users.length <= 0 && btn.groups.length <= 0) {
                    this.userButtons.push(btn);
                }
                else {
                    let isGroupUser = false;
                    if (btn.groups) {
                        btn.groups.forEach(g => {
                            if (g.users.indexOf(userId) >= 0) {
                                isGroupUser = true;
                            }
                        });
                    }
                    if (btn.users.indexOf(userId) >= 0 || isGroupUser) {
                        this.userButtons.push(btn);
                    }
                }



                //else if (btn.users.indexOf(userId) >= 0) {
                //    this.userButtons.push(btn);
                //}
                //else if (btn.groups) {
                //    btn.groups.forEach(g => {
                //        if (g.users.indexOf(userId) >= 0) {
                //            this.userButtons.push(btn);
                //        }
                //    });
                //}





            });


        }


    }

    selectedColLanguage: any;
    addButtonLanguage(button, i) {
        //
        //this.componentService.getLang(this.lang, button.property.language);

        let j = 0;
        for (j = 0; j < button.property.language.length; j++) {
            button.property.language[j].buttonId = i;
        }

        this.selectedColLanguage = button.property.language;
        this.languagePopup = true;
    }
    getTextValue(btn) {
        let _text = "";
        if (typeof btn.property.language === "undefined") {
            _text = btn.property.text;
        }
        else {
            let index = btn.property.language.findIndex(item => item.Code === this.lang);

            if (index > -1) {
                if (btn.property.language[index] && btn.property.language[index].Label)
                    _text = btn.property.language ? btn.property.language[index].Label : '-';

                else
                    _text = '-';

            }
            else
                _text = btn.property.language ? btn.property.language[0].Label : '-';
        }
        return _text;
    }
    statusOnChange(button, index) {


        let statusText = this.buttonActions.find(f => f.value == button.property.status).text;
        if (typeof button.property.language[0].Label === "undefined" || button.property.language[0].Label == "" || button.property.language[0].Label == button.property.language[0].DefaultLabel) {
            button.property.language[0].Label = statusText;
            button.property.language[0].DefaultLabel = statusText;
        } //   button.property.name = this.buttonActions.find(f => f.value == button.property.status).text;

    }

    //replaceTurkish(str) {
    //      ı ğ ş ü
    //    Ö Ç İ Ğ Ş Ü
    //    'ö': 'o', 'ç': 'c', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o',
    //        'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o', 'ö': 'o',
    //    for (var x in { 'ö': 'o', 'b': 'o' }) {
    //        str = str.replace(new RegExp("ö", "gm"), "o");
    //        retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
    //    }


    //    return 
    //}

    onEditPopopShown() {
        this.textComponentList = this.componentService.getInputComponentList(this.componentService.currentForm.components);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }
    defaultCustomerObj = { id: 0, name: "", surname: "", identityNumber: "", country: "", city: "", email: "", address: "", ip: this.customerIp };
    paymentProviders = [{ name: "IYZICO" }, { name: "YAPIKREDI" }];
    addNewButton() {
        let btn: any = {};
        btn.users = [];
        btn.groups = [];
        btn.isLocation = false;
        btn.isPayment = false;
        btn.isZoom = false;
        btn.zoomUrl = "";
        btn.isLiveWeb = false;
        btn.liveWeb = { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" };
        this.defaultCustomerObj.ip = this.customerIp;
        btn.customer = this.defaultCustomerObj;
        btn.priceComponent = "";
        btn.hirePurchaseComponent = "";
        btn.descriptionComponent = "";
        btn.paymentProvider = "";
        btn.property = this.componentService.cloneModel(this.buttonModel);
        this.data.buttons.push(btn);
    }

    deleteButton(index) {
        let msg = "<i>Buton silinsin mi?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.buttons.splice(index, 1);
            }
        });
    }

    saveButtons() {
        console.log(this.data.buttons);
        this.changeDetectorRef.detectChanges();
        this.buttonsPopup = false;
    }

    selectedButton: any;
    openUsersAndGroupPermissionPopup(btn) {
        this.selectedButton = btn;
        this.users.forEach(user => {
            if (btn.users.indexOf(user.id) != -1) {
                user.hasPermission = true;
            }
            else {
                user.hasPermission = false;
            }
        });

        this.groups.forEach(group => {
            let extGroup = btn.groups.find(f => f.id == group.id);
            if (extGroup) {
                group.hasPermission = true;
            }
            else {
                group.hasPermission = false;
            }
        });

        this.usersAndGroupsPopup = true;
    }

    openButtonSettingPopup(btn) {

        this.selectedButton = btn;
        this.selectedButton.isLocation = typeof this.selectedButton.isLocation == "undefined" ? false : this.selectedButton.isLocation;
        this.selectedButton.isPayment = typeof this.selectedButton.isPayment == "undefined" ? false : this.selectedButton.isPayment;
        this.selectedButton.isZoom = typeof this.selectedButton.isZoom == "undefined" ? false : this.selectedButton.isZoom;
        this.selectedButton.isLiveWeb = typeof this.selectedButton.isLiveWeb == "undefined" ? false : this.selectedButton.isLiveWeb;
        this.selectedButton.liveWeb = typeof this.selectedButton.liveWeb == "undefined" ? { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" } : this.selectedButton.liveWeb;
        this.selectedButton.zoomUrl = typeof this.selectedButton.zoomUrl == "undefined" ? "" : this.selectedButton.zoomUrl;
        this.selectedButton.priceComponent = typeof this.selectedButton.priceComponent == "undefined" ? "" : this.selectedButton.priceComponent;
        this.selectedButton.hirePurchaseComponent = typeof this.selectedButton.hirePurchaseComponent == "undefined" ? "" : this.selectedButton.hirePurchaseComponent;
        this.selectedButton.descriptionComponent = typeof this.selectedButton.descriptionComponent == "undefined" ? "" : this.selectedButton.descriptionComponent;
        this.selectedButton.paymentProvider = typeof this.selectedButton.paymentProvider == "undefined" ? "IYZICO" : this.selectedButton.paymentProvider;
        this.defaultCustomerObj.ip = this.customerIp;
        this.selectedButton.customer = typeof this.selectedButton.customer == "undefined" ? this.defaultCustomerObj : this.selectedButton.customer;
        this.buttonSettingPopup = true;
    }

    saveButtonSetting() {
        console.log(this.data.buttons);
        this.changeDetectorRef.detectChanges();
        this.buttonSettingPopup = false;
    }

    /* onStatusValueChanged(val, btn) {
      let status = this.buttonActions.find(f => f.value == val);
      if (status && (btn.property.text=="" || btn.property.text==null || typeof(btn.property.text) == "undefined")) {btn.property.text = status.text;}
    } */
    userPermissionChangeAll(isChecked) {
        this.users.forEach(user => {
            if (isChecked) {
                if (this.selectedButton.users.indexOf(user.id) == -1) {
                    this.selectedButton.users.push(user.id);
                }
            }
            else {
                if (this.selectedButton.users.indexOf(user.id) != -1) {
                    this.selectedButton.users.splice(this.selectedButton.users.indexOf(user.id), 1);
                }
            }
        });
        this.openUsersAndGroupPermissionPopup(this.selectedButton);
    }
    changeUserPermission(isChecked, id) {
        if (isChecked) {
            if (this.selectedButton.users.indexOf(id) == -1) {
                this.selectedButton.users.push(id);
            }
        }
        else {
            if (this.selectedButton.users.indexOf(id) != -1) {
                this.selectedButton.users.splice(this.selectedButton.users.indexOf(id), 1);
            }
        }

        //console.log(this.data.buttons);
    }

    groupPermissionChangeAll(isChecked) {
        this.groups.forEach(group => {
            if (isChecked) {
                let extGroup = this.selectedButton.groups.find(f => f.id == group.id);
                if (!extGroup) {
                    let grp: any = {};
                    grp.id = group.id;
                    grp.users = [];
                    let _group = this.groups.find(f => f.id == group.id);
                    if (_group.users && _group.users.length > 0) {
                        let sp = _group.users.split(",");
                        sp.forEach(f => {
                            if (f) grp.users.push(parseInt(f));
                        });
                    }
                    this.selectedButton.groups.push(grp);
                }
            }
            else {
                let extGroup = this.selectedButton.groups.find(f => f.id == group.id);
                if (extGroup) {
                    this.selectedButton.groups.splice(this.selectedButton.groups.indexOf(extGroup), 1);
                }
            }
        });
        this.openUsersAndGroupPermissionPopup(this.selectedButton);
    }

    changeGroupPermission(isChecked, id, users) {
        if (isChecked) {
            let extGroup = this.selectedButton.groups.find(f => f.id == id);
            if (!extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (users && users.length > 0) {
                    let sp = users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.selectedButton.groups.push(grp);
            }
        }
        else {
            let extGroup = this.selectedButton.groups.find(f => f.id == id);
            if (extGroup) {
                this.selectedButton.groups.splice(this.selectedButton.groups.indexOf(extGroup), 1);
            }
        }
        //console.log(this.data.buttons);
    }

    selectedFormStatus: number;
    selectedButtonName: any;
    submitForm(args, status, name) {

        if (!args.validationGroup.validate().isValid) {
            return;
        }
        //let result = custom({
        //    title: "!", messageHtml: msg, buttons: [
        //        { text: "Evet", onClick: (e) => { return true; } },
        //        { text: "Hayır", onClick: (e) => { return false; } }
        //    ]
        //}).show();
        this.selectedFormStatus = status;
        this.selectedButtonName = name;
        let msg = "<i>İşlemi onaylıyor musunuz?</i>"
        let result = confirm(msg, "!");
        result.then((dialogResult) => {
            if (dialogResult) {
                if (status == this.componentService.formStatus.Disapprove) {
                    this.showRejectReasonPopup = true;
                    this.changeDetectorRef.detectChanges();
                }
                else {
                    this.sendFormData();
                }
            }
        });
    }

    sendFormData() {

        //this.data.value = this.selectedFormStatus;
        //this.eventEmitterService.sendForm(this.selectedFormStatus, this.selectedButtonName, this.rejectReason);

        if (typeof this.componentService.isMenuForm != "undefined" && this.componentService.isMenuForm == true)
            this.eventEmitterService.submitMenu(this.selectedFormStatus, this.selectedButtonName, "");
        else
            this.eventEmitterService.sendForm(this.selectedFormStatus, this.selectedButtonName, this.rejectReason);
    }

}
