import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { EventEmitterService } from '../../shared/services';
import { InstanceService } from '../../shared/services/data/instance.service';

@Component({
    selector: 'app-imageupload',
    templateUrl: './imageupload.component.html',
    styleUrls: ['./imageupload.component.css']
})
export class DxImageuploadComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showFilesPopup: boolean = false;

    showDisplay: any = "flex";

    uploadedFiles: any = [];
    uploadResult = { isShow: false, status: "success", message: "" };
    totalSize: any = 0;

    constructor(private eventEmitterService: EventEmitterService, private componentService: ComponentService,
        public instanceService: InstanceService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
    }


    ngOnInit() {
        
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                this.changeDetectorRef.detectChanges();
            }
        });

        //this.componentService.currentProcessFormId

        //data.valueTemp[i].base64BlobData

        if (typeof this.data.valueTemp == "undefined")
            this.data.valueTemp = [];



    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    openPermissionPopup() {
        this.permissionPopup = true;
    }

    onValueChanged = (e: any): void => {
        let errors = [];
        if (e.value.length) {
            this.totalSize = 0;
            this.uploadResult.isShow = true;
            this.uploadResult.status = "primary";
            this.uploadResult.message = "Dosyalar yükleniyor...";
            for (let i = 0; i < e.value.length; i++) {
                let checkSum = this.totalSize + e.value[i].size;
                if (checkSum > (this.data.maxFileSize * 1000000)) {
                    let msg = e.value[i].name + " yüklenemedi."
                    errors.push(msg);
                    continue;
                }

                this.getDocument(e.value[i]).then(document => {
                    this.data.valueTemp.push(document);
                    this.totalSize += e.value[i].size;
                    if (i === (e.value.length - 1)) {
                        if (errors.length) {
                            this.uploadResult.status = "danger";
                            this.uploadResult.message = "Dosya boyutu aşıldı: " + errors.join(", ");
                        }
                        else {
                            this.uploadResult.status = "success";
                            this.uploadResult.message = "Dosyalar yüklendi";
                        }
                    }

                    this.changeDetectorRef.detectChanges();
                });

            }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: true
                    };
                    resolve(document);

                }
            }

        });
    }

    showFiles() {
        for (let i = 0; i < this.data.valueTemp.length; i++) {
            this.data.valueTemp[i].src = 'data:' + this.data.valueTemp[i].fileType + ';base64,' + this.data.valueTemp[i].base64BlobData;
        }
        this.showFilesPopup = true;
    }

    deleteFiles() {
        let result = confirm("<i>Dosyaları silmek istediğinize emin misiniz?</i>", "Dosyalar Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];
                for (let i = 0; i < this.data.valueTemp.length; i++) {
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                }
                this.data.valueTemp = [];
                this.uploadResult.isShow = false;
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    deleteFile(i) {
        let result = confirm("<i>Dosya silinsin mi?</i>", "Dosya Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                
                if (this.data.valueTemp && this.data.valueTemp.length > 0 && this.data.valueTemp[i])
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                this.data.valueTemp.splice(i, 1);
                this.changeDetectorRef.detectChanges();
                this.showFiles();
            }
        });

    }

}
