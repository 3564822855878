import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription'; 

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  currentFormComponents:any;
  constructor() { }    
    
}
