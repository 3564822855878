import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { HttpClient} from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';

import { formatDate } from 'devextreme/localization';
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { GenericService } from '../../shared/services';
import { AppInfoService } from '../../shared/services';
import { ComponentService } from '../component.service';

//import 'devextreme/localization/globalize/date';

@Component({
  selector: 'customdatagrid-component',
  templateUrl: './customdatagrid.component.html',
   styleUrls: ['./customdatagrid.component.css']
})
export class DxCustomDataGridComponent implements ComponentParameters, OnInit {
  @Input() data: any;
  @Input() isEdit: any;
  @Input() lang: string;


  fieldTypes: any[] = [
    {"value":"text", "name":"Text"},
    {"value":"number", "name":"Sayı"},
    {"value":"date", "name":"Tarih"},
    {"value":"checkbox", "name":"Checkbox"},
    {"value":"combobox", "name":"Combobox"},
    {"value":"selection", "name":"Selection Text"}];

  headers = {
    "name":"",
    "type":"",
    "dataSourceKey":"",
    "dataSource":[],
    "displayKey":"",
    "valueKey":"",
    "width":1
    };

   dataGridColumn:any = [];
   dataSourcePopup:boolean=false;
   dataSources:any=[];
   selectedType: any = {};
   dataGridSource:any=[];
   gridBoxValue: number[];
   permissionPopup : boolean=false;

  dataGridPopup: boolean = false;
  selectedCellRow:number;
  selectedCellCol:number;
  constructor(private eventEmitterService: EventEmitterService,
    public genericService: GenericService,
    public appInfo: AppInfoService,
     private componentService:ComponentService) {
  
  }
  
  ngOnInit() {
    this.getDataSources();
  }

  getDataSources() {
    this.dataSources = this.componentService.getComponentsByType("datasource");
    if (!this.isEdit) {
       setTimeout(() => {
           this.setDataSource();
       }, 1000);
     }
  }

    setDataSource() {
     for (let i = 0; i < this.data.headers.length; i++) {
       if ((this.data.headers[i].type == "combobox" || this.data.headers[i].type == "selection") && this.data.headers[i].dataSourceKey !="") {
         let source = this.dataSources.find(f => f.id == this.data.headers[i].dataSourceKey);
         this.data.headers[i].dataSource = source.dataSource;
       }
     }
  }

  addHeader() {
    localStorage.setItem("dataGridHeader", JSON.stringify(this.headers));
    let header: any = JSON.parse(localStorage.getItem("dataGridHeader"));
    localStorage.removeItem("dataGridHeader");
    this.data.headers.push(header);
    this.showToast("Eklendi","success");
    this.resetHeaderObject();
  }

  resetHeaderObject() {
    this.headers = {
    "name":"",
    "type":"",
    "dataSourceKey":"",
    "dataSource":[],
    "displayKey":"",
    "valueKey":"",
    "width":1
    };
  }

  deleteHeader(index) {
      let result = confirm("<i>Emin misiniz?</i>", "Kolon Silinecek!");
      result.then((dialogResult) => {
         if (dialogResult) {
          this.data.headers.splice(index, 1);
        }
      });
  }

  addRow() {
    let row: any = {};
    row.isEditable= true;
      row.cols = [];
    for (let i = 0; i < this.data.headers.length; i++) {
      let col = {
        type:this.data.headers[i].type,
        label:"",
        value:"",
        width:this.data.headers[i].width
        };
      row.cols.push(col);
    }
    this.data.value.push(row);
  }

  deleteRow(index) {
      let result = confirm("<i>Emin misiniz?</i>", "Satır Silinecek!");
      result.then((dialogResult) => {
         if (dialogResult) {
          this.data.value.splice(index, 1);
          this.executeExp();
        }
      });
  }

  onValueChanged(event) {
    if (event.value == "selection" || event.value == "combobox") {
      this.dataSources = this.componentService.getComponentsByType("datasource");
    }
  }

  openDataGrid(dataSource, i, j) {
    this.selectedCellRow = i;
    this.selectedCellCol = j;
    this.dataGridSource = dataSource;
    this.dataGridPopup = true;
  }

  gridDataClick(data) {
    let index = data.rowIndex;
    let val = this.data.headers[this.selectedCellCol].dataSource[index][this.data.headers[this.selectedCellCol].displayKey];
    this.data.value[this.selectedCellRow].cols[this.selectedCellCol].value = val;
    this.dataGridSource = [];
    this.dataGridPopup = false;
  }

  openPermissionPopup() {
    this.permissionPopup = true;
  }

   executeExp() {
     console.log("grid executeExp")
      this.eventEmitterService.executeExpretion();
    }

    showToast(message, status) {
    notify(message, status, 3000);
  }
}
