import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { FormModel } from '../../../shared/models/form-model';
import { AppInfoService } from '../app-info.service';
import { MenuNode } from '../../models/menu-node-model';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getAllForms() {
        return this.http.get(this.appInfo.appUrl + "api/forms/getall");
    }

    getGroupedForms(): Observable<FormModel[]> {
        return this.http.get<FormModel[]>(this.appInfo.appUrl + "api/forms/getformsasgroup");
    }

    getSingleForm(formId: string): Observable<FormModel> {
        let params = new HttpParams().set("id", formId.toString());
        return this.http.get<FormModel>(this.appInfo.appUrl + "api/forms/getsingle", { params: params });
    }

    getMenuFormId(_guid: string): Observable<any> {
        let params = new HttpParams().set("guid", _guid);
        return this.http.get<any>(this.appInfo.appUrl + "api/forms/getmenuformid", { params: params });
    }

    geFormField(formId: string): Observable<string> {
        let params = new HttpParams().set("id", formId.toString());
        return this.http.get<string>(this.appInfo.appUrl + "api/forms/getfield", { params: params });
    }


    saveForm(formData): Observable<FormModel> {
        return this.http.post<FormModel>(this.appInfo.appUrl + "api/forms/save", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    updateForm(formData): Observable<FormModel> {
        return this.http.post<FormModel>(this.appInfo.appUrl + "api/forms/update", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    updateFormFields(id, formData) {
        return this.http.post(this.appInfo.appUrl + "api/forms/updatefields", { id: id, data: JSON.stringify(formData) }, this.appInfo.httpOptions);
    }

    deleteForm(formId: number): Observable<boolean> {
        //let params = new HttpParams().set("id", formId.toString()); 
        return this.http.get<boolean>(this.appInfo.appUrl + "api/form/delete?id=" + formId);
    }

    saveMenuForm(formData): Observable<MenuNode> {
        return this.http.post<MenuNode>(this.appInfo.appUrl + "api/forms/savemenuform", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    checkMenuForm(formData): Observable<MenuNode> {
        
        return this.http.post<MenuNode>(this.appInfo.appUrl + "api/forms/savemenuform?isArray=true", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    deleteMenuForm(formData, indexRow): Observable<boolean> {
        return this.http.post<boolean>(this.appInfo.appUrl + "api/forms/deletemenuform", JSON.stringify(formData), this.appInfo.httpOptions);
    }

    getMenuForm(formId: string, nodeKey: string, key: number, userId: string) {
        let params = new HttpParams().set("formId", formId).set("nodeKey", nodeKey).set("rowId", key.toString()).set("userId", userId);
        return this.http.get(this.appInfo.appUrl + "api/forms/getmenuform", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ` + error.status + `\nMessage: `+error.message;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
