import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EventEmitterService } from "../../shared/services/event-emitter.service";

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { FormModel } from '../../shared/models/form-model';
import { ComponentService } from "../component.service";
import { AppInfoService, FormService, TaskService } from '../../shared/services';
import { CurrentFormDataModel } from '../../shared/models/form-data-model';
import { InstanceService } from '../../shared/services/data/instance.service';
import { Subscription } from 'rxjs';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dx-render',
    templateUrl: './formrender.html',
    styleUrls: ['./formrender.css']
})


export class FormRender implements OnInit, OnDestroy {
    @Input() form: any;
    @Input() isEdit: boolean;
    @Input() isStarted: boolean;
    // @Input() selectedSchemaId: boolean;
    /*formStatus =
    {
        Processing : 1, //while yes
        SaveAndSend : 2, //while break
        Approve : 3, // while break
        Disapprove : 4, //while break
        Returned : 5, //while yes
        Cancelled : 6 // while break
    }*/


    languageCode: string;
    forms: any;

    colCountByScreen: Object;
    currentFormData = [];
    buttonActions: any = [];
    selectedstatus: number = 1;

    currentFormStatus: any;


    isFormEditable: boolean = false;
    currentProcessFormId: number = 0;
    prevUserId: number = 0;

    //rejectReason: string;
    //showRejectReasonPopup: boolean = false;
    isMenuForm: boolean = false;
    constructor(public formService: FormService,
        private appInfo: AppInfoService,
        private componentService: ComponentService,
        private eventEmitterService: EventEmitterService,
        public taskService: TaskService,
        public changeDetectorRef: ChangeDetectorRef,
        public instanceService: InstanceService) {
        //this.isSendForm = false;
        if (typeof this.eventEmitterService.subsSendForm == "undefined") {
            this.eventEmitterService.subsSendForm = this.eventEmitterService.invokeSendFormFunction.subscribe(sub => {
                console.log("invokeSendFormFunction");

                this.sendFormData(sub.status, sub.name, sub.reason);
            });
        }

    }
    deletedFileIds: any = [];
    invokeChangeEventLocal: Subscription;
    invokeFileDeleteLocal: Subscription;
    ngOnInit() {

        

        if (this.appInfo.currentUser.deflang) this.languageCode = this.appInfo.currentUser.deflang.replace(/-/gi, '');
        else this.languageCode = "trTR";

        this.currentProcessFormId = this.form.processFormId;

        this.renderForm();

        this.invokeChangeEventLocal = this.eventEmitterService.invokeColumnSumFunction.subscribe(sub => {
            try {

                let curentComponents = this.componentService.getComponentList(this.componentService.currentForm.components);
                let curentForm = curentComponents.find(x => x.id == sub.id);
                if (curentForm || sub.id == "sys_re_set_val") {
                    //this.componentService.currentForm.components.find(x => x.id == sub.id).value = sub.sum;
                    curentForm.value = sub.sum;
                    //this.changeDetectorRef.detectChanges();
                }

            } catch (e) {

            }
        });

        this.invokeFileDeleteLocal = this.eventEmitterService.invokeDeletedFileIdsFunction.subscribe(sub => {
            try {

                let curentDeletedFile = this.deletedFileIds.find(x => x.id == sub.id);
                if (!curentDeletedFile) {
                    this.deletedFileIds.push(sub.id);
                }

            } catch (e) {

            }
        });



        //
        //this.eventEmitterService.getAllComponent(this.componentService.currentForm.components);

    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
        this.invokeFileDeleteLocal.unsubscribe();
    }
    locationButtons: any = [];
    paymentButtons: any = [];
    zoomButtons: any = [];
    //liveWebButton: any;
    renderForm() {
        
        if (typeof this.form.formJson !== "undefined") {
            this.componentService.currentProcessFormId = this.form.processFormId;
            this.prevUserId = this.form.prevUserId;
            let formJson = JSON.parse(this.form.formJson);
            let formData = JSON.parse(this.form.formValues);
            
            this.componentService.checkUserAuthorization(this.form.readonly, formJson.components, this.appInfo.currentUser.id);


            //  this.componentService.hideComponentList(formJson.components);

            //formJson.components = formJson.components.filter(f => !f.hide);

            this.componentService.currentForm = formJson;
            this.componentService.currentForm.isReadonly = this.form.readonly;
            this.componentService.currentForm.formNo = this.form.formNo || 0;
            this.componentService.currentUserStaus = this.form.userStatus || 0;
            this.setStatus(this.form.userStatus);
            this.componentService.userHasApprove = this.form.userStatus == this.componentService.userStatus.FinalApprove || this.form.userStatus == this.componentService.userStatus.InterimApprove;
            this.componentService.setFormValues(this.componentService.currentForm.components, formData);
            this.executeExp();
            this.invokeFunctions();

            let menus = this.componentService.getComponentsByType("menu");
            if (menus && menus.length > 0) {
                this.isMenuForm = true;
            }
            else {
                this.isMenuForm = false;
            }

            let curentFormFiles = this.componentService.getFilesComponentList(this.componentService.currentForm.components);
            let _idArray = [];
            curentFormFiles.forEach(file => {
                file.value.forEach(fileVal => {
                    let compSend = {
                        id: fileVal,
                        componentId: file.id,
                        type: file.type,
                        row: -1,
                        column: "-1"
                    };
                    _idArray.push(compSend);//   src: 'data:' + fileType + ';base64,' + base64BlobDat a,
                });
            });
            let datagridComps = this.componentService.getComponentList(this.componentService.currentForm.components);
            if (this.componentService.currentProcessFormId != 0) {

                let datagridCompsFilter = datagridComps.filter(x => x.type == "datagrid");
                if (datagridCompsFilter.length > 0) {
                    datagridCompsFilter.forEach(_dataGrid => {
                        let _row = 0;
                        let datagridHeaderFiles = _dataGrid.headers.filter(x => x.type == "file");

                        _dataGrid.value.forEach(_dataGridValue => {
                            datagridHeaderFiles.forEach(_headerFile => {

                                let IdArrayLast = [];
                                let _column = _headerFile.field;
                                let valArray = _dataGridValue[_column];
                                if (typeof valArray != "undefined" ) {

                                    valArray.forEach(valId => {
                                        IdArrayLast.push(valId);
                                    });

                                    let compSend = {
                                        id: IdArrayLast,
                                        componentId: _dataGrid.id,
                                        type: _dataGrid.type,
                                        row: _row,
                                        column: _column//"-1"
                                    };
                                    _idArray.push(compSend);
                                }

                            });

                        });

                    });
                }

                if (_idArray.length > 0) {
                    let _idArrayInt = _idArray.map(ele => ele.id);
                    let _idIntLast = [];
                    _idArrayInt.forEach(_idObj => {
                        if (Array.isArray(_idObj)) {
                            _idObj.forEach(_idObjTemp => {
                                _idIntLast.push(_idObjTemp);
                            });
                        }
                        else
                            _idIntLast.push(_idObj);
                    });

                    this.getFormFilesService(_idIntLast, datagridComps);

                }
            }
            else {

                if (_idArray.length > 0) {
                    let _idArrayInt = _idArray.map(ele => ele.id);
                    let _idIntLast = [];
                    _idArrayInt.forEach(_idObj => {
                        if (Array.isArray(_idObj)) {
                            _idObj.forEach(_idObjTemp => {
                                _idIntLast.push(_idObjTemp);
                            });
                        }
                        else
                            _idIntLast.push(_idObj);
                    });

                    this.getFormFilesService(_idIntLast, datagridComps);

                }

            }
            //else {
            //     
            //        curentFormFiles.forEach(file => {
            //            file.valueTem p = [];
            //        }); 
            //}
            
            let curentFormButtons = this.componentService.getButtonComponentList(this.componentService.currentForm.components);
            
            this.locationButtons = [];
            curentFormButtons.forEach(obj => {

                obj.buttons.forEach(objChild => {

                    if (objChild.isLocation)
                        this.locationButtons.push({ buttonCompId: obj.id, button: objChild });
                });
            });

            if (this.locationButtons.length > 0)
                this.getGeoLocation();

        }
    }
    getFormFilesService(_idIntLast, datagridComps) {
        this.instanceService.getFormFilesData(_idIntLast).subscribe((s: any) => {

            let currentComp;

            s.files.forEach(currentCompReturned => {
                currentComp = datagridComps.find(x => x.id == currentCompReturned.componentId);
                let componentType = currentComp.type;
                if (componentType == "datagrid") {

                    let currentCompHeaderColumn = currentComp.headers.find(x => x.field == currentCompReturned.columnId);

                    let document: any = {
                        id: currentCompReturned.id,
                        base64BlobData: currentCompReturned.fileData,
                        name: currentCompReturned.fileName,
                        fileType: currentCompReturned.fileType,
                        extension: currentCompReturned.fileExtension,
                        size: currentCompReturned.fileSize,
                        isImg: currentCompReturned.isImg
                    };
                    let doc: any = {};
                    doc.row = currentCompReturned.rowId;
                    doc.file = document;
                    currentCompHeaderColumn.files.push(doc);

                }
                else {

                    currentComp.valueTemp = [];

                    let document: any = {
                        id: currentCompReturned.id,
                        base64BlobData: currentCompReturned.fileData,
                        name: currentCompReturned.fileName,
                        fileType: currentCompReturned.fileType,
                        extension: currentCompReturned.fileExtension,
                        size: currentCompReturned.fileSize,
                        isImg: currentCompReturned.isImg
                    };

                    if (componentType == "picturebox")
                        document.src = 'data:' + document.fileType + ';base64,' + document.base64BlobData;

                    currentComp.valueTemp.push(document);

                }

            });

            this.changeDetectorRef.detectChanges();

        });
    }

    invokeFunctions() {
        if (typeof this.eventEmitterService.subsFormChange == "undefined") {
            this.eventEmitterService.invokeFormChangesFunction.subscribe((name: string) => {
                this.executeExp();
                this.componentService.evulateExpretion(this.componentService.currentForm.expretion);

            });
        }
        //if (typeof this.eventEmitterService.subsMenuSubmit == "undefined") {
        //    this.eventEmitterService.subsMenuSubmit = this.eventEmitterService.invokeMenuSubmitFunction.subscribe((sub: any) => {
        //        this.sendFormData(sub.status, sub.name, "");
        //    });
        //}

    }


    setStatus(userStatus) {
        if (userStatus == this.componentService.userStatus.DefaultStatus) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.InterimApprove) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" },
                //{ "status": this.componentService.formStatus.Returned, "text": "Geri Gönder", "type": "danger" },
                { "status": this.componentService.formStatus.Disapprove, "text": "Reddet", "type": "danger" },
                { "status": this.componentService.formStatus.DisapproveTerminate, "text": "Reddet ve Bitir", "type": "danger" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.FinalApprove) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.Approve, "text": "Onayla", "type": "success" },
                { "status": this.componentService.formStatus.Disapprove, "text": "Reddet", "type": "danger" }
            ];
        }
        else if (userStatus == this.componentService.userStatus.Completed) {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.Completed, "text": "Bitir", "type": "success" }
            ];
        }
        else {
            this.buttonActions = [
                { "status": this.componentService.formStatus.Processing, "text": "Kaydet", "type": "default" },
                { "status": this.componentService.formStatus.SaveAndSend, "text": "Kaydet ve Gönder", "type": "success" }
            ];
        }
    }

    screen(width) {
        return width < 720 ? "sm" : "md";
    }

    executeExp() {
        if (this.componentService.currentForm.expression) eval(this.componentService.currentForm.expression)
    }

    getById(id) {
        return this.componentService.getById(id, this.componentService.currentForm.components);
    }

    colSum(id, colm) {
        return this.componentService.colSum(id, colm, this.componentService.currentForm.components);
    }


    setValue(selectboxId, elementId, key) {
        return this.componentService.setValue(selectboxId, elementId, key, this.componentService.currentForm.components);
    }

    buttonNameGlobal: any = "";
    sendFormData(status, name, reason) {
        this.eventEmitterService.setHomePopupVisibleFunc(true);

        let curentFormButtons = this.componentService.getButtonComponentList(this.componentService.currentForm.components);
        
        this.locationButtons = [];
        curentFormButtons.forEach(obj => {

            obj.buttons.forEach(objChild => {

                if (objChild.isLocation)
                    this.locationButtons.push({ buttonCompId: obj.id, button: objChild });

                if (objChild.isPayment)
                    this.paymentButtons.push({ buttonCompId: obj.id, button: objChild });

                if (objChild.isZoom)
                    this.zoomButtons.push({ buttonCompId: obj.id, button: objChild });


            });

        });


        this.componentService.currentForm.components.forEach(el => {
            this.eventEmitterService.saveSystemInfoEvent(el.id, el.value);
        });



        this.currentFormData = [];
        this.buttonNameGlobal = name;
        this.createValuesData(this.componentService.currentForm.components);
        //var fff = this.currentFormData[0].value;

        let datagridComps = this.componentService.getComponentList(this.componentService.currentForm.components);

        let curentFormFiles = this.componentService.getFilesComponentList(this.componentService.currentForm.components);
        let objArray = [];

        curentFormFiles.forEach(file => {
            file.valueTemp.forEach(fileVal => {
                //value array içine gir
                //base64BlobDat a
                //extension 
                if (typeof fileVal.id == "undefined" || fileVal.id == 0) {
                    let obj: any = {
                        componentId: file.id,
                        rowId: "-1",
                        columnId: "-1",
                        fileData: fileVal.base64BlobData,
                        fileExtension: fileVal.extension,
                        fileType: fileVal.fileType,
                        isImage: fileVal.isImg,
                        name: fileVal.name,
                        size: fileVal.size
                    };

                    objArray.push(obj);
                }

            });
            file.valueTemp = [];
        });

        let datagridCompsFilter = datagridComps.filter(x => x.type == "datagrid");
        if (datagridCompsFilter.length > 0) {

            datagridCompsFilter.forEach(_dataGrid => {
                let datagridHeader = _dataGrid.headers.filter(x => x.type == "file");
                datagridHeader.forEach(_datagridHeader => {
                    let dataFiles = _datagridHeader.files;
                    let columnId = _datagridHeader.field;
                    dataFiles.forEach(_dataFile => {
                        if (typeof _dataFile.file.id == "undefined" || _dataFile.file.id == 0) {
                            let obj: any = {
                                componentId: _dataGrid.id,
                                rowId: _dataFile.row.toString(),
                                columnId: columnId.toString(),
                                fileData: _dataFile.file.base64BlobData,
                                fileExtension: _dataFile.file.extension,
                                fileType: _dataFile.file.fileType,
                                isImage: _dataFile.file.isImg,
                                name: _dataFile.file.name,
                                size: _dataFile.file.size
                            };
                            objArray.push(obj);
                        }
                    });
                });
            });

        }

        let filesIds = [];
        if (objArray.length > 0) {

            this.instanceService.saveFormFilesData(objArray, this.componentService.currentProcessFormId).subscribe((s: any) => {

                if (s.status == true) {
                    if (s.files.length > 0) {
                        filesIds = s.files.map(ele => ele.Id);
                        let _thisLocal = this;

                        _thisLocal.currentFormData.forEach(formData => {

                            let _fileVal = s.files.filter(x => x.ComponentId == formData.key);
                            if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                                if (formData.type == "datagrid") {

                                    _fileVal.forEach(_fileValObj => {
                                        let _tempData = formData.value.find(x => x.ID == parseInt(_fileValObj.RowId));
                                        if (_tempData)
                                            _tempData[_fileValObj.ColumnId] = _fileVal.filter(z => z.RowId == _fileValObj.RowId && z.ColumnId == _fileValObj.ColumnId).map(ele => ele.Id);
                                    });

                                }
                                else
                                    formData.value = _fileVal.map(ele => ele.Id);
                            }

                        });
                    }

                    this.callSaveFormData(status, reason, name, filesIds);
                }

            });

        } else {
            this.callSaveFormData(status, reason, name, filesIds);
        }





    }

    callSaveFormData(status, reason, name, filesIds) {

        let fData = new CurrentFormDataModel();
        fData.FromUserId = this.appInfo.currentUser.id;
        fData.ToUserId = this.appInfo.currentUser.id;
        fData.ProcessFormId = this.componentService.currentProcessFormId;

        fData.FormVariable = JSON.stringify(this.currentFormData, null, 4);
        fData.Description = this.getStatusText(status) + " (" + this.appInfo.currentUser.name + ")";
        fData.Status = status; //this.selectedstatus;
        fData.UserStatus = this.componentService.currentUserStaus;
        fData.RejectionReason = reason;
        fData.Users = this.form.users;
        fData.FilesIds = filesIds;
        fData.DeletedFilesIds = [];
        fData.DeletedFilesIds = this.deletedFileIds;
        
        let locationButton = this.locationButtons.find(x => x.button.property.name == name && x.button.property.status == status);

        if (typeof locationButton != "undefined" && this.locationGlobal != null)
            fData.GeoLocation = JSON.stringify({ lat: this.locationGlobal.latitude, lng: this.locationGlobal.longitude });

        let paymentButton = this.paymentButtons.find(x => x.button.property.name == name && x.button.property.status == status);
        let curentCustomer = "0";
        let curentPrice = "0";
        let curentHirePurchase = "0";
        let curentPaymentDescription = "";
        let paymentProvider = "0";
        let curentCustomerFormId = "0";
        let customerButton = {};
        if (typeof paymentButton != "undefined") {

            let curentComponents = this.componentService.getComponentList(this.componentService.currentForm.components);
            curentCustomer = curentComponents.find(x => x.id == paymentButton.button.customerId).value;
            curentPrice = curentComponents.find(x => x.id == paymentButton.button.priceComponent).value;
            curentHirePurchase = curentComponents.find(x => x.id == paymentButton.button.hirePurchaseComponent).value;
            curentPaymentDescription = curentComponents.find(x => x.id == paymentButton.button.descriptionComponent).value;
            customerButton = paymentButton.button.customer;
            paymentProvider = paymentButton.button.paymentProvider;
            curentCustomerFormId = paymentButton.button.customerFormId;

        }

        this.instanceService.saveFormData(fData, this.componentService.selectedSchemaId, curentCustomerFormId, customerButton, curentCustomer, curentPrice, curentHirePurchase, curentPaymentDescription, paymentProvider).subscribe((s: any) => {

            if (s != null) {
                s = s.replace('"', '').replace('"', '');
                this.eventEmitterService.updateTasks();
                this.componentService.isSentFormToDb = true;
                this.componentService.currentProcessFormId = 0;
                this.showToast("Form Başarıyla Gönderildi", "success");

                if (typeof paymentButton != "undefined") {
                    let _domain = window.location.protocol + "//" + window.location.host + "/" + s;
                    window.open(_domain, "_blank");
                }

            }
            this.eventEmitterService.setHomePopupVisibleFunc(false);
        });
    }

    locationGlobal: any;
    getCurrentPosition(options = {}) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    async getGeoLocation() {

        if (window.navigator && window.navigator.geolocation) {
            await window.navigator.geolocation.getCurrentPosition(
                position => {
                    this.locationGlobal = position.coords;
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.locationGlobal = null;
                            break;
                        case 2:
                            this.locationGlobal = null;
                            break;
                        case 3:
                            this.locationGlobal = null;
                            break;
                    }
                }
            );
        };
    }

    //süreç iptal edilecek
    cancelInstance() {
        let msg = "<i>İşlemi onaylıyor musunuz?</i>"
        let result = confirm(msg, "Süreç İptal Edilecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.currentFormData = [];
                this.createValuesData(this.componentService.currentForm.components);
                let fData = new CurrentFormDataModel();
                fData.FromUserId = this.appInfo.currentUser.id;
                fData.ToUserId = this.appInfo.currentUser.id;
                fData.ProcessFormId = this.currentProcessFormId;
                fData.FormVariable = JSON.stringify(this.currentFormData, null, 4);
                fData.Description = "Süreç, kullanıcı tarafından iptal edildi";
                fData.Status = this.componentService.formStatus.Cancelled;
                fData.RejectionReason = "";
                fData.IsAvalible = true;
                this.instanceService.saveFormData(fData, 0, "0", {}, "0", "0", "1", "", "").subscribe((s: any) => {
                    if (s != null) {
                        this.eventEmitterService.submitForm();
                    }
                });
            }
        });
    }

    getStatusText(status) {
        let ststu = "";
        if (status == this.componentService.formStatus.Approve) ststu = "Onayladı";
        else if (status == this.componentService.formStatus.Disapprove) ststu = "Reddedildi";
        else if (status == this.componentService.formStatus.SaveAndSend) ststu = "Gönderildi";
        else if (status == this.componentService.formStatus.Returned) ststu = "Geri Gönderildi";
        else if (status == this.componentService.formStatus.Cancelled) ststu = "İptal Edildi";
        else if (status == this.componentService.formStatus.DisapproveTerminate) ststu = "Reddedildi ve Süreç Bitirildi";
        else if (status == this.componentService.formStatus.Processing) ststu = "Kaydedildi";

        return ststu;
    }

    createValuesData(components) {
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true
                || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].inputType == "map"
                || components[i].inputType == "menu"
                || components[i].type == "gallery") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;

                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;


                if (components[i].type == "livestream") {
                    obj["meetingType"] = components[i].meetingType;
                }
                if (components[i].type == "button") {

                    // let selectedButton = components[i];//.find(x => x.property.name == this.buttonNameGlobal);
                    obj["name"] = this.buttonNameGlobal;// components[i].nameValue;
                    // obj["isLiveWeb"] = selectedButton.isLiveWeb;
                    //  obj["liveWeb"] = selectedButton.liveWeb;
                }

                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;

                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                }
                else if (components[i].type == "map") {

                    obj["markers"] = components[i].markers;
                }
                else {
                    obj["markers"] = [];
                    obj["headers"] = "";
                    obj["headerTypes"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                }
                this.currentFormData.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(components[i].items[j].components);
                }
            }


        }
    }


    getGridHeaders(grid) {
        let headers = [];
        let headerTypes = [];
        grid.headers.forEach(header => {
            var obj: any = {};
            if (header.type != "file") {

                headers.push(header.field);
                headerTypes.push(header.type);
            }
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }

    errorHandler(error) {
        let errorMessage = '';
        errorMessage = error.error instanceof ErrorEvent ? error.error.message : 'Error Code: ${error.status}\nMessage: ${error.message}';
        console.log(errorMessage);
        this.showToast(errorMessage, "error");
    }

    changeStatus(event) {

    }

    showToast(message, status) {
        notify(message, status, 3000);
    }
}
