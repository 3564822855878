
import { Component, OnInit, Input, ChangeDetectorRef, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { GenericService } from '../../shared/services/data/generic.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';


//declare var MediaRecorder: any;
declare var webkitSpeechRecognition: any;
declare var webkitSpeechGrammarList: any;
declare var webkitSpeechRecognitionEvent: any;


declare var SpeechRecognition: any;
declare var SpeechRecognitionEvent: any;
declare var SpeechGrammarList: any;

declare var SpeechRecognition: any;
declare var SpeechGrammarList: any;

@Component({
    selector: 'textarea-component',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.css']
})
export class DxTextareaComponent implements ComponentParameters, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";
    showDisplayBoolean = false;
    invokeChangeEventLocal: Subscription;

    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService,
        private zone: NgZone,
        public genericService: GenericService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {

                if (sub.data != null && typeof sub.data != "undefined") this.data.value = sub.data;
                else if (sub.elementId == "clean_element") this.data.value = "";
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                this.changeDetectorRef.detectChanges();
            }
        });


    }

    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    convertBlobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });




    // deutschland
    globalLanguageList: any = [
        { id: 0, text: "Türkçe", val1: "TR", val2: "tr" },
        //{ id: 1, text: "USA", val1: "US", val2: "en" },
        { id: 1, text: "English", val1: "EN", val2: "en" },
        { id: 2, text: "Français", val1: "FR", val2: "fr" },
        { id: 3, text: "Deutsche", val1: "DE", val2: "de" },
        { id: 4, text: "Español", val1: "ES", val2: "es" },
        { id: 5, text: "Italiano", val1: "IT", val2: "it" },
        { id: 6, text: "Pусский", val1: "RU", val2: "ru" },
        { id: 7, text: "日本語", val1: "JP", val2: "ja" },
        { id: 8, text: "中文", val1: "CN", val2: "cmn-Hans" },
        { id: 9, text: "한국어", val1: "KR", val2: "ko" },
        { id: 10, text: "हिन्दी", val1: "IN", val2: "hi" },
    ];

    globalLanguageValue: any = 0;

    //onLanguageChange(event) {
    //    
    //    this.globalLanguageValue = this.globalLanguageValue;
    //}






    @HostListener('window:sToTJS', ['$event'])
    speechToTextJSListener(event) {//
        this.data.value = event.detail;
    }
    @HostListener('window:sToTJSFinal', ['$event'])
    speechToTextJSFinalListener(event) {//
        this.data.value = event.detail;
    }

    final_transcript: any = '';
    interim_transcript: any = '';
    recognizing: any = false;
    recognition: any;
    speechToTextClick() {
        var _this = this;

        let _SpeechRecognition;
        let _SpeechGrammarList;
        let _SpeechRecognitionEvent;

        try {
            if (SpeechRecognition)
                _SpeechRecognition = SpeechRecognition
            else
                _SpeechRecognition = webkitSpeechRecognition

            if (SpeechGrammarList)
                _SpeechGrammarList = SpeechGrammarList
            else
                _SpeechGrammarList = webkitSpeechGrammarList

        }
        catch (e) {
            _SpeechRecognition = webkitSpeechRecognition
            _SpeechGrammarList = webkitSpeechGrammarList
        }


        if (this.recognizing) {
            this.recognition.onend = null;
            this.recognition.continuous = false;
            this.recognition.stop();
            this.recognizing = false;

            let fff = this.final_transcript;
            this.data.value = fff;

        }
        else {
            this.recognition = new _SpeechRecognition();
            var speechRecognitionList = new _SpeechGrammarList();

            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = this.globalLanguageList[this.globalLanguageValue].val2 + "-" + this.globalLanguageList[this.globalLanguageValue].val1;
            //"tr-TR";
            this.recognition.start();

            //var grammar = '#JSGF V1.0 ISO8859-3;';
            //speechRecognitionList.addFromString(grammar, 1);
            //this.recognition.grammars = speechRecognitionList;

            this.recognition.onstart = function () {

                _this.recognizing = true;
            };

            this.recognition.onresult = function (event) {
                let interim_transcript = '';
                //if (typeof (event.results) == 'undefined') {
                //    recognition.onend = null;
                //    recognition.stop();

                //    return;
                //}
                //if (!_this.recognizing) {
                //    recognition.onend = null;
                //    recognition.stop();
                //    return;
                //}
                for (var i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        _this.final_transcript = _this.final_transcript + " " + event.results[i][0].transcript;
                    } else {
                        interim_transcript += event.results[i][0].transcript;
                    }
                }

                //  _this.data.value = interim_transcript ;
                if (interim_transcript)
                    window.dispatchEvent(new CustomEvent('sToTJS', { detail: interim_transcript }));
                if (_this.final_transcript)
                    window.dispatchEvent(new CustomEvent('sToTJSFinal', { detail: _this.final_transcript }));

            };

        }



        //this.zone.run(() => {

        //    let _SpeechRecognition;
        //    let _SpeechGrammarList;
        //    let _SpeechRecognitionEvent;

        //    try {
        //        if (SpeechRecognition)
        //            _SpeechRecognition = SpeechRecognition
        //        else
        //            _SpeechRecognition = webkitSpeechRecognition
        //    }
        //    catch (e) {
        //        _SpeechRecognition = webkitSpeechRecognition
        //    }

        //    var recognition = new _SpeechRecognition();
        //    recognition.continuous = true;
        //    recognition.interimResults = true;
        //    recognition.lang = "tr-TR";
        //    recognition.start(); 

        //    //recognition.onstart = function () {
        //    //    this.recognizing = true;
        //    //};

        //    recognition.onresult = function (event) {
        //        var interim_transcript = '';
        //        if (typeof (event.results) == 'undefined') {
        //            recognition.onend = null;
        //            recognition.stop(); 
        //            return;
        //        }
        //        if (!this.recognizing) {
        //            recognition.onend = null;
        //            recognition.stop(); 
        //            return;
        //        }
        //        for (var i = event.resultIndex; i < event.results.length; ++i) {
        //            if (event.results[i].isFinal) {
        //                final_transcript += event.results[i][0].transcript;
        //            } else {
        //                interim_transcript += event.results[i][0].transcript;
        //            }
        //        }
        //        final_transcript = capitalize(final_transcript);
        //        final_span.innerHTML = linebreak(final_transcript);
        //        interim_span.innerHTML = linebreak(interim_transcript);
        //        if (final_transcript || interim_transcript) {
        //            showButtons('inline-block');
        //        }
        //    };
        //}




        //});


    }


    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

}
