import { Component, OnInit, Input } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { GenericService } from '../../shared/services';

@Component({
    selector: 'app-datasource',
    templateUrl: './datasource.component.html',
    styleUrls: ['./datasource.component.scss']
})
export class DxDatasourceComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;
    ismonocoActive: any = false;

    showSettingPopup: boolean = false;
    constructor(private eventEmitterService: EventEmitterService, private genericService: GenericService, public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
                setTimeout(f => {
                    this.ismonocoActive = true;
                }, 1000);
            }
        });
    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        //if(!this.isEdit) this.executeScript();
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.ismonocoActive = false; 
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.ismonocoActive = false; 
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }


    openSettings() {
        this.data.showEditPop = true;
    }

    onValueChanged(event) {
        this.data.value = event.value;
        this.eventEmitterService.executeExpretion();
    }

    executeScript() {
        if (this.data.query != "" && this.data.connectionString != "") {
            this.genericService.getDataTable(this.data.query, this.data.connectionString).subscribe(result => {
                this.data.dataSource = result;
            });
        }
    }

}
