import { Component, OnInit, Input } from '@angular/core';
import { ComponentParameters } from '../../component.parameters';
import { ComponentService } from '../../component.service';
import { GenericService, AppInfoService } from '../../../shared/services';
//declare var myfunction: any;
import notify from "devextreme/ui/notify";

@Component({
  selector: 'sqlcommand-component',
  templateUrl: './sqlcommand.component.html',
  styleUrls: ['./sqlcommand.component.css']
})
export class DxSqlcommandComponent implements OnInit {
  @Input() item: any;

  selectedConnection: any;
  editorOptions = { theme: 'vs', language: 'sql' };
  connections: any = [];
  constructor(public appInfo: AppInfoService, private componentService: ComponentService, private genericService: GenericService) { }

  ngOnInit() {
    this.connections = this.appInfo.RiverEntity.connections;
    this.selectedConnection = this.connections ? this.connections[0].id : 0;
    this.item.connectionString = this.createConnectionString();
     // this.ismonocoActive = true;
      //setTimeout(f => {
      //    this.ismonocoActive = true;
      //}, 1000);
  }

    onInit(editor) {
        //setTimeout(f => {
        //    this.ismonocoActive = true;
        //}, 1000);
  }

  onValueChanged(event) {
    this.selectedConnection = event.value;
    this.item.connectionString = this.createConnectionString();
  }

  executeScript() {
    if (this.selectedConnection && this.item.query) {
      let connectionString = this.createConnectionString();
      this.genericService.getDataTable(this.item.query, connectionString).subscribe(result => {
        console.log(result);
        if (result && result.length > 0) {
          notify("Bağlantı başarılı. İşlenecek veri sayısı: " + result.length, "success", 4000);
        }
        else {
           notify("Veri bulunamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz", "warning", 4000);
        }
      }, err => {
          notify("Bağlantı sağlanamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz", "warning", 4000);
        });
    }
    else {
      notify("Lütfen bağlantınızı ve sql komutunuzu doldurunuz", "warning", 4000);
    }

  }

  createConnectionString() {
    if (this.selectedConnection > 0) {
      let conn = this.connections.find(x => x.id === this.selectedConnection);
      let connectionString =
        "Server=" + conn.serverName
        + ";Database=" + conn.databaseName
        + ";user ID=" + conn.userName
        + ";Password=" + conn.password + ";";
      return connectionString;
    }

  }
}
