import { NgModule } from '@angular/core';
import {
    DxPivotGridModule,
    DxChartModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxPopoverModule,
    DxSelectBoxModule, 
    DxCheckBoxModule,
    DxDateBoxModule,
    DxNavBarModule,
    DxListModule,
    DxBoxModule,
    DxToolbarModule,
    DxPopupModule,
    DxContextMenuModule,
    DxButtonGroupModule,
    DxDataGridModule,
    DxTabPanelModule,
    DxFormModule,
    DxScrollViewModule,
    DxSchedulerModule,
    DxDraggableModule,
    DxAccordionModule,
    DxDrawerModule,
    DxRadioGroupModule,
    DxSwitchModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxTagBoxModule,
    DxValidatorModule,
    DxFileUploaderModule,
    DxHtmlEditorModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    DxTooltipModule,
    DxFileManagerModule,
    DxColorBoxModule,
    DxTileViewModule,
    DxGalleryModule
} from 'devextreme-angular';

@NgModule({
    declarations: [],
    exports: [
        DxDataGridModule,
        DxFormModule,
        DxButtonModule,
        DxLoadPanelModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxSelectBoxModule, 
        DxCheckBoxModule,
        DxDateBoxModule,
        DxPopoverModule,
        DxNavBarModule,
        DxListModule,
        DxBoxModule,
        DxToolbarModule,
        DxPopupModule,
        DxContextMenuModule,
        DxButtonGroupModule,
        DxDataGridModule,
        DxTabPanelModule,
        DxFormModule,
        DxScrollViewModule,
        DxSchedulerModule,
        DxDraggableModule,
        DxAccordionModule,
        DxDrawerModule,
        DxRadioGroupModule,
        DxSwitchModule,
        DxDropDownBoxModule,
        DxTreeViewModule,
        DxTagBoxModule,
        DxValidatorModule,
        DxFileUploaderModule,
        DxHtmlEditorModule,
        DxPivotGridModule,
        DxChartModule,
        DxLoadIndicatorModule,
        DxValidationGroupModule,
        DxTooltipModule,
        DxFileManagerModule,
        DxColorBoxModule,
        DxTileViewModule,
        DxGalleryModule
    ],
    imports: [

    ],
    providers: [],
    bootstrap: []
})
export class DevExtremeModule { }
