import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AppInfoService } from '../../../shared/services';
import { ComponentService } from '../../component.service';
import { DxTreeViewComponent } from 'devextreme-angular';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Component({
    selector: 'permission-component',
    templateUrl: './permission.component.html',
    styleUrls: ['./permission.component.css']
})
export class DxPermissionComponent implements OnInit {
    @ViewChild(DxTreeViewComponent, { static: false }) treeView;
    @Input() permissions: any;

    groups: any = [];
    users: any = [];
    constructor(public appInfo: AppInfoService, private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {

        debugger;
        this.groups = this.appInfo.RiverEntity.userGroups;
        this.users = this.appInfo.RiverEntity.users;
        this.checkUserAndGroup();
    }

    checkUserAndGroup() {
        this.users.forEach(user => {
            user.readonly = this.permissions.readonlyUsers.indexOf(user.id) !== -1;
            user.hide = this.permissions.hideUsers.indexOf(user.id) !== -1;
        });

        this.groups.forEach(group => {
            let rGroup = this.permissions.readonlyGroup.find(f => f.id == group.id);
            let hGroup = this.permissions.hideGroup.find(f => f.id == group.id);
            group.readonly = typeof (rGroup) != "undefined" && rGroup.id > 0;
            group.hide = typeof (hGroup) != "undefined" && hGroup.id > 0;
        });
    }

    checkSelections() {
        for (let i = 0; i < this.users.length; i++) {
            let rUsersIndex = this.permissions.readonlyUsers.indexOf(this.users[i].id);
            let hUsersIndex = this.permissions.hideUsers.indexOf(this.users[i].id);
            //this.users[i].hide = hGroupIndex != -1 || hUsersIndex != -1;
            //this.users[i].readonly = rGroupIndex != -1 || rUsersIndex != -1;
        }
    }

    changeReadonly(isChecked, id, isGroup) {
        if (isChecked) {
            this.addReadonlyList(id, isGroup);
        }
        else {
            this.removeReadonlyList(id, isGroup);
        }

        //console.log(this.permissions);
    }

    changeHide(isChecked, id, isGroup) {
        if (isChecked) {
            this.addHideList(id, isGroup);
        }
        else {
            this.removeHideList(id, isGroup);
        }
        //console.log(this.permissions);
    }

    readonlyGroupAll = false;
    hidingGroupAll = false;
    readonlyUserAll = false;
    hidingUserAll = false;
    readonlyChangeAll(isChecked, isGroup) {

        if (isGroup) {
            this.groups.forEach(group => {
                if (isChecked) {
                    this.readonlyGroupAll = true;
                    this.addReadonlyList(group.id, true);
                }
                else {
                    this.readonlyGroupAll = false;
                    this.removeReadonlyList(group.id, true);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }
        else {
            this.users.forEach(user => {
                if (isChecked) {
                    this.readonlyUserAll = true;
                    this.addReadonlyList(user.id, false);
                }
                else {
                    this.readonlyUserAll = false;
                    this.removeReadonlyList(user.id, false);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }

        this.checkUserAndGroup();
        // try { this.changeDetectorRef.detectChanges(); } catch (e) { }
        //console.log(this.permissions);
    }

    hidingChangeAll(isChecked, isGroup) {
        if (isGroup) {
            this.groups.forEach(group => {
                if (isChecked) {
                    this.hidingGroupAll = true;
                    this.addHideList(group.id, true);
                }
                else {
                    this.hidingGroupAll = false;
                    this.removeHideList(group.id, true);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }
        else {
            this.users.forEach(user => {
                if (isChecked) {
                    this.hidingUserAll = true;
                    this.addHideList(user.id, false);
                }
                else {
                    this.hidingUserAll = false;
                    this.removeHideList(user.id, false);
                }
                try { this.changeDetectorRef.detectChanges(); } catch (e) { }
            });
        }

        this.checkUserAndGroup();
    }

    addReadonlyList(id, isGroup) {
        if (isGroup) {
            let extGroup = this.permissions.readonlyGroup.find(f => f.id == id);
            let group = this.groups.find(f => f.id == id);
            if (group && !extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (group.users && group.users.length > 0) {
                    let sp = group.users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.permissions.readonlyGroup.push(grp);
            }
        }
        else {
            let extUser = this.permissions.readonlyUsers.find(f => f == id);
            if (!extUser)
                this.permissions.readonlyUsers.push(id);
        }
    }

    removeReadonlyList(id, isGroup) {
        if (isGroup && this.permissions.readonlyGroup.length) {
            let extGroup = this.permissions.readonlyGroup.find(f => f.id == id);
            if (extGroup) {
                this.permissions.readonlyGroup.splice(this.permissions.readonlyGroup.indexOf(extGroup), 1);
            }
        }
        else if (this.permissions.readonlyUsers.length) {
            let _in = this.permissions.readonlyUsers.indexOf(id);
            if (_in != -1)
                this.permissions.readonlyUsers.splice(_in, 1);
        }
    }

    addHideList(id, isGroup) {
        if (isGroup) {
            let extGroup = this.permissions.hideGroup.find(f => f.id == id);
            let group = this.groups.find(f => f.id == id);
            if (group && !extGroup) {
                let grp: any = {};
                grp.id = id;
                grp.users = [];
                if (group.users && group.users.length > 0) {
                    let sp = group.users.split(",");
                    sp.forEach(f => {
                        if (f) grp.users.push(parseInt(f));
                    });
                }
                this.permissions.hideGroup.push(grp);
            }
        }
        else {
            let extUser = this.permissions.hideUsers.find(f => f == id);
            if (!extUser)
                this.permissions.hideUsers.push(id);
        }
    }

    removeHideList(id, isGroup) {
        if (isGroup && this.permissions.hideGroup.length) {
            let extGroup = this.permissions.hideGroup.find(f => f.id == id);
            if (extGroup) {
                this.permissions.hideGroup.splice(this.permissions.hideGroup.indexOf(extGroup), 1);
            }
        }
        else if (this.permissions.hideUsers.length) {
            let _in = this.permissions.hideUsers.indexOf(id);
            if (_in != -1)
                this.permissions.hideUsers.splice(_in, 1);
        }
    }


}
