export class CurrentFormDataModel {
    ProcessFormId: number;
    FromUserId: number;
    ToUserId: number;
    FormVariable: string;
    Description: string;
    Status: number;
    UserStatus: number;
    RejectionReason: string;
    GeoLocation: string;
    Users: string;
    IsAvalible: boolean;

    CustomerFormId: string;
    CustomerFormComponent: string;
    Customer: string;
    Price: string;
    FilesIds: any;
    DeletedFilesIds: any;
}
