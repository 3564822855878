export class FormModel {
    ID: number;
    USER: string;
    NAME: string;
    DESCRIPTION: string;
    FORM_JSON: string;
    FORM_VARIABLE:string;
    CATEGORY_ID: number;
    ISMENU: boolean;
    USEDFORMS: string;
    MENUOPTIONS: string;
    FORMCOLOR: string;
}



