import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'formresult-component',
    templateUrl: './formresult.component.html',
    styleUrls: ['./formresult.component.css']
})
export class DxFormResultComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;

    showDisplay: any = "flex";

    dataTypes = [{ "name": "Decimal", "format": "#,##0.00", "type": "int" }, { "name": "Number", "format": "#", "type": "decimal" }];
    possitions = [{ "name": "Sol", "pos": "left" }, { "name": "Sağ", "pos": "right" }];
    isFormatDisabled: boolean = false;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    constructor(private eventEmitterService: EventEmitterService, public componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
       
        //this.eventEmitterService.invokeColumnSumFunction.subscribe(sub => {
        //    
        //    if (sub.id === this.data.id || sub.id == "sys_re_set_val") {
        //        this.data.value = sub.sum;
        //        this.changeDetectorRef.detectChanges();
        //    }
        //});
    }

    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
         
        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                this.changeDetectorRef.detectChanges();
            }
        });

    }

    update(): void {
        this.data.value = this.data.value;
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onChange() {
        //this.eventEmitterService.executeExpretion();
    }

    onValueChanged(event) {
        this.isFormatDisabled = event.value == "#";
        if (this.isFormatDisabled) this.data.inputType = "int";
        else this.data.inputType = "decimal";
    }
}
