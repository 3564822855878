import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, Inject, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DxFormResultComponent } from "../formresult/formresult.component";

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { GenericService } from '../../shared/services';
import { AppInfoService } from '../../shared/services';
import { ComponentService } from '../component.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { error } from '@angular/compiler/src/util';
import { Subscription } from 'rxjs';
//import { FormRender } from '../_formrender/formrender';

//import {evaluate} from 'mathjs'
export class HeaderExpression {
    code: string;
    export: boolean;
    element: string;
}

@Component({
    selector: 'datagrid-component',
    templateUrl: './datagrid.component.html',
    styleUrls: ['./datagrid.component.scss']
})
export class DxEtrDataGridComponent implements ComponentParameters, OnInit, AfterViewInit, OnDestroy {
    @ViewChild("dataGridEtr", { static: false }) dataGridEtr: DxDataGridComponent;
    @ViewChild(DxFormResultComponent, { static: false }) formResultComponent: DxFormResultComponent;

    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;

    showDisplay: any = "flex";

    dbQueryTypes = { "insert": 1, "update": 2, "delete": 3 };
    users: any = [];
    fieldTypes: any[] = [
        { "type": "text", "value": "text", "name": "Text" },
        { "value": "double", "name": "Ondalık Sayı" },
        { "value": "int", "name": "Tam Sayı" },
        { "value": "checkbox", "name": "Checkbox" },
        { "value": "date", "name": "Date" },
        { "value": "datetime", "name": "DateTime" },
        { "value": "datagrid", "name": "Data Grid" },
        { "value": "combobox", "name": "Combobox" },
        { "value": "file", "name": "File" }];

    headers: any = {
        "name": "",
        "caption": "",
        "field": "",
        "type": "text",
        "format": "#,##0.00",
        "template": "",
        "width": 150,
        "dataSourceKey": "",
        "dataSource": [],
        "displayKey": "",
        "valueKey": "",
        "funcParam": {},
        "files": [],
        "changeEvents": [],
        "expression": {},
        "dataGridSettings": {
            "connectionStringId": 0,
            "query": "",
            "key": "ID",
            "table": "",
            "formFields": []
        },
        "permissions": {
            "readonlyGroup": [],
            "readonlyUsers": [],
            "hideGroup": [],
            "hideUsers": []
        },
        "language": [
            {
                "Code": "trTR",
                "Label": "Başlık"
            }
        ]
    };

    headerExpression: HeaderExpression;
    isMonacoLoaded: boolean = false;

    sqlExpression = {
        "field": "",
        "connectionStringId": 0,
        "query": ""
    }

    selectedHeader: any;
    selectedHeaderIndex: number = -1;
    selectedFuncIndex: number = -1;
    selectedFormatIndex: number = -1;

    dataGridCols = [];

    selectionGridDataSource = [];


    dataGridInstance: any;
    dropDownOptions: object;

    dataSources: any = [];
    filterOperators = ["=", "!=", "<", ">", "<=", ">="];
    operators = ["+", "-", "*", "/"];
    sqlOperators = ["=", ">", "<", ">=", "<="];



    funcParam = {
        "blockoperator": "",
        "exp": "",
        "params": {
            "param1": "",
            "param2": "",
            "operator": ""
        }
    }

    funcParamData = {
        "export": false,
        "element": "",
        "formula": []
    }

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    filterPopup: boolean = false;
    showGridColPopop: boolean = false;
    showFuncPopup: boolean = false;
    showFormatPopup: boolean = false;
    showSqlExpressioPopup: boolean = false;
    showChanEventParamsPopup: boolean = false;

    selectedGridForm: any;

    dataGridSettingsPopop: boolean = false;
    selectedConnection: number = 0;
    connections: any = [];
    tableList: any = [];
    fileValue = [];

    colPermissionPopup: boolean = false;
    colLanguagePopup: boolean = false;
    selectedColPermission: any;
    selectedColLanguage: any;
    uploadedFiles: any = [];
    previewFileSrc: any;
    showPreviewFilesPopup: boolean = false;

    imageGaley = [];
    showGalleryPopup: boolean = false;
    perCol: string;
    headerFieldNames = [];
    selectionFields = [];
    exportElements = [];

    registeredLanguage: any;
    allowAdding: boolean = true;
    allowCopy: boolean = true;

    constructor(private eventEmitterService: EventEmitterService,
        public genericService: GenericService,
        public appInfo: AppInfoService,
        private componentService: ComponentService,
        //@Inject(FormRender) private formRender: FormRender,
        public changeDetectorRef: ChangeDetectorRef, private elementHtml: ElementRef) {
        this.headerExpression = new HeaderExpression();

        this.dropDownOptions = { width: 500 };
        this.users = this.appInfo.RiverEntity.users;
        this.users.forEach(f => { f.isChecked = false });
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
    }

    invokeChangeEventLocal: Subscription;
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        if (this.isEdit) {
            this.getDataSources();
            //localStorage.removeItem("dxDatagridSettingsOnDesigner");
        };

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {

                this.showLoadPanel();

                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";

                this.changeDetectorRef.detectChanges();

                this.applyDataSources();

                this.changeDetectorRef.detectChanges();

            }
        });


        this.outOfGridComponent = this.componentService.getComponentList(this.componentService.currentForm.components).filter(x => x.type !== "datagrid");

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {


            if (sub.elementId == this.data.id) {
                if (this.data.id != sub.id && sub.elementId == this.data.id) {

                    if (sub.data) {

                        let result = sub.data;
                        if (result /*&& result.length > 0*/) {

                            this.data.dataSource = result;
                            this.data.value = result;
                            this.changeDetectorRef.detectChanges();
                            this.dataGridEtr.instance.refresh();
                        }

                    }
                    else if (sub.elementId == "clean_element") {

                        this.data.dataSource = [];
                        this.data.value = [];
                        this.changeDetectorRef.detectChanges();
                        this.dataGridEtr.instance.refresh();
                    }
                }
                this.changeDetectorRef.detectChanges();



            }
        });

        // this.componentService.currentForm.components.filter(x => x.type !== "datagrid");


        //
        //this.eventEmitterService.invokeGetAllComponentFunction.subscribe(sub => {
        //    
        //    let curentForm = sub.component;//this.componentService.currentForm.components;
        //    if (curentForm) {
        //        this.outOfGridComponent = curentForm;
        //        this.changeDetectorRef.detectChanges();
        //    }
        //});


        // this.formRender.formc

        this.closeSqlExpressioPopup();

        //if (this.data.valu e.length > 0) {

        //    let _i = 0;
        //    let fields = Object.keys(this.data.valu e[0]);
        //    this.data.valu e.forEach(exp => {
        //        fields.forEach(exp_field => {
        //            this.executeSqlExpression (exp_field, exp, _i, false);
        //        });
        //        _i++;
        //    });

        //}


    }

    loadingVisible = false;
    loadPanelText = "Loading...";
    onShown() {
        //setTimeout(() => {
        //    this.loadingVisible = false;
        //}, 3000);
    }

    onHidden() {
    }

    showLoadPanel() {
        //this.dataGridEtr.instance.beginCustomLoading(this.loadPanelText);
        if (!this.isEdit)
            this.loadingVisible = true;
    }
    hideLoadPanel() {
        //this.loadingVisible = false;
        let _this = this;
        let myVarTimer = setTimeout(function () {
            clearTimeout(myVarTimer);
            debugger;
            //  _this.dataGridEtr.instance.beginCustomLoading(_this.loadPanelText);
            _this.loadingVisible = false;
            _this.changeDetectorRef.detectChanges();
        }, 1000);
    }


    forceRefreshDatagrid() {
        this.changeDetectorRef.detectChanges();
        this.dataGridEtr.instance.refresh();
        this.changeDetectorRef.detectChanges();
    }
    getDataGridSelectValue(columnField) { //cellInfo.column.dataField
        /////////////////////////////////////////////////////////////////////////////////////////////
        // let curr = this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == "bb");
        let header = this.data.headers.find(x => x.field == columnField);
        return header.isDefaultFirstValue && header.isDefaultFirstValue == true && header.dataSource.length > 0 ? header.dataSource[0][header.valueKey] : "";
        /////////////////////////////////////////////////////////////////////////////////////////////
    }
    getDataGridSelectValueWithDataSource(columnField, dataSource) { //cellInfo.column.dataField
        let header = this.data.headers.find(x => x.field == columnField);
        return header.isDefaultFirstValue && header.isDefaultFirstValue == true && dataSource.length > 0 ? dataSource[0][header.valueKey] : "";
    }
    getChechBoxValueByGridColumn(cell) {
        //let _val = cell.data[cell.column.name];
        // _val = typeof _val == "undefined" ? false : _val;
        cell.data[cell.column.name] = typeof cell.data[cell.column.name] == "undefined" ? false : !cell.data[cell.column.name];
        return cell.data[cell.column.name];
    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }

    ngAfterViewInit() {
        if (!this.isEdit) {
            this.applyDataSources();
            this.connections = this.appInfo.RiverEntity.connections;

        };
    }


    colShorting(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.headers, event.previousIndex, event.currentIndex);
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    onEditingStart(e) {

        this.datagridColsSql();

        var added = typeof e.key == "undefined" ? true : false;
        if (added) {
            this.allowCopy = false;
            let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
            elements[0].classList.add("dx-state-disabled");
        }
    }
    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.applyHeadersToDataGrid();
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }

        this.headers.dataGridSettings.key = "";
        this.headers.dataGridSettings.formFields = [];

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
                this.applyHeadersToDataGrid();

                //setTimeout(() => { }, 500);
            }
        });
    }



    applyDataSources() {

        let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid");

        if (dataCols && dataCols.length > 0) {

            this.prepareDataGridColumn(dataCols, 0);
        }
        else this.applyHeadersToDataGrid();

        //this.data.headers[this.selectedHeaderIndex].dataGridSettings.connectionString
    }

    datagridColsSql() {
        let dataCols = this.data.headers.filter(f => f.type == "combobox" || f.type == "datagrid");
        if (dataCols && dataCols.length > 0) {
            for (let j = 0; j < dataCols.length; j++) {

                let query = dataCols[j].dataGridSettings.query;
                let _type = dataCols[j].type;
                let connectionStringId = dataCols[j].dataGridSettings.connectionStringId;
                if (query != "" && connectionStringId > 0) {
                    query = this.editSqlByFormData(query);
                    this.genericService.executeQuerySecurited(query, connectionStringId).subscribe((result: any) => {
                        let fields = result.length > 0 ? Object.keys(result[0]) : undefined;
                        if (fields && _type == "combobox") {
                            result.forEach(item => {
                                if (fields.length == 1) {
                                    item.displayText = item[fields[0]];
                                }
                                else {
                                    let displayList = [];
                                    fields.forEach(f => {
                                        if (f != dataCols[j].valueKey) {
                                            displayList.push(item[f]);
                                        }
                                    });
                                    item.displayText = displayList.join(",");
                                }
                            });
                        }

                        dataCols[j].dataSource = result;

                    });
                }

            }
        }
    }

    editSqlByFormData(query) {
        let componentList = this.componentService.getComponentList(this.componentService.currentForm.components);

        var pattern = /( and | or | where |)\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
        var patt = new RegExp(pattern);
        if (patt.test(query)) {
            let _match = query.match(pattern);
            if (_match.length > 0) {
                _match.forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (_k) {
                        let _value = _k.value == null ? "" : _k.value;
                        query = query.replace(f, _value);
                    }
                });
            }
            //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
        }

        return query;

    }

    prepareDataGridColumn(cols, index) {

        //query
        //let query = "SELECT * FROM " + cols[index].dataGridSettings.table;
        let query = cols[index].dataGridSettings.query;
        let _type = cols[index].type;
        let connectionStringId = cols[index].dataGridSettings.connectionStringId;
        if (query != "" && connectionStringId > 0) {
            query = this.editSqlByFormData(query);

            //
            //var pattern = /( and | or | where |)\{[a-zA-Z0-9 _\[\],'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
            //var patt = new RegExp(pattern);
            //if (patt.test(query)) {
            //    query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
            //}

            this.genericService.executeQuerySecurited(query, connectionStringId).subscribe((result: any) => {
                //  if (result && result.length) {
                let fields = result.length > 0 ? Object.keys(result[0]) : undefined;
                if (fields && _type == "combobox") {
                    result.forEach(item => {
                        if (fields.length == 1) {
                            item.displayText = item[fields[0]];
                        }
                        else {
                            let displayList = [];
                            fields.forEach(f => {
                                if (f != cols[index].valueKey) {
                                    displayList.push(item[f]);
                                }
                            });
                            item.displayText = displayList.join(",");
                        }
                    });
                }

                cols[index].dataSource = result;
                if (index == cols.length - 1) {
                    this.applyHeadersToDataGrid();
                    return;
                }
                else {
                    this.prepareDataGridColumn(cols, ++index);
                }

                //   }


            });
        }
        else {
            if (index == cols.length - 1) {
                this.applyHeadersToDataGrid();
                return;
            }
            else {
                this.prepareDataGridColumn(cols, ++index);
            }

        }
    }

    getDataSources() {
        this.dataSources = this.componentService.getComponentsByType("datasource");
    }

    allowDeleting(e) {
        return true;
    }


    isCloneButtonVisible(e) {
        return !e.row.isEditing;
    }

    cloneRow(e) {
        //// e.row.isNewRow
        let currArray = this.selectionGridDataSource.filter(f => f.index == e.rowIndex);
        var _this = this;
        var newRow = typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;

        currArray.forEach(function (item) {
            let arr: any = {};
            arr.field = item.field;
            arr.index = newRow ? _this.dataGridEtr.instance.totalCount() + 1 : _this.dataGridEtr.instance.totalCount();
            arr.colIndex = item.colIndex;
            arr.data = item.data;
            _this.selectionGridDataSource.push(arr);
        });
        var clonedItem = Object.assign({}, e.row.data, { ID: (this.data.value[this.data.value.length - 1].ID + 1) });
        this.data.value.splice(this.data.value.length, 0, clonedItem);

        // this.onRowInserting.bind(this)
        for (let i = 0; i < this.data.headers.length; i++) {
            let exp = this.data.headers[i].expression;
            if (exp.export === true && typeof (exp.element) != "undefined") {

                let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, this.componentService.currentForm.components);
                this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
            }
        }

    }

    rowValidating(e) {
        //
        //let f = this;
        /*if (!e.newData.I D || e.newData.I D <=  0) {
          e.errorText = "The company can have only one";
          e.isValid = false;
        }*/
    }

    onInitNewRow(event) {
        //    
        if (this.data.sqlExpressions.length > 0) {
            this.data.sqlExpressions.forEach(exp => {
                let replacedQuery = this.componentService.cloneModel(exp.query);

                let rowIndex = (this.data.value.length - 1);
                rowIndex = rowIndex < 0 ? 0 : rowIndex;

                // event.data[exp.field] = this.getDataGridSelectValu e(exp.field);

                this.outOfDatagridGetSql(replacedQuery, rowIndex, exp.connectionStringId, exp.field, true, "", null);
            });
        }




        var elemVal_AllHeader = this.data.headers
        for (var j = 0; j < elemVal_AllHeader.length; j++) {
            if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && (elemVal_AllHeader[j].type == "number" || elemVal_AllHeader[j].type == "int" || elemVal_AllHeader[j].type == "double")) {
                if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "")
                    event.data[elemVal_AllHeader[j].field] = 0;
            }
            else if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && elemVal_AllHeader[j].type == "checkbox") {
                event.data[elemVal_AllHeader[j].field] = false;
            }

            if (typeof event.data.ID == "undefined" || event.data.ID == "") {
                event.data.ID = this.data.value.length === 0 ? 1 : this.data.value[this.data.value.length - 1].ID + 1;
                event.data[elemVal_AllHeader[j].field] = this.getDataGridSelectValue(elemVal_AllHeader[j].field);
            }

        }

    }

    onRowInserting(e) {
        // (this.selectedDataGridItem.data[this.selectedDataGridItem.data.length - 1].ID + 1)

        e.data.ID = e.data.ID;// this.data.value.length === 0 ? 1 : this.data.value[this.data.value.length - 1].ID + 1;

        let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
        elements[0].classList.remove("dx-state-disabled");
        e.component.refresh(true);

    }

    elementOutOfDatagrid() {
        let elementsOutOfDatagrid = this.componentService.elementsOutOfDatagrid;
        if (this.outOfGridComponent.length > 0 && elementsOutOfDatagrid.length > 0) {
            this.outOfGridComponent.forEach(f => {
                if (elementsOutOfDatagrid.filter(x => x == f.id).length > 0 && this.data.value.length > 0) {
                    f.disabled = true;
                }
                else {
                    f.disabled = false;
                }
            });
        }
    }
    //this.statuses
    onSelectionChanged(selectedRowKeys, cellInfo) {

        if (typeof cellInfo != "undefined") {

            let header = this.data.headers.find(f => f.name == cellInfo.column.name);
            if (header) this.selectedGridForm = header.dataGridSettings.formFields;

            if (selectedRowKeys.length > 0) {

                let data = cellInfo.column.lookup.items.find(f => f[cellInfo.column.lookup.valueExpr] == selectedRowKeys[0][cellInfo.column.lookup.valueExpr]);
                if (data) {
                    let display = data[cellInfo.column.lookup.valueExpr];
                    // cellInfo.setValue(display);

                    let rowIndexSelected = cellInfo.row.rowIndex;
                    let selectedIndex = -1;
                    var newRow = typeof cellInfo.row.isNewRow == "undefined" ? false : cellInfo.row.isNewRow;
                    if (newRow && cellInfo.row.data != null && typeof cellInfo.row.data.ID != "undefined") {
                        selectedIndex = this.data.value.findIndex(x => x.ID == cellInfo.row.data.ID);
                    }
                    if (selectedIndex != -1) {
                        this.data.value[selectedIndex][header.field] = display;
                        this.forceRefreshDatagrid();
                        this.executeSqlExpression(header.field, this.data.value[selectedIndex], selectedIndex, false);
                    }
                    else {
                        cellInfo.setValue(display);
                        // dropDownBoxComponent.close();
                        if (header) {

                            this.executeSqlExpression(header.field, cellInfo.row.data, cellInfo.row.rowIndex, newRow);
                        }
                    }


                }
            }
            this.showDatagridPopup = false;
            this.cellInfoGlobal = undefined;
        }
    }

    cellTemplate(container, options) {
        var noBreakSpace = "\u00A0",
            text = (options.value || []).map(element => {
                return options.column.lookup.calculateCellValue(element);
            }).join(", ");
        container.textContent = text || noBreakSpace;
        container.title = text;
    }
    isCalcCellFormula: boolean = false;
    onEditorPreparing(e) {
        if (e.parentType === "dataRow" && !this.isCalcCellFormula) {

            //this.dataGridEtr.instance.cellValue(rowIndex, exp.field, value);
            // 
            // if (e.row.isNewRow) {

            //cells
            //
            //e.row.cells.forEach(f => {

            //    if (f.column.cellTemplate == "checkboxTemplate") {
            //        if (f.value == null) {
            //            
            //            try {
            //                e.setValue(false);
            //                this.dataGridEtr.instance.cellValue(e.row.rowIndex, f.column.name, false);
            //                this.data.valu e[e.row.rowIndex][f.column.name] = false;
            //            } 
            //            catch (ex) { }
            //        }
            //        // f.value = f.value == null ? false : f.value;
            //    }
            //});

            //if (f.column.cellTemplate === "checkboxTemplate" || typeof f.column.dataType !== "undefined") {
            //    let defaultVal: any;// text      combobox
            //    if (f.column.cellTemplate == "checkboxTemplate")
            //        defaultVal = false;
            //    else if (f.column.dataType == "numbe r")
            //        defaultVal = 0;
            //    else if (f.column.dataType == "string")
            //        defaultVal = "";

            //    if (f.value == null) {
            //        
            //        e.setValue(defaultVal);
            //        this.dataGridEtr.instance.cellValue(e.row.rowIndex, f.column.name, defaultVal);
            //        this.data.valu e[e.row.rowIndex][f.column.name] = defaultVal;
            //    }

            //}
            //
            //let f =e.dataField;
            //let f1 = e.row.data;
            //let f2 = e.row.rowIndex;

            //let f3 = this.data.headers;

            //if (this.data.headers.find(x => x.field === dataField)) {
            //  this.dataGridEtr.instance.cellValue(rowIndex, dataField, result);


            //   }

            e.editorOptions.onValueChanged = args => {

                e.setValue(args.value);

                var newRow = typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;
                this.executeSqlExpression(e.dataField, e.row.data, e.row.rowIndex, newRow);
            };
            //  var dataField = e.dataField;

            //e.editorOptions.onFocusOut = args => {
            //  var rowData = e.row.data;
            //  var rowIndex = e.row.rowIndex;
            //  let fields = this.data.headers.map(function (value) {
            //    return value.field;
            //  });

            //  for (var i = 0; i < fields.length; i++) {
            //    this.cellCalcFormula(fields[i], rowData, rowIndex, fields);
            //    this.isCalcCellFormula = false;
            //  }
            //};

            //e.editorOptions.onFocusIn = args => {
            //  if ( (/*_newRow || */(e.type == "selection" && e.command == "select")) ) {
            //    var rowData = e.row.data;
            //    var rowIndex = e.row.rowIndex;
            //    let fields = this.data.headers.map(function (value) {
            //      return value.field;
            //    });

            //    //  if (e.row.isEditing && !this.isCalcCellFormula && (_newRow || fields.indexOf(e.dataField) > -1)/*(_newRow || (e.type == "selection" && e.command == "select"))*/) {
            //    for (var i = 0; i < fields.length; i++) {
            //      this.cellCalcFormula(fields[i], rowData, rowIndex, fields);
            //      this.isCalcCellFormula = false;
            //    }
            //  }
            //  // }
            //  //var __e = e;
            //  //var _args = args;
            //  //var editor = args.component;
            //  //
            //  //
            //  //alert(editor._options.value);
            //};

            //var _newRow = typeof e.row.isNewRow == "undefined" ? false : e.row.isNewRow;
            //if (e.row.isEditing && !this.isCalcCellFormula && (_newRow || fields.indexOf(e.dataField) > -1)/*(_newRow || (e.type == "selection" && e.command == "select"))*/) {
            //  for (var i = 0; i < fields.length; i++) {
            //    this.cellCalcFormula(fields[i], rowData, rowIndex, fields);
            //    this.isCalcCellFormula = false;
            //  }
            //}
        }

    }
    outOfDatagridGetSqlFinish: any = true;
    executeSqlExpression(field, data, rowIndex, newRow) {

        this.outOfDatagridGetSqlFinish = true;

        if (this.data.sqlExpressions.length > 0) {
            /*let hasExp = this.data.sqlExpressions.some(x => x.query.indexOf(field) > -1);
            if (hasExp) { }*/
            this.data.sqlExpressions.forEach(exp => {
                let tThis = this;
                let checkExist = setInterval(function () {
                    if (tThis.outOfDatagridGetSqlFinish) {
                        clearInterval(checkExist);
                        tThis.outOfDatagridGetSqlFinish = false;
                        let replacedQuery = tThis.componentService.cloneModel(exp.query);
                        let fields = Object.keys(data);
                        fields.forEach(f => {
                            let pref = "{" + f + "}";
                            let value = data[f];
                            if (value != null && typeof value != "undefined" && typeof value.getMonth === 'function') {
                                value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                                replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                            }
                            else
                                replacedQuery = tThis.replaceAll(replacedQuery, pref, value);
                        });


                        tThis.outOfDatagridGetSql(replacedQuery, rowIndex, exp.connectionStringId, exp.field, newRow, field, data);
                    }

                }, 300);



            });

        }
    }

    outOfDatagridGetSql(replacedQuery, rowIndex, connectionStringId, field, newRow, fieldGeneral, dataSelected) {

        let fullOutComponentValue = [];
        this.outOfGridComponentDatas = this.componentService.formComponentList;
        if (this.outOfGridComponent.length > 0) {

            let sqlExpressionTag = replacedQuery.match(/\{(\w)+\}/ig);
            if (sqlExpressionTag != null && typeof sqlExpressionTag != "undefined" && sqlExpressionTag.length > 0) {
                sqlExpressionTag[0] = sqlExpressionTag[0].replace("{", "").replace("}", "");
                let isActive = this.outOfGridComponent.filter(x => x.id == sqlExpressionTag[0]);
                if (isActive.length > 0) {
                    let pref = "{" + isActive[0].id + "}";
                    let value = this.outOfGridComponentDatas.find(x => x.id == isActive[0].id).value;
                    if (value != null && typeof value != "undefined" && value != "") {//true
                        if (typeof value.getMonth === 'function') {
                            value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
                            replacedQuery = this.replaceAll(replacedQuery, pref, value);
                        }
                        else if (typeof value.match === 'function' && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?/gm)) {
                            value = value.split("T")[0];
                            replacedQuery = this.replaceAll(replacedQuery, pref, value);
                        }
                        else
                            replacedQuery = this.replaceAll(replacedQuery, pref, value);
                    }
                    else {
                        replacedQuery = "";
                    }

                }
            }

        }



        //!sqlExpressionTagControl.toString().indexOf("{CURRENTUSERID}")
        let sqlExpressionTagControl = replacedQuery.match(/\{(\w)+\}/ig);
        //let ifControl = false;
        if (replacedQuery != "" && (sqlExpressionTagControl == null || typeof sqlExpressionTagControl == "undefined" || sqlExpressionTagControl.length == 0 || (
            (sqlExpressionTagControl.length == 1 && sqlExpressionTagControl[0] == "{CURRENTUSERID}")
        ))
        ) {
            this.genericService.executeQuerySecurited(replacedQuery, connectionStringId).subscribe((result: any) => {


                if (result && result.length) {


                    let header = this.data.headers.find(f => f.field == field);
                    if (header.type == "combobox" || header.type == "datagrid") {
                        let fields = Object.keys(result[0]);
                        if (fields && header.type == "combobox") {
                            result.forEach(item => {
                                if (fields.length == 1) {
                                    item.displayText = item[fields[0]];
                                }
                                else {
                                    let displayList = [];
                                    fields.forEach(f => {
                                        if (f != header.valueKey) {
                                            displayList.push(item[f]);
                                        }
                                    });
                                    item.displayText = displayList.join(",");
                                }
                            });
                            // cols[index].dataSource = result;
                        }


                        if (fieldGeneral != field) {
                            if (newRow && dataSelected != null && typeof dataSelected.ID != "undefined") {
                                rowIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                                newRow = false;
                            }
                            rowIndex = newRow ? this.dataGridEtr.instance.totalCount() : rowIndex;
                            //if (!newRow) {
                            //    this.data.value[rowIndex][field] = result[0][header.valueKey];
                            //}
                            if (rowIndex == -1) {
                                let selectedIndex = rowIndex;
                                if (newRow && dataSelected != null && typeof dataSelected.ID != "undefined") {
                                    selectedIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                                    newRow = false;
                                }
                                if (selectedIndex == -1)
                                    selectedIndex = 0;
                                rowIndex = selectedIndex;
                            }
                            var cellObject = this.dataGridEtr.instance.getCellElement(rowIndex, field);

                            if (typeof cellObject != "undefined") {

                                var columnIndex = cellObject["cellIndex"];
                                let curr = this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == field);
                                if (curr) {
                                    curr.data = [];
                                    curr.data = result;
                                }
                                else {
                                    let arr: any = {};
                                    arr.field = field;
                                    arr.index = rowIndex;
                                    arr.colIndex = columnIndex;
                                    arr.data = result;
                                    this.selectionGridDataSource.push(arr);
                                    curr = arr;
                                }


                                rowIndex = newRow ? 0 : rowIndex;
                                var selectedValue = this.dataGridEtr.instance.cellValue(rowIndex, field);
                                if ((typeof selectedValue == "undefined" || selectedValue == null || selectedValue == "")) {
                                    if (newRow) {
                                        this.dataGridEtr.instance.cellValue(rowIndex, field, "");
                                    }
                                    else
                                        this.data.value[rowIndex][field] = "";
                                    this.forceRefreshDatagrid();
                                }

                                var selectedValueFirstVal = this.getDataGridSelectValueWithDataSource(field, result);
                                if (newRow && !(typeof selectedValueFirstVal == "undefined" || selectedValueFirstVal == null || selectedValueFirstVal == "")) {
                                    this.dataGridEtr.instance.cellValue(rowIndex, field, selectedValueFirstVal);
                                    if (dataSelected)
                                        dataSelected[field] = selectedValueFirstVal;
                                }
                                else if ((typeof selectedValue == "undefined" || selectedValue == null || selectedValue == "") && !(typeof selectedValueFirstVal == "undefined" || selectedValueFirstVal == null || selectedValueFirstVal == "")) {
                                    this.data.value[rowIndex][field] = selectedValueFirstVal;
                                    if (dataSelected)
                                        dataSelected[field] = selectedValueFirstVal;
                                    this.forceRefreshDatagrid();
                                }
                                else if (!(typeof selectedValueFirstVal == "undefined" || selectedValueFirstVal == null || selectedValueFirstVal == "")) {
                                    let selectedIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                                    this.data.value[selectedIndex][field] = selectedValueFirstVal;
                                    if (dataSelected)
                                        dataSelected[field] = selectedValueFirstVal;
                                    this.forceRefreshDatagrid();
                                }
                            }

                        }

                    }
                    else {
                        let keys = Object.keys(result[0]);
                        if (keys.length > 0) {
                            let finalData = [];
                            keys.forEach(f => {
                                finalData.push(result[0][f]);
                            });
                            let value = finalData.join(",");
                            //var cellObject = this.dataGridEtr.instance.getCellElement(rowIndex, field);
                            // this.dataGridEtr.instance.setValue(rowIndex,"",value);
                            //rowIndex = newRow ? 0 : rowIndex;

                            let rowIndexSelected = rowIndex;
                            let selectedIndex = -2;
                            if (newRow && dataSelected != null && typeof dataSelected.ID != "undefined") {
                                selectedIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                                rowIndexSelected = selectedIndex != -1 ? selectedIndex : rowIndex;
                            }
                            else if (dataSelected != null && typeof dataSelected.ID != "undefined") {
                                selectedIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                            }

                            if (selectedIndex >= 0) {
                                this.data.value[rowIndexSelected][field] = value;
                            }
                            else {
                                this.dataGridEtr.instance.cellValue(rowIndexSelected, field, value);

                                var cellObject = this.dataGridEtr.instance.getCellElement(rowIndex, field);
                            }

                            if (dataSelected)
                                dataSelected[field] = value;

                            this.changeDetectorRef.detectChanges();
                            this.changeDetectorRef.markForCheck();
                            this.dataGridEtr.instance.refresh();
                            this.changeDetectorRef.detectChanges();

                            let _evt = { data: {} };

                            let _tempValIndex = this.data.value.findIndex(x => x.ID == dataSelected.ID);
                            if (this.data.value[_tempValIndex]) {
                                _evt.data = this.data.value[_tempValIndex];
                                if (_evt && _evt.data)
                                    this.executeExp(_evt, this.dbQueryTypes['update']);
                            }/*  else
                                _evt.data = this.dataGridEtr.instance.option("dataSource");//this.dataGridEtr.instance.getDataSource();
*/

                            //this.executeExp(_evt, this.dbQueryTypes['update']);
                            //this.dataGridEtr.instance.cellValue(rowIndex, field, value);
                        }
                    }

                }

                this.outOfDatagridGetSqlFinish = true;

            }, error => {
                this.outOfDatagridGetSqlFinish = true;
                console.log(error);
            });
        }
        else
            this.outOfDatagridGetSqlFinish = true;

    }

    getSelectionData(rowIndex, columnIndex, _type) {

        let column = this.dataGridEtr.instance.columnOption(columnIndex);
        console.log(column);
        if (column) {
            let curr = this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == "bb");
            console.log("curr");
            console.log(curr);
            if (curr) {
                return curr.data;
            }
        }
        return [];
    }
    getValueSelectBoxData(rowIndex, columnIndex) {
    }

    //checkboxDefault(e, cell) {
    //   
    //    return cell.data[cell.column.name] ? cell.data[cell.column.name] : false;
    //}
    controlFirst: any = true;
    cellInfoGlobal: any;
    showDatagridPopup: any = false;
    datagridButton = {
        icon: "data:data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJlbGxpcHNpcy1oIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1lbGxpcHNpcy1oIGZhLXctMTYgZmEtNXgiPjxwYXRoIGZpbGw9IndoaXRlIiBkPSJNMzI4IDI1NmMwIDM5LjgtMzIuMiA3Mi03MiA3MnMtNzItMzIuMi03Mi03MiAzMi4yLTcyIDcyLTcyIDcyIDMyLjIgNzIgNzJ6bTEwNC03MmMtMzkuOCAwLTcyIDMyLjItNzIgNzJzMzIuMiA3MiA3MiA3MiA3Mi0zMi4yIDcyLTcyLTMyLjItNzItNzItNzJ6bS0zNTIgMGMtMzkuOCAwLTcyIDMyLjItNzIgNzJzMzIuMiA3MiA3MiA3MiA3Mi0zMi4yIDcyLTcyLTMyLjItNzItNzItNzJ6IiBjbGFzcz0iIj48L3BhdGg+PC9zdmc+",
        type: "default"
        //,
        //onClick: () => {
        //    //this.passwordMode = this.passwordMode === "text" ? "password" : "text";
        //}
    };
    openDatagridPopup(row) {

        let header = this.data.headers.find(f => f.name == row.column.name);


        if (header) {
            var newRow = typeof row.row.isNewRow == "undefined" ? false : row.row.isNewRow;
            this.outOfDatagridGetSql(header.dataGridSettings.query, row.rowIndex, header.dataGridSettings.connectionStringId, header.field, newRow, "", null);
            //this.executeSqlExpression(, row.row.data,, newRow);
        }



        this.cellInfoGlobal = row;
        this.showDatagridPopup = true;
    }
    applyHeadersToDataGrid() {


        if (this.data.headers.length) {
            this.dataGridEtr.instance.option('columns', []);
            this.dataGridEtr.instance.addColumn({ name: "ID", dataField: "ID", caption: "ID", dataType: "number", width: 50, alignment: 'left', allowEditing: false });
            this.data.headers.forEach(header => {
                this.checkUserAuthorization(header);
                if (!header.hide) {
                    header.caption = this.componentService.getLang(this.lang, header.language).Label;
                    if (header.type == "text") {
                        this.dataGridEtr.instance.addColumn({ name: header.name, dataField: header.field, caption: header.caption, width: header.width, allowEditing: header.editable });
                    }
                    else if (header.type == "double") {
                        let params = header.expression;
                        if (params && typeof (params.code) != "undefined" && params.code != "") {
                            header.editable = false;
                        }

                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                dataField: header.field,
                                caption: header.caption,
                                dataType: "number",
                                format: header.format,
                                width: header.width,

                                //alignment: 'center',
                                allowEditing: header.editable
                            });
                    }
                    else if (header.type == "int") {
                        let params = header.expression;
                        if (params && typeof (params.code) != "undefined" && params.code != "") {
                            header.editable = false;
                        }

                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                dataField: header.field,
                                caption: header.caption,
                                dataType: "number",
                                format: "#,##0",
                                width: header.width,
                                //alignment: 'center',
                                allowEditing: header.editable
                            });
                    }
                    else if (header.type == "date" || header.type == "datetime") {

                        let defaultFormat = header.type == "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
                        let _format = header.format && header.format != "" && header.format != "#,##0.00" ? header.format : defaultFormat;
                        header.format = _format;
                        this.dataGridEtr.instance.addColumn({ name: header.name, dataField: header.field, caption: header.caption, dataType: header.type, format: _format, width: header.width, allowEditing: header.editable });
                    }
                    else if (header.type == "checkbox") {

                        this.dataGridEtr.instance.addColumn({ name: header.field, caption: header.caption, width: header.width, cellTemplate: "checkboxTemplate", allowEditing: header.editable });
                    }
                    else if (header.type == "file") {
                        this.dataGridEtr.instance.addColumn({ name: header.name, caption: header.caption, width: header.width, cellTemplate: "fileTemplate", allowEditing: header.editable });
                    }
                    else if (header.type == "datagrid") {
                        var _this = this;

                        //if (header.dataGridSettings.formField s) {
                        //    header.dataGridSettings.formField s = header.dataGridSettings.formField s;//.filter(f => !f.isHid e)
                        //}
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                caption: header.caption,
                                dataField: header.field,
                                allowEditing: header.editable || true,
                                dataType: 'string',
                                alignment: 'left',
                                width: header.width,
                                showEditorAlways: true,
                                editCellTemplate: "dropDownBoxEditor",
                                lookup: {
                                    allowClearing: true,
                                    //items: ,
                                    dataSource: function (options) {
                                        //var dataSourceConfiguration = {
                                        //    store: header.dataSource
                                        //};
                                        // 
                                        var totalCount = _this.dataGridEtr.instance.totalCount();
                                        var rowIndex = typeof options.rowIndex == "undefined" || options.isNewRow ? totalCount : options.rowIndex;

                                        var _dataSource = _this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == header.field);

                                        var dataSourceConfiguration = {
                                            store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                                        };
                                        return dataSourceConfiguration;
                                    },
                                    displayExpr: header.displayKey,
                                    valueExpr: header.valueKey
                                }
                            }
                        );
                    }
                    else if (header.type == "combobox") {

                        var _this = this;
                        this.dataGridEtr.instance.addColumn(
                            {
                                name: header.name,
                                caption: header.caption,
                                dataField: header.field,
                                allowEditing: header.editable,
                                dataType: 'string',
                                alignment: 'left',
                                showEditorAlways: true,
                                width: header.width,
                                //setCellValue: function (rowData: any, value: any) {

                                //  (<any>this).defaultSetCellValue(rowData, value);
                                //},
                                lookup: {
                                    disabled: header.editable,
                                    allowClearing: true,
                                    dataSource: function (options) {
                                        var totalCount = _this.dataGridEtr.instance.totalCount();

                                        var rowIndex = typeof options.rowIndex == "undefined" || options.isNewRow ? totalCount : options.rowIndex;

                                        var _dataSource = _this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == header.field);

                                        var dataSourceConfiguration = {
                                            store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
                                        };
                                        return dataSourceConfiguration;
                                    },
                                    displayExpr: "displayText",//header.displayKey,
                                    valueExpr: header.valueKey
                                }
                            }
                        );
                    }
                }
            });
            this.dataGridEtr.instance.addColumn({ caption: "", allowEditing: false });
            this.dataGridEtr.instance.addColumn({ caption: "*", width: 50, cellTemplate: "cloneButton" });
            this.applyFilter();

            this.dataGridEtr.instance.refresh();

            //if (this.controlFirst) {

            //    this.controlFirst = false;

            //    if (this.data.valu e.length > 0) {

            //        let _i = 0;
            //        let fields = Object.keys(this.data.valu e[0]);
            //        this.data.valu e.forEach(exp => {
            //            fields.forEach(exp_field => {
            //                this.executeSqlExpression (exp_field, exp, _i, false);
            //                let cellInfo: any = this.dataGridEtr.instance.getCellElement(_i, exp_field);
            //                cellInfo.column.looku p.items = this.selectionGridDataSource.find(f => f.index == _i && f.field == exp_field);getMonth
            //            });
            //            _i++;
            //        });

            //        this.dataGridEtr.instance.refresh( );

            //    }
            //    //this.data.sqlExpressions.forEach(exp => {
            //    //    let replacedQuery = this.componentService.cloneModel(exp.query);

            //    //    let rowIndex = (this.data.valu e.length - 1);
            //    //    rowIndex = rowIndex < 0 ? 0 : rowIndex;

            //    //    this.outOfDatagridGetSq l(replacedQuery, rowIndex, exp.connectionStringId, exp.field, true, "");
            //    //});

            //}

        }
        this.hideLoadPanel();
    }
    getDatagridkeyExpr(cellInfo) {
        if (typeof cellInfo != "undefined")
            return cellInfo.column.lookup.valueExpr
    }
    getDatagridSelectRowKey(cellInfo) {
        let arraySelected = [];
        if (typeof cellInfo != "undefined" && cellInfo.value != null && cellInfo.value != "") {
            arraySelected.push(cellInfo.value);
        }
        return arraySelected;
    }
    getDatagridSourceNew(cellInfo) {
        if (typeof cellInfo != "undefined") {

            var totalCount = this.dataGridEtr.instance.totalCount();
            var rowIndex = typeof cellInfo.row.rowIndex == "undefined" || cellInfo.row.isNewRow ? totalCount : cellInfo.row.rowIndex;

            var _dataSource = this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == cellInfo.column.dataField);
            let header = this.data.headers.find(x => x.field == cellInfo.column.dataField);

            var dataSourceConfiguration = {
                store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
            };
            cellInfo.column.lookup.items = dataSourceConfiguration.store;

            return dataSourceConfiguration;
        }
    }
    getDatagridSourceGetValue(cellInfo) {
        if (typeof cellInfo != "undefined") {

            //var totalCount = this.dataGridEtr.instance.totalCount();
            //var rowIndex = typeof cellInfo.row.rowIndex == "undefined" || cellInfo.row.isNewRow ? totalCount : cellInfo.row.rowIndex;

            //var _dataSource = this.selectionGridDataSource.find(f => f.index == rowIndex && f.field == cellInfo.column.dataField);
            //let header = this.data.headers.find(x => x.field == cellInfo.column.dataField);

            //var dataSourceConfiguration = {
            //    store: typeof _dataSource == "undefined" ? header.dataSource : _dataSource.data
            //};
            //cellInfo.column.lookup.items = dataSourceConfiguration.store;
            // 
            let _item = cellInfo.column.lookup.items.find(x => x[cellInfo.column.lookup.valueExpr] == cellInfo.value);
            return _item ? _item[cellInfo.column.lookup.displayExpr] : "&nbsp;";
        }
    }
    lookupValueChanged(data) {
        console.log("lookupValueChanged");
        console.log(data);
    }

    checkUserAuthorization(header) {
        if (!this.isEdit) {
            let userId = this.appInfo.currentUser.id;
            let rGroupIndex = header.permissions.readonlyGroup.indexOf(userId);
            let rUsersIndex = header.permissions.readonlyUsers.indexOf(userId);
            let hGroupIndex = header.permissions.hideGroup.indexOf(userId);
            let hUsersIndex = header.permissions.hideUsers.indexOf(userId);

            let isHide = hGroupIndex != -1 || hUsersIndex != -1;
            let isReadonly = rGroupIndex != -1 || rUsersIndex != -1;

            if (isHide) header.hide = true;
            else header.hide = false;

            if (isReadonly) header.editable = false;
            else header.editable = true;
        }
        else {
            header.hide = false;
            header.editable = true;
        }

    }

    addEmptyHeader() {
        this.headers.name = this.componentService.createuuid();
        let header = this.componentService.cloneModel(this.headers);
        this.data.headers.push(header);
        //this.resetHeaderObject();
    }

    addHeader() {
        localStorage.setItem("dataGridHeader", JSON.stringify(this.headers));
        let header: any = JSON.parse(localStorage.getItem("dataGridHeader"));
        localStorage.removeItem("dataGridHeader");
        this.data.headers.push(header);
        this.showToast("Eklendi", "success");
        //this.resetHeaderObject();
    }

    deleteAllHeaders() {
        let result = confirm("<i>Emin misiniz?</i>", "Tüm Kolonlar Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.headers = [];
                this.dataGridEtr.instance.option('columns', []);
            }
        });
    }

    deleteHeader(index) {
        let result = confirm("<i>Emin misiniz?</i>", "Kolon Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.data.headers.splice(index, 1);
            }
        });
    }

    //getHideVal(col) {
    //    return col ? col.isHid e : true;
    //}
    /*******Database işlemleri************/
    isFirstOpen = true;
    dataGridSettings(index) {

        this.connections = this.appInfo.RiverEntity.connections;
        this.selectedConnection = this.data.headers[index].dataGridSettings.connectionStringId || 0;
        this.data.headers[index].dataGridSettings.connectionStringId = this.selectedConnection;
        this.selectedHeaderIndex = index;
        this.dataGridSettingsPopop = true;
        // if (!this.isFirstOpen) {
        //this.tablesValueChanged(this.data.headers[this.selectedHeaderIndex].dataGridSettings.table);
        //}
        //else
        //    this.isFirstOpen = false;

        // let f = this.data.headers[this.selectedHeaderIndex].dataGridSettings.formField s;
    }

    connectionListValueChanged(event) {
        this.selectedConnection = event.value;
        //let connectionStr = this.createConnectionString();
        if (event.value > 0) {
            this.data.headers[this.selectedHeaderIndex].dataGridSettings.connectionStringId = event.value;
            this.genericService.getTables(event.value).subscribe((result: any) => {
                this.tableList = result;
            });
        }
    }


    //tablesValueChanged(tableName) {

    //    this.data.headers[this.selectedHeaderIndex].dataGridSettings.query = "SELECT * FROM " + tableName
    //    this.getTableInfo();
    //}

    //getTableInfo() {
    //    let id = this.data.headers[this.selectedHeaderIndex].dataGridSettings.connectionStringId;
    //    let table = this.data.headers[this.selectedHeaderIndex].dataGridSettings.table;
    //    if (id > 0 && table && table != "") {
    //        this.genericService.getTableColumns(table, id).subscribe((result: any) => {
    //            let _selectedDbColumns = this.data.headers[this.selectedHeaderIndex].dataGridSettings.formField s;
    //            if (result && result.length > 0) {

    //                result.forEach(f => {
    //                    let selectedDbColumn = _selectedDbColumns.find(x => x.name == f.name);
    //                    f.isEditable = selectedDbColumn ? selectedDbColumn.isEditable : true;
    //                    if (_selectedDbColumns.length > 0)
    //                        f.isHide = selectedDbColumn ? selectedDbColumn.isHide : true;
    //                    else
    //                        f.isHide = false;
    //                    //selectedDbColumns.find(x=>x.name == f.name).isHide
    //                    /*if(f.isKey) this.data.headers[this.selectedHeaderIndex].valueKey = f.name;*/
    //                });
    //            }
    //            this.data.headers[this.selectedHeaderIndex].dataGridSettings.formField s = result;
    //        });
    //    }
    //}



    //insertToDatabase(event, name) {
    //    let header = this.data.headers.find(f => f.name == name);
    //    let query = this.createQuery(this.dbQueryTypes.insert, header, event.data);
    //    this.executeDbQuery(query, header)
    //}

    //updateFromDatabase(event, name) {
    //    let header = this.data.headers.find(f => f.name == name);
    //    let query = this.createQuery(this.dbQueryTypes.update, header, event.data);
    //    this.executeDbQuery(query, header)
    //}

    rowRemoving(event) {

        let _dataIndex = this.data.value.findIndex(x => x.ID == event.data.ID);//.find(x => x.ID == event.data.ID);

        let datagridHeader = this.data.headers.filter(x => x.type == "file");
        datagridHeader.forEach(_datagridHeader => {
            let dataFiles = _datagridHeader.files;
            let columnId = _datagridHeader.field;
            let _indexValsTemp = this.data.value.find(x => x.ID == event.data.ID);
            if (_indexValsTemp) {
                let _indexVals = _indexValsTemp[columnId].map((x) => x);//this.data.value[_dataIndex][columnId]; 
                _indexVals.forEach(_dataFile => {

                    this.deleteFileConfirm(_dataFile);
                });
            }

        });

        //let currArray = this.selectionGridDataSource.find(f => f.index == _dataIndex);
        let bo = false;
        let _tempArray = [];
        let j = 0;


        for (let i = 0; i < this.selectionGridDataSource.length; i++) {

            if (this.selectionGridDataSource[i].index == _dataIndex) {
                bo = true;
            }
            else {
                _tempArray.push(this.selectionGridDataSource[i]);
                if (bo) {
                    _tempArray[j].index = j;
                }
                j++;
            }


        }

        this.selectionGridDataSource = [];
        this.selectionGridDataSource = _tempArray;
    }

    //deleteFromDatabase(event, name) {

    //    let header = this.data.headers.find(f => f.name == name);
    //    let query = this.createQuery(this.dbQueryTypes.delete, header, event.data);
    //    this.executeDbQuery(query, header)
    //}

    executeDbQuery(query, header) {

        this.genericService.executeQuerySecurited(query, header.dataGridSettings.connectionStringId).subscribe(result => {
            if (result) {
                let sql = header.dataGridSettings.query;
                this.genericService.executeQuerySecurited(sql, header.dataGridSettings.connectionStringId).subscribe(data => {
                    header.dataSource = data;
                    this.showToast("İşlem başarılı", "success");
                });
            }
            else {
                this.showToast("Üzgünüz, işlem sırasında bir hata oluştu", "error");
            }
        });
    }

    createConnectionString() {
        if (this.selectedConnection > 0) {
            let conn = this.connections.find(x => x.id === this.selectedConnection);
            let connectionString =
                "Server=" + conn.serverName
                + ";Database=" + conn.databaseName
                + ";user ID=" + conn.userName
                + ";Password=" + conn.password + ";";
            return connectionString;
        }

    }

    //createQuery(type, header, data) {

    //    let table = header.dataGridSettings.table;
    //    let formFields = header.dataGridSettings.formField s;
    //    let cols = [];


    //    let keyField = formFields.find(f => f.isKey);
    //    let query = "";
    //    if (type === this.dbQueryTypes.insert) {
    //        let insertValues = [];
    //        formFields.forEach(field => {
    //            if (field.name != keyField.name) {
    //                cols.push(field.name);
    //                if (typeof data[field.name] === 'string' || data[field.name] instanceof String) insertValues.push("'" + data[field.name] + "'");
    //                else insertValues.push(data[field.name]);
    //            };
    //        });
    //        query = "INSERT INTO " + table + " (" + cols.join(',') + ") VALUES(" + insertValues.join(',') + ")";
    //    }
    //    else if (type === this.dbQueryTypes.update) {
    //        let updateValues = [];
    //        formFields.forEach(field => {
    //            if (field.name != keyField.name) {
    //                if (typeof data[field.name] === 'string' || data[field.name] instanceof String) {
    //                    let value = "'" + data[field.name] + "'";
    //                    let set = field.name + "=" + value;
    //                    updateValues.push(set);
    //                }
    //                else {
    //                    let set = field.name + "=" + data[field.name];
    //                    updateValues.push(set);
    //                };
    //            };
    //        });
    //        query = "UPDATE " + table + " SET " + updateValues + " WHERE " + keyField.name + "=" + data[keyField.name];
    //    }

    //    else if (type === this.dbQueryTypes.delete) {
    //        query = "DELETE FROM " + table + " WHERE " + keyField.name + "=" + data[keyField.name];
    //    }
    //    return query;
    //}

    /*******End Database işlemleri************/

    addColPermission(header) {
        this.selectedColPermission = header.permissions;
        this.perCol = header.field;
        this.colPermissionPopup = true;
    }

    addColLanguage(header) {
        this.selectedColLanguage = header.language;
        this.colLanguagePopup = true;
    }

    calculateSelectedRow(options) {

        if (options.name == "SelectedRowsSummary") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = options.totalValue + 100;
                    break;
                case "finalize":
                    break;
            }
        }
        /* if (options.name === "SelectedRowsSummary") {
             if (options.summaryProcess === "start") {
                 options.totalValue =  0;
             } else if (options.summaryProcess === "calculate") {
                 if (options.component.isRowSelected(options.value.ID)) {
                     options.totalValue = options.totalValue + 100;//options.value[this.data.summaryCol];
                 }
             }
         }*/
    }
    onGridSelectionChanged(e) {
        //
        //var rowData = e.row.data;
        //var rowIndex = e.row.rowIndex;
        //let fields = this.data.headers.map(function (value) {
        //  return value.field;
        //});

        ////  if (e.row.isEditing && !this.isCalcCellFormula && (_newRow || fields.indexOf(e.dataField) > -1)/*(_newRow || (e.type == "selection" && e.command == "select"))*/) {
        //for (var i =  0; i < fields.length; i++) {
        //  this.cellCalcFormula(fields[i], rowData, rowIndex, fields);
        //  this.isCalcCellFormula = false;
        //}

        //e.row.data.I D = e.row.rowIndex;

        e.component.refresh(true);

        //console.log(this.data);
    }


    applyFilter() {
        if (
            this.data.filter.field
            && this.data.filter.field != ""
            && this.data.filter.users.length > 0
            && this.data.filter.operator != ""
        ) {
            if (this.isUserExistInFilter(this.data.filter.users)) {
                this.dataGridEtr.instance.filter([this.data.filter.field, this.data.filter.operator, this.data.filter.value]);
            }
        }
    }

    isUserExistInFilter(userList) {
        for (var i = 0; i < userList.length; i++) {
            if (userList[i] == this.appInfo.currentUser.id) {
                return true;
            }
        }

        return false;
    }

    resetFilter() {
        this.data.filter.field = "";
        this.data.filter.operator = "";
        this.data.filter.value = 0;
        this.data.filter.users = [];
        this.users.forEach(f => { f.isChecked = false });
    }

    applyUser(isChecked, id) {
        if (isChecked) {
            this.addUser(id);
        }
        else {
            this.removeUser(id);
        }
    }

    addUser(id) {
        let exUser = this.data.filter.users.find(f => f == id);
        if (!exUser) {
            this.data.filter.users.push(id);
        }
    }

    removeUser(id) {
        this.data.filter.users.splice(this.data.filter.users.indexOf(id), 1);
    }

    /* funcParam = {
         "blockoperator": "",
         "params": {
           "param1": "",
           "param2": "",
           "operator": ""
           }
       }
   
       funcParamData = {
         "export": false,
         "element": "",
         "formula": []
       }*/
    fieldTypesValueChanged = (e: any, header: any): void => {
        if (e.value.length) {
            if (e.value == "datetime")
                header.format = "dd/MM/yyyy HH:mm";
            else if (e.value == "date")
                header.format = "dd/MM/yyyy";
            else if (e.value == "double" || e.value == "int")
                header.format = "#,##0.00";
        }
    }
    openFormatPopup(i) {

        let fff = this.data.headers;
        this.selectedFormatIndex = i;
        let _header = this.data.headers[i];
        if (_header.type == "date" || _header.type == "datetime") {
            let defaultFormat = _header.type == "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm";
            let _format = _header.format && _header.format != "" && _header.format != "#,##0.00" ? _header.format : defaultFormat;
            this.data.headers[i].format = _format;
        }
        else {
            this.data.headers[i].format = "#,##0.00";
        }
        this.showFormatPopup = true;
    }

    closeFormatPopup() {
        this.selectedFormatIndex = -1;
        this.showFormatPopup = false;
    }

    monacoOnInit(e) {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    outOfGridComponent: any;
    outOfGridComponentDatas: any;
    setCustomKeywords(monaco) {

        if (this.registeredLanguage)
            this.registeredLanguage.dispose();



        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];

                for (let i = 0; i < this.data.headers.length; i++) {
                    suggestions.push({
                        label: this.data.headers[i].field,
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "{" + this.data.headers[i].field + "}",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }

                for (let i = 0; i < this.outOfGridComponent.length; i++) {
                    suggestions.push({
                        label: this.outOfGridComponent[i].id,
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "{" + this.outOfGridComponent[i].id + "}",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }

                return { suggestions: suggestions };
            }
        });
    }


    openFuncPopup(i) {

        this.isMonacoLoaded = false;
        this.selectedFuncIndex = i;
        let exp = this.data.headers[i].expression;
        this.headerExpression.code = exp.code;
        this.headerExpression.export = exp.export;
        this.headerExpression.element = exp.element;
        this.showFuncPopup = true;
        setTimeout(() => { this.isMonacoLoaded = true; }, 1000);
    }

    saveFuncParam() {
        console.log(this.headerExpression);
        let exp: any = {};
        exp.code = this.headerExpression.code;
        exp.export = this.headerExpression.export;
        exp.element = this.headerExpression.element;
        this.data.headers[this.selectedFuncIndex].expression = exp;
        this.selectedFuncIndex = -1;
        this.headerExpression = new HeaderExpression();
        this.showFuncPopup = false;
    }

    exportChange(val) {

        if (val) {
            this.exportElements = this.componentService.getComponentsByComponentName("DxFormResultComponent").map(m => m.id);
        }
        else {
            this.funcParamData.element = "";
            this.funcParamData.export = false;
            this.exportElements = [];
        }
    }


    dropdownboxOnChange(e) {
        console.log(e);
    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    onCellClick(_event) {
        // _event.event.stopPropagation();
        //var dataField = _event.column.dataField;
        //var rowData = _event.row.data;
        //var rowIndex = _event.rowIndex;
        //let fields = this.data.headers.map(function (value) {
        //  return value.field;
        //});
        //this.cellCalcFormula(dataField, rowData, rowIndex, fields);
        //var dataField = _event.column.dataField;

        //if (this.data.headers.find(x => x.field === dataField)) {
        //  
        //  var expression = this.data.headers.find(x => x.field === dataField).expression;
        //  if (expression && typeof (expression.code) != "undefined" && expression.code != '') {
        //    let fields = this.data.headers.map(function (value) {
        //      return value.field;
        //    });

        //    var func = expression.code;
        //    fields.forEach(f => {
        //      let pref = "{" + f + "}";
        //      let value = _event.row.data[f];
        //      func = this.replaceAll(func, pref, value) 
        //    });

        //    let result = eval(func) ; 
        //    
        //    _event.data[dataField] = result;
        //    _event.values[_event.columnIndex] = result; 
        //    this.dataGridEtr.instance.cellValue(_event.rowIndex, dataField, result);

        //    if (expression.expor t === true && typeof (expression.element) != "undefined") {
        //      let sum = this.componentService.setColSumToElement(expression.element, dataField, this.data.valu e, this.componentService.currentForm.components);
        //      this.eventEmitterService.setColumnSumToComponent(expression.element, sum);
        //    }

        //  } 
        //}
    }
    //cellCalcFormula(dataField, rowData, rowIndex, fields) {
    // "{toplam2}=3600+{yuzde}" 
    //  if (this.data.headers.find(x => x.field === dataField)) {

    //    var expression = this.data.headers.find(x => x.field === dataField).expression;
    //    if (expression && typeof (expression.code) != "undefined" && expression.code != '') {

    //      var dataFieldObject = this.data.headers.find(x => x.field === dataField);

    //      var func = expression.code;
    //      fields.forEach(f => {
    //        // var fff = dataFieldObject;
    //        let pref = "{" + f + "}";
    //        let value = rowData[f];
    //        if (typeof value !== "undefined")
    //          func = this.replaceAll(func, pref, value)
    //      });

    //      try {

    //        let result = eval(func) ;
    //        if (isNaN(result)) {
    //          if (dataFieldObject.type == "numbe r") {
    //            result =  0;
    //          }
    //        }
    //        this.isCalcCellFormula = true;
    //        this.dataGridEtr.instance.cellValue(rowIndex, dataField, result);

    //      } catch (e) {
    //        if (e instanceof SyntaxError) {

    //        }
    //      }

    //      if (expression.expor t === true && typeof (expression.element) != "undefined") {
    //        let sum = this.componentService.setColSumToElement(expression.element, dataField, this.data.valu e, this.componentService.currentForm.components);
    //        this.eventEmitterService.setColumnSumToComponent(expression.element, sum);
    //      }

    //    }
    //  }
    //}
    executeExp(event, process_type) {
        try {

            var elemVal_AllHeader = this.data.headers
            for (var j = 0; j < elemVal_AllHeader.length; j++) {
                if (elemVal_AllHeader[j] && elemVal_AllHeader[j].type && (elemVal_AllHeader[j].type == "number" || elemVal_AllHeader[j].type == "int" || elemVal_AllHeader[j].type == "double")) {
                    if (!event.data[elemVal_AllHeader[j].field] || event.data[elemVal_AllHeader[j].field] == "")
                        event.data[elemVal_AllHeader[j].field] = 0;
                }
            }

            if (process_type == this.dbQueryTypes.delete) {

                // let currArray = this.selectionGridDataSource.filter(f => f.index == e.rowIndex);
            }


            this.elementOutOfDatagrid();

            this.allowCopy = true;
            let elements = this.elementHtml.nativeElement.querySelectorAll('.dx-datagrid-addrow-button');
            elements[0].classList.remove("dx-state-disabled");


            this.outOfGridComponentDatas = this.componentService.formComponentList;

            for (let i = 0; i < this.data.headers.length; i++) {
                let exp = this.data.headers[i].expression;

                if (exp && typeof (exp.code) != "undefined" && exp.code != '') {

                    let func = this.componentService.cloneModel(exp.code);
                    let fields = Object.keys(event.data);
                    fields.forEach(f => {
                        let pref = "{" + f + "}";
                        let value = event.data[f];
                        func = this.replaceAll(func, pref, value)
                    });

                    this.outOfGridComponent.forEach(f => {
                        let pref = "{" + f.id + "}";
                        let value = this.outOfGridComponentDatas.find(x => x.id == f.id).value;//f.value;//event.data[f];
                        func = this.replaceAll(func, pref, value)
                    });

                    var pattern = /\{[a-zA-Z0-9_,'=ıİöÖçÇğĞ()üÜşŞ%]+\}/ig;
                    var patt = new RegExp(pattern);

                    if (patt.test(func)) {
                        //   
                        var res_pattern = func.match(pattern);
                        for (var j = 0; j < res_pattern.length; j++) {
                            var elem = res_pattern[j];
                            var elemVal = this.data.headers.find(x => x.field === elem.replace("{", "").replace("}", ""));
                            if (!elemVal) {
                                elemVal = this.outOfGridComponent.find(x => x.id == elem.replace("{", "").replace("}", ""));
                            }

                            if (elemVal && elemVal.type && (elemVal.type == "number" || elemVal.type == "int" || elemVal.type == "double")) {

                                func = this.replaceAll(func, elem, "0");
                                event.data[elem.replace("{", "").replace("}", "")] = 0;
                            }
                            else if (elemVal && elemVal.type && elemVal.type == "text") {
                                func = this.replaceAll(func, elem, "");
                                event.data[elem.replace("{", "").replace("}", "")] = "";
                            }
                            //else if (!elemVal || !elemVal.type) {
                            //    
                            //  ;//.find(x => x.id == f.id);
                            //    if (elementOut.type=="number") {
                            //        func = func.repl ace(elem, "0");
                            //        event.data[elem.replace("{", "").replace("}", "")] =  0;
                            //    }
                            //}

                        }
                    }


                    let result = eval(func.replace(/[\u200B-\u200D\uFEFF]/g, ''));
                    console.log("result: " + result);
                    event.data[this.data.headers[i].field] = result;

                }
                if (exp.export === true && typeof (exp.element) != "undefined") {

                    let sum = this.componentService.setColSumToElement(exp.element, this.data.headers[i].field, this.data.value, this.componentService.currentForm.components);
                    this.eventEmitterService.setColumnSumToComponent(exp.element, sum);
                }
            }

        } catch (_ex_) {
            console.error(_ex_.message);
            console.error(_ex_.stack);
        }

    }
    gridInGridColumnStyle(cell) {
        let _style = {};
        try {
            var elemVal_AllHeader = this.data.headers
            //   for (var j = 0; j < elemVal_AllHeader.length; j++) { event.data[elemVal_AllHeader[j].field] = this.getDataGridSelectValu e(elemVal_AllHeader[j].field);
            let header = this.data.headers.find(f => f.field == cell.column.dataField);


            _style = {
                'display': (header.editable ? "none" : "block"),
                'position': "absolute",
                'background': "#f1f1f194",
                'top': "0",
                'left': "0",
                'width': "100%",
                'height': "100%",
                'z-index': "100"
            };
        } catch (e) {
        }
        return _style;
        //let f = this.getDatagridSourceNew(cell);
        //return f.store.length > 0 ? f[cell.value] : "";
    }
    elementColumnDisabled(cell) {
        try {
            let header;
            if (typeof cell.column.dataField != "undefined") {
                header = this.data.headers.find(f => f.field == cell.column.dataField);
            }
            else {
                header = this.data.headers.find(f => f.field == cell.column.name);
            }
            let v1 = true;//cell.isEditing;
            let v2 = header.editable;
            let v3 = v1 && v2;
            let v4 = !(v3);
            return v4;
        }
        catch (e) {
        }
        return false;
    }
    fileUploaderDisabled(row) {
        return !row.isEditing || (typeof row == 'undefined' || typeof row.data == 'undefined' || typeof row.data.ID == 'undefined' || row.data.ID == 0);
    }
    onValueChanged = (e: any, row): void => {
        console.log(row);
        //row.column.name
        if (e.value.length) {
            let header = this.data.headers.find(f => f.name == row.column.name);
            e.value.forEach(f => {
                this.getDocument(f).then(document => {

                    let doc: any = {};
                    doc.row = row.data.ID;
                    doc.file = document;
                    header.files.push(doc);

                });
            });
        }
    }

    openSqlExpressioPopup() {
        this.connections = this.appInfo.RiverEntity.connections;
        this.showSqlExpressioPopup = true;
    }

    addSqlExpression() {
        let evt = this.data.sqlExpressions.find(f => f.field == this.sqlExpression.field);
        if (!evt) {
            this.data.sqlExpressions.push(this.componentService.cloneModel(this.sqlExpression));
        }
    }

    deleteSqlExpressions(i) {
        this.data.sqlExpressions.splice(i, 1);
    }

    closeSqlExpressioPopup() {
        this.showSqlExpressioPopup = false;
        this.componentService.elementsOutOfDatagrid = [];

        let componentList = this.componentService.getComponentList(this.componentService.currentForm.components);

        for (let i = 0; i < this.data.sqlExpressions.length; i++) {
            let sqlExpressionTag = this.data.sqlExpressions[i].query.match(/\{(\w)+\}/ig);
            if (sqlExpressionTag != null && typeof sqlExpressionTag != "undefined" && sqlExpressionTag.length > 0) {
                sqlExpressionTag[0] = sqlExpressionTag[0].replace("{", "").replace("}", "");
                let isActive = componentList.filter(x => x.id == sqlExpressionTag[0]);
                if (isActive.length > 0)
                    this.componentService.elementsOutOfDatagrid.push(sqlExpressionTag[0]);
            }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: this.componentService.isImage(fileExtension)
                    };
                    resolve(document);

                }
            }

        });
    }

    datagridHeaderFiles: any = []
    //datagridValueFiles: any = []
    showFile(row) {

        this.datagridHeaderFiles = []
        //this.datagridValueFiles = []
        let header = this.data.headers.find(f => f.name == row.column.name);
        let docs = header.files.filter(f => f.row == row.data.ID);
        if (docs.length > 0) {
            docs.forEach(f => {
                f.file.src = 'data:' + f.file.fileType + ';base64,' + f.file.base64BlobData;
                this.datagridHeaderFiles.push(f.file);
            });
        }

        this.showPreviewFilesPopup = true;
    }

    deleteFiles() {
        let result = confirm("<i>Dosyaları silmek istediğinize emin misiniz?</i>", "Dosyalar Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];

                let datagridHeader = this.data.headers.filter(x => x.type == "file");
                datagridHeader.forEach(_datagridHeader => {
                    _datagridHeader.files = [];
                    let dataFiles = _datagridHeader.files;
                    let columnId = _datagridHeader.field;
                    dataFiles.forEach(_dataFile => {
                        /*[parseInt()]*/
                        this.data.value.find(x => x.ID == _dataFile.row)[columnId] = [];
                    });
                });
                this.datagridHeaderFiles = [];
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    deleteFile(_id) {
        let result = confirm("<i>Dosya silinsin mi?</i>", "Dosya Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.deleteFileConfirm(_id);
            }
        });

    }

    deleteFileConfirm(_id) {

        let datagridHeader = this.data.headers.filter(x => x.type == "file");
        datagridHeader.forEach(_datagridHeader => {

            let dataFiles = _datagridHeader.files;
            let columnId = _datagridHeader.field;
            dataFiles.forEach(_dataFile => {
                /*[parseInt(_dataFile.row)]*/
                let _indexTemp = this.data.value.find(x => x.ID == parseInt(_dataFile.row));
                if (_indexTemp) {
                    let _indexVal = _indexTemp[columnId].findIndex(x => x == _id);
                    if (_indexVal != -1) {
                        /*[parseInt(_dataFile.row)]*/
                        _indexTemp[columnId].splice(_indexVal, 1);
                    }
                }

            });

            let _index = _datagridHeader.files.findIndex(x => x.file.id == _id);
            if (_index != -1)
                _datagridHeader.files.splice(_index, 1);

            this.eventEmitterService.setDeletedFileIds(_id);
        });

        let _indexShowFiles = this.datagridHeaderFiles.findIndex(x => x.id == _id);
        if (_indexShowFiles != -1)
            this.datagridHeaderFiles.splice(_indexShowFiles, 1);

        this.changeDetectorRef.detectChanges();
    }

    previewFile(src) {
        //this.previewFileSrc = src;
        //this.showPreviewFilesPopup = true;
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }
}
