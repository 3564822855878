import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { CdkDragDrop, CdkDragStart, CdkDragEnd, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import { ComponentService } from '../component.service';
import { EventEmitterService, AppInfoService } from '../../shared/services';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class DxTabsComponent implements ComponentParameters {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;

    showDisplay: any = "block";

    onGridDragClass: string = "active";
    actions = [
        {
            icon: "edit",
            alignment: "center",
            hint: "Center",
            action: "edit"
        },
        {
            icon: "trash",
            alignment: "right",
            hint: "Delete",
            action: "delete"
        },
    ];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    panelPopup: boolean = false;
    panelPermissionPopup: boolean = false;
    selectedPanelPermission: any;

    constructor(private componentService: ComponentService, private eventEmitterService: EventEmitterService, public appInfo: AppInfoService, public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
    }
    //
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        if (!this.isEdit) this.checkUserAuthorization();

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
                 
                this.changeDetectorRef.detectChanges();
            }
        });

    }


    tabSelectionChanged(event) {
        
        this.eventEmitterService.menuTabComponentChange(null);
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (!this.data.hide && this.data.outsideVisible ? "block" : "none") : "block";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }


    checkUserAuthorization() {
        for (let i = 0; i < this.data.items.length; i++) {
            let userId = this.appInfo.currentUser.id;
            let rGroupIndex = this.data.items[i].permissions.readonlyGroup.indexOf(userId);
            let rUsersIndex = this.data.items[i].permissions.readonlyUsers.indexOf(userId);
            let hGroupIndex = this.data.items[i].permissions.hideGroup.indexOf(userId);
            let hUsersIndex = this.data.items[i].permissions.hideUsers.indexOf(userId);

            let isHide = hGroupIndex != -1 || hUsersIndex != -1;
            let isReadonly = rGroupIndex != -1 || rUsersIndex != -1;

            if (isHide) this.data.items[i].hide = true;
            else this.data.items[i].hide = false;
            if (isReadonly) this.data.items[i].readonly = true;
            else this.data.items[i].readonly = false;
        }
    }

    itemClick(e, item, list) {
        if (e.itemData.action == "edit") {
            this.eventEmitterService.showPopop(item.uuid);
            //item.showEditPop = true;
        }
        else if (e.itemData.action == "delete") {
            let msg = item.type == "grid" || item.type == "panel" ? "<i>Bu component içerisindeki elemanlar silinecektir?</i>" : "<i>Emin misiniz?</i>"
            let result = confirm(msg, "Uyarı!");
            result.then((dialogResult) => {
                if (dialogResult) this.removeField(item, list);
            });
        }
    }


    dragStart() {
        this.onGridDragClass = "active";
    }


    onDragEnded(event: CdkDragEnd): void {

        //event.source._dragRef.reset();
    }
    cdkGridDrop(event: CdkDragDrop<string[]>, list?: any[]) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            let type = event.previousContainer.element.nativeElement.dataset.type;
            if (type && type == "copy") {
                let field: any = event.previousContainer.data[event.previousIndex];
                if (field.type != "tabs") {
                    localStorage.setItem("currentFieldTabs", JSON.stringify(event.previousContainer.data[event.previousIndex]));
                    let currentFieldTab: any = JSON.parse(localStorage.getItem("currentFieldTabs"));
                    localStorage.removeItem("currentFieldTabs");
                    //localStorage.removeItem(key);localStorage.clear();
                    currentFieldTab.id = this.componentService.createId(currentFieldTab.type, this.componentService.currentForm.components);
                    list.splice(event.currentIndex, 0, currentFieldTab);
                }
                else {
                    this.componentService.showAlert("Geçersiz hedef", "warning", 300);
                }

                /*copyArrayItem(event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.currentIndex);*/
            }
            else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.onGridDragClass = "";
    }

    removeField(item, list) {
        list.splice(list.indexOf(item), 1);
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        this.componentService.showAlert("Silindi", "success", 300);
    }


    addPanel() {
        var panel = {
            title: "Başlık...",
            permissions: {
                "readonlyGroup": [],
                "readonlyUsers": [],
                "hideGroup": [],
                "hideUsers": []
            },
            components: []
        }
        this.data.items.push(panel);
        this.componentService.createCdkList();
    }


    deletePanel(index) {
        this.data.items.splice(index, 1);
    }

    addPermission(panel) {
        this.selectedPanelPermission = panel.permissions;
        this.panelPermissionPopup = true;
    }

}
