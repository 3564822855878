import { Component, OnInit, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';

import notify from "devextreme/ui/notify";

@Component({
    selector: 'languagegrid-component',
    templateUrl: './languagegrid.component.html'
})
export class DxLanguageGridComponent implements OnInit {
    @Input() dataSource: any;

    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enUS",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
        {
            "code": "esES",
            "name": "Español",
        },
        {
            "code": "frFR",
            "name": "Français",
        },
        {
            "code": "bgBG",
            "name": "български",
        }
    ]

    gridCols: any = [];

    constructor() {

    }

    ngOnInit() {
        this.gridCols = Object.keys(this.dataSource[0]);
        const index = this.gridCols.findIndex(item => item === "Code");
        this.gridCols.splice(index, 1);




        //const indexNameLabel = this.gridCols.findIndex(item => item === "NameLabel");

        ////NameLabel 
        ////CardNoLabel 
        ////ExpiresLabel
        ////MonthLabel 
        ////YearLabel 
        ////CvvCvcLabel 



    }

}
