import { Component, OnInit, Input } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from "../component.service";
import { EventEmitterService } from '../../shared/services';
//declare var myfunction: any;
import notify from "devextreme/ui/notify";
@Component({
  selector: 'codeditor-component',
  templateUrl: './codeditor.component.html',
  styleUrls: ['./codeditor.component.css']
})
export class DxCodeditorComponent implements OnInit {
  @Input() form: any;
  @Input() item: any;
  @Input() isEdit: any;

  loadingPopup:boolean=true;
  isConfigured: boolean = false;
  editorOptions = {theme: 'vs-dark', language: 'typescript'};
  inputComponents :any = [];
  themes = [{
    "name": "Dark",
    "code": "vs-dark",
  }, {
      "name": "White",
      "code": "vs",
  }, {
    "name": "Hight Black",
    "code": "hc-black",
  }];
  languages = [{
    "name": "Javascript",
    "code": "javascript",
  }, {
    "name": "Typescript",
    "code": "typescript",
  }, {
    "name": "SQL",
    "code": "sql",
  }];
 
  constructor(private componentService: ComponentService,  private eventEmitterService: EventEmitterService) { }

  ngOnInit() {
       this.loadingPopup=true;
  }

  onInit(editor) {
      this.loadingPopup=false;
      if (!this.componentService.isCreateditorDict) {
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
        this.componentService.isCreateditorDict = true;
      }
    
    //if(!this.isEdit && this.form.expression && this.form.expression.length > 0) this.execute();
  }

  execute() {
    eval(this.form.expression);
  }

  setCustomKeywords(monaco) {
      monaco.languages.registerCompletionItemProvider('typescript', {
        provideCompletionItems: () => {
         var suggestions = this.createDependencyProposals(monaco) || [];
          let componentslist = this.componentService.getComponentList(this.form.components);
          for (let i = 0; i < componentslist.length; i++) {
            suggestions.push({
              label: componentslist[i].id,
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: "var " +componentslist[i].id +' = ' +'this.getById("' +   componentslist[i].id +'");',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'method'
            })
          }
          return { suggestions: suggestions };
        }
      });
    }

  createDependencyProposals(monaco) {
      return [{
            label: 'form',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'form',
          },{
            label: 'components',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'components[]',
          },{
            label: 'value',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'value',
          },{
            label: 'data',
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: 'data',
          }, {
            label: 'ifelse',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: [
              'if () {',
              '\t$0',
              '} else {',
              '\t',
              '}'
            ].join('\n'),
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'If-Else Statement'
          }, {
            label: 'forEach',
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: [
              'this.form.components.forEach(component => {',
              '\t console.log(component);',
              '});'
            ].join('\n'),
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'forEach Statement'
          },
            {
              label: 'getById',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'this.getById("id")',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'method'
            },
            {
              label: 'colSum',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'this.colSum("Grid Id", kolon_index)',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'method'
            },
            {
              label: 'setValue',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'this.setValue("selectboxId","elementId","key")',
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'method'
            }
          ];
    }

  getById(id) {
    return  this.componentService.getById(id, this.form.components);
  }
  setValue(selectboxId, elementId, key) {
    this.componentService.setValue(selectboxId, elementId, key, this.form.components);
  }

  colSum(id, colm) {
       return this.componentService.colSum(id, colm, this.form.components);
      /*if (this.isNumeric(colm)) {
        let result = this.componentService.colSum(id, colm, this.form.components);
        if (this.isNumeric(result)) return result;
        else {
           this.showToast("Hatalı kolon indexi girildi!","warning");
           return 0;
        }
      
      }
      else {
        this.showToast("Hatalı kolon indexi girildi!","warning");
        return 0;
      }*/
    }

  isNumeric(val: any): boolean {
     return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
  }

    showToast(message, status) {
    notify(message, status, 3000);
  }
}
