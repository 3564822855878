import { Component, OnInit, ViewChild, ElementRef,Input } from '@angular/core';

@Component({
    selector: 'videoviewer-component',
    templateUrl: './videoviewer.component.html',
    styleUrls: ['./videoviewer.component.css']
})
export class DxVideoviewerComponent implements OnInit {
    @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
    @Input() data: any;
    videoSrc: any;
    fileName: string = "";
    constructor() { }

    ngOnInit() {
    }

    onValueChanged = (e: any): void => {
        if (e.value.length) {
            var reader = new FileReader();
            reader.readAsDataURL(e.value[0]);
            reader.onload = (args: any) => {
                if (reader.result) {
                    this.videoSrc = args.target.result;
                    //this.loadVideo(args.target.result)
                    //console.log(args.target.result);
                    //let questionCode = dataField;
                    let fileName: string = e.value[0].name;
                    this.fileName = fileName;
                    let fileType: string = e.value[0].type;
                    let base64BlobData: any = "";//reader.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension
                    };
                }
            }
        }
    }

    loadVideo(data: string) {
        this.videoPlayer.nativeElement.src = data;
        this.videoPlayer.nativeElement.load();
        //this.videoPlayer.nativeElement.play();
    }
}
