import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { EventEmitterService } from '../../shared/services';
import { Subscription, Observable } from 'rxjs';

import { MouseEvent, MapsAPILoader } from '@agm/core';

import { marker } from '../../shared/models/map-marker';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

declare var google;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css']
})
export class DxMapComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    markerOptionPopup: boolean = false;

    showDisplay: any = "flex";

    //uploadResult = { isShow: false, status: "success", message: "" };
    //totalSize: any = 0;

    invokeChangeEventLocal: Subscription;

    constructor(private eventEmitterService: EventEmitterService, public changeDetectorRef: ChangeDetectorRef, private componentService: ComponentService,
        private mapsAPILoader: MapsAPILoader, private _sanitizationService: DomSanitizer) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        
        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (sub.elementId == this.data.id) {
                let thisPageMapTimeout = this;
                setTimeout(function () {
                    if (sub.data && sub.data != "") thisPageMapTimeout.data.markers = sub.data.markers;
                    else thisPageMapTimeout.data.markers = [];

                    thisPageMapTimeout.changeDetectorRef.detectChanges();
                }, 100);
            }
        });

        let thisPageMap = this;
        setTimeout(function () {
            thisPageMap.mapsAPILoader.load().then(() => {
                thisPageMap.autocompleteService = new google.maps.places.AutocompleteService();
            });
        }, 1500);

        this.setClassMarker();
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    getImageImgTag(uFs) {

        try {
            return this._sanitizationService.bypassSecurityTrustUrl(uFs);
        } catch (ex) { }
    }

    getImageImgTagSelected() {
        try {
            return this._sanitizationService.bypassSecurityTrustUrl(this.mapMarkerSelectedImage);
        } catch (ex) { }
    }

    setClassMarker() {
        try {

            document.querySelector('div[style*="color: rgb(0, 0, 0); font-size: 14px; font-family: Roboto, Arial, sans-serif;"]').className = "deneme";
        } catch (ex) { }
    }

    markerStyleGlobal = {
        text: "some text",
        background: "white"
    };
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";

        this.data.width = this.data.width > 0 ? this.data.width : 500;
        this.data.height = this.data.height > 0 ? this.data.height : 300;

        // this.data.imgalt = this.defaultLanguage


        //let ff = this.data.markers;
        this.data.disabled = typeof this.data.disabled == "undefined" ? false : this.data.disabled;


        this.setCurrentLocation();

        this.setClassMarker();
    }

    mapMarkerSelectedImage = "";
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            let _thisPage = this;
            navigator.geolocation.getCurrentPosition((position) => {
                _thisPage.lat = position.coords.latitude;
                _thisPage.lng = position.coords.longitude;
                _thisPage.zoom = 15;
                try {
                    _thisPage.changeDetectorRef.detectChanges();
                } catch (ex) { }
            });
        }
    }


    //async getGeoLocation() {

    //    if (window.navigator && window.navigator.geolocation) {
    //        await window.navigator.geolocation.getCurrentPosition(
    //            position => {
    //                this.lat = position.coords.latitude;
    //                this.lng = position.coords.longitude;
    //                this.zoom = 15;

    //                try {
    //                    this.changeDetectorRef.detectChanges();
    //                } catch (ex) { }

    //            },
    //            error => {
    //                switch (error.code) {
    //                    case 1:
    //                        // this.locationGlobal = null;
    //                        break;
    //                    case 2:
    //                        //  this.locationGlobal = null;
    //                        break;
    //                    case 3:
    //                        //  this.locationGlobal = null;
    //                        break;
    //                }
    //            }
    //        );
    //    };
    //}

    searchTextGlobal = "";
    getCoorninateAddress(sRs) {

        this.showSearchResulPanel = "none";
        this.searchTextGlobal = sRs.description;
        this.getLatLong(sRs.place_id);
        // ;
    }

    searchResults = [];
    public autocompleteService: any;
    getPlacePredictions(term: string): Observable<Object[]> {
        //: GoogleResult[]
        this.showSearchResulPanel = "block";
        return this.autocompleteService.getPlacePredictions({ input: term }, (data) => {

            if (data) {
                this.searchResults = data;
                //console.log(data);
                //this.data.next(data);
            }
        });
    }
    map;
    public mapReady(map) {

        this.map = map;
    }
    getLatLong(placeid: string) {
        let placeService = new google.maps.places.PlacesService(this.map);
        let _thisPage = this;
        placeService.getDetails({
            placeId: placeid
        }, function (result, status) {
            _thisPage.lat = result.geometry.location.lat();
            _thisPage.lng = result.geometry.location.lng();
            try {
                _thisPage.changeDetectorRef.detectChanges();
            } catch (ex) { }
            //console.log(result.geometry.location.lat());
            //console.log(result.geometry.location.lng())
        });
    }

    //getPlacePredictions(term: string): Observable<any[]> {
    //    return Observable.create(observer => {
    //        // API Call

    //        this.autocompleteService.getPlacePredictions({ input: term }, data => {
    //            let previousData: Array<any[]>;

    //            // Data validation

    //            if (data) {
    //                console.log(data);
    //                previousData = data;
    //                observer.next(data);
    //                observer.complete();
    //            }

    //            // If no data, emit previous data

    //            if (!data) {
    //                console.log("PreviousData: ");
    //                observer.next(previousData);
    //                observer.complete();

    //                // Error Handling

    //            } else {
    //                observer.error(status);
    //            }

    //        });

    //    });

    //}

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    openPermissionPopup() {
        this.permissionPopup = true;
    }



    mapDivStyleFunc() {

        var width = this.data.width > 0 ? this.data.width + 'px' : '500px';
        var height = this.data.height > 0 ? this.data.height + 'px' : '300px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay, 'position': 'relative' };
        return styles;
    }

    showSearchResulPanel = "none";
    mapSearchStyleFunc() {
        const _styles = { 'background': 'rgba(255, 255, 255, .8)', 'padding': '5px', 'width': '100%', 'display': this.showSearchResulPanel, 'color': '#494949' };
        return _styles;
    }

    zoom: number = 8;

    // initial center position for the map
    lat: number = 39.925533;
    lng: number = 32.866287;

    mapMarkerLocal = {
        id: 0,
        uploadedFiles: []
    }
    clickedMarker(label: string, index: number, id: number) {
        this.mapMarkerSelectedImage = "";
        if (!this.data.disabled) {
            this.mapMarkerLocal = this.data.markers.find(x => x.id == id);
            if (this.mapMarkerLocal.uploadedFiles.length > 0)
                this.mapMarkerSelectedImage = this.mapMarkerLocal.uploadedFiles[0];
        }

        this.setClassMarker();
    }

    clickMarkerAddInfo(markerLocal: any, index: number, id: number) {
        if (!this.data.disabled) {
            this.markerOptionPopup = true;
        }
        this.setClassMarker();
    }

    markerDragEnd(m: marker, $event: MouseEvent) {
        if (!this.data.disabled) {
            let _id = m.id;
            let indexDataMarker = this.data.markers.findIndex(x => x.id == _id);
            m.lat = $event.coords.lat;
            m.lng = $event.coords.lng;
            this.data.markers[indexDataMarker] = m;
        }
        this.setClassMarker();
    }

    clickMarkerDelete(markerLocal: any, index: number, id: number) {
        if (!this.data.disabled) {
            let _id = this.mapMarkerLocal.id;
            let indexDataMarker = this.data.markers.findIndex(x => x.id == _id);
            this.data.markers.splice(indexDataMarker, 1);
        }
        this.setClassMarker();
    }

    mapClicked($event: MouseEvent) {
        //data.isAddMultipleMarker   data.maxMarkerLimit

        if (typeof this.data.markers == "undefined")
            this.data.markers = [];

        let ismarkerMuti = (this.data.isAddMultipleMarker && (this.data.maxMarkerLimit > this.data.markers.length || this.data.maxMarkerLimit == 0)) ||
            (!this.data.isAddMultipleMarker && this.data.markers.length == 0);

        if (!this.data.disabled && ismarkerMuti) {
            let _id = this.data.markers.length > 0 ? this.data.markers[this.data.markers.length - 1].id + 1 : 1;
            this.data.markers.push({
                lat: $event.coords.lat,
                lng: $event.coords.lng,
                label: "",
                uploadedFiles: [],
                id: _id,
                draggable: this.data.draggable
            });
        }
        this.setClassMarker();
    }

    cancelMarkerOption() {
        this.markerOptionPopup = false;
        let _id = this.mapMarkerLocal.id;
        this.mapMarkerLocal = this.data.markers.find(x => x.id == _id);
        this.setClassMarker();
    }

    saveMarkerOption() {
        this.markerOptionPopup = false;
        let _id = this.mapMarkerLocal.id;
        let indexDataMarker = this.data.markers.findIndex(x => x.id == _id);
        this.data.markers[indexDataMarker] = this.mapMarkerLocal;
        this.setClassMarker();
    }

    totalSize = 0;
    onValueChanged = (e: any): void => {

        let errors = [];
        if (e.value.length) {

            this.totalSize = 0;
            for (let i = 0; i < e.value.length; i++) {
                let checkSum = this.totalSize + e.value[i].size;
                if (checkSum > (this.data.maxFileSize * 1000000)) {
                    let msg = e.value[i].name + " yüklenemedi."
                    errors.push(msg);
                    continue;
                }

                this.getDocument(e.value[i]).then(document => {
                    this.mapMarkerLocal.uploadedFiles.push(document);
                    this.totalSize += e.value[i].size;
                    if (i === (e.value.length - 1)) {
                        if (errors.length) {
                            //this.uploadResult.status = "danger";
                            //this.uploadResult.message = "Dosya boyutu aşıldı: " + errors.join(", ");
                        }
                        else {
                            //this.uploadResult.status = "success";
                            //this.uploadResult.message = "Dosyalar yüklendi";
                        }
                    }
                });

            }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {

                resolve(args.target.result);

                //if (reader.result) {
                //    let fileName = file.name;
                //    let fileType: string = file.type;
                //    let base64BlobData: any = args.target.result.split(',').pop();
                //    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                //    let fileSize = file.size;
                //    let document: any = {
                //        base64BlobData: base64BlobData,
                //        name: fileName,
                //        fileType: fileType,
                //        extension: fileExtension,
                //        size: fileSize,
                //        isImg: true
                //    };
                //    resolve(document);

                //}
            }

        });
    }

    //markers: marker[] = [
    //    //{
    //    //    lat: 51.673858,
    //    //    lng: 7.815982,
    //    //    label: 'A',
    //    //    draggable: true
    //    //},
    //    //{
    //    //    lat: 51.373858,
    //    //    lng: 7.215982,
    //    //    label: 'B',
    //    //    draggable: false
    //    //},
    //    //{
    //    //    lat: 51.723858,
    //    //    lng: 7.895982,
    //    //    label: 'C',
    //    //    draggable: true
    //    //}
    //]



}

export interface GoogleResult {
    description: string;
    id: string;
    matched_substrings: Matchedsubstring[];
    place_id: string;
    reference: string;
    structured_formatting: Structuredformatting;
    terms: Term[];
    types: string[];
}

interface Term {
    offset: number;
    value: string;
}

interface Structuredformatting {
    main_text: string;
    main_text_matched_substrings: Matchedsubstring[];
    secondary_text: string;
}

interface Matchedsubstring {
    length: number;
    offset: number;
}

