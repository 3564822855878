import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { AppInfoService, GenericService } from '../../shared/services';
import { debug } from 'console';

export class SqlScriptTextboxModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}

@Component({
    selector: 'textbox-component',
    templateUrl: 'textbox.component.html',
    styleUrls: ['./textbox.component.css']
})
export class DxTextboxComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    systemInfoPage: any;
    systemValTemp: any = "";
    systemValTempActive: any = false;

    designerDataSourceList: any;
    designerDatasourceModel: any;
    getTextboxStyles: any;
    invokeChangeEventLocal: Subscription;
    invokeSaveSystemInfoEventLocal: Subscription;

    constructor(public appInfo: AppInfoService, private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        private genericService: GenericService,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });


        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {


            if (sub.elementId == this.data.id) {

                if (sub.data) this.data.value = sub.data;
                else this.data.value = "";
                //setTimeout(() => {
                //    try {
                this.changeDetectorRef.detectChanges();
                //    } catch{ }
                //}, 300);

            }
        });

        this.invokeSaveSystemInfoEventLocal = this.eventEmitterService.invokeSaveSystemInfoEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {
                if (this.data.value == "" && this.systemValTemp != "") {
                    this.data.value = this.systemValTemp;
                    this.systemValTempActive = true;
                    this.getTextboxStyles = this.getTextboxStylesFunc();
                    this.changeDetectorRef.detectChanges();
                    this.changeDetectorRef.markForCheck();
                }
                //else
                //    this.systemValTempActive = false;
            }
        });

        this.systemInfoPage = this.componentService.systemInfoElements;

    }
    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
        this.invokeSaveSystemInfoEventLocal.unsubscribe();
    }

    connections: any = [];
    ngOnInit() {
        //
        this.data.isBarcode = typeof this.data.isBarcode === "undefined" ? false : true;

        this.connections = this.appInfo.RiverEntity.connections;

        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (this.data.isAuto) {
            this.data.disabled = true;
            //this.data.value = this.data.autoText + this.componentService.currentForm.formNo;
        }
        this.data.upperCaseLetter = this.data.upperCaseLetter == null ? false : this.data.upperCaseLetter;

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                this.changeDetectorRef.detectChanges();
            }
        });

        this.data.systemInfoId = typeof this.data.systemInfoId == "undefined" ? 0 : this.data.systemInfoId;
        if (this.data.value == "{|STARTED-USER-NAME|}" ||
            this.data.value == "{|STARTED-USER-ID|}" ||
            this.data.value == "{|START-DATE|}" ||
            this.data.value == "{|PROCESS-INSTANCE-ID|}" ||
            this.data.value == "{|FORM-ID|}") {
            this.data.value = "";
            this.systemValTemp = this.systemInfoPage.find(x => x.id == this.data.systemInfoId).value;
        }

        if (this.isEdit) {
            this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }

        this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };
        this.fillDesignerDataSource();

        this.getTextboxStyles = this.getTextboxStylesFunc();
    }

    showChamgeEventPopup: boolean = false;
    sqlScriptModel: SqlScriptTextboxModel;
    isMonacoLoaded: boolean = false;
    formElements: any;
    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let elements = this.componentService.getComponentList(this.componentService.currentForm.components).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            if (typeof this.data.sqlScripts == "undefined")
                this.data.sqlScripts = [];

            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptTextboxModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }


    onEnterKey(event) {
        debugger;
        if (this.data.value) {
            //this.data.value = event.value;
            this.data.sqlScripts.forEach(s => {
                let pref = "{" + this.data.id + "}";
                let value = this.data.value;
                let query = s.query.replace(pref, value);

                let curentComponents = this.componentService.getComponentList(this.componentService.currentForm.components);
                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = query.replace(f, _value);
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }


                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
                    console.log(result);
                    if (result) {
                        let element = this.componentService.getById(s.elementId, this.componentService.currentForm.components);
                        if (element.type) {
                            console.log("elementId: " + s.elementId);
                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery") {
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, result);
                            }
                            else {
                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let dataText = finalData.join(",");
                                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, this.data.value, dataText);
                                }
                            }
                        }
                    }
                });
            });
        }
        else {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, "clean_element", null, null);
        }
    }


    disableElements() {
        // data.systemInfoId != 0 
        return this.data.systemInfoId == 0 ? (typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false) : true;
    }
    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit && this.componentService.currentProcessFormId == 0 && typeof this.data.desktopDatasource != "undefined"
                && this.designerDatasourceModel.SQLSTR != "") {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {

            if (result && result.length > 0) {
                this.data.value = result[0][this.data.valueExpr];
                this.changeDetectorRef.detectChanges();
            }
            else {
                if (this.data.value != "") {
                    this.data.value = "";
                    this.data.dataSource = [];
                }
            }
        });
    }
    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);
            this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }
    systemInfoChange() {
    }
    onEditPopopShown() {

        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        this.data.desktopDatasource = this.designerDatasourceModel.ID;
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
            if (systemInfoTemp)
                this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            else
                this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }
    getTextboxStylesFunc() {//text-transform: uppercase

        let myStyles = {
            'text-transform': this.data.upperCaseLetter != null && this.data.upperCaseLetter != false ? 'uppercase' : 'none',
            'font-size': this.systemValTempActive ? "0px" : ""
        };
        return myStyles;
    }
    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);


                let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == this.data.id);
                if (systemInfoTemp)
                    this.componentService.currentSystemInfo.find(x => x.id == this.data.id).value = this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
                else
                    this.componentService.currentSystemInfo.push({ id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) });
                // this.componentService.currentSystemInfo = { id: this.data.id, value: this.systemInfoPage.find(x => x.id == this.data.systemInfoId) };//this.systemInfoPage.find(x => x.id == this.data.systemInfoId);
            }
        });
    }

}
