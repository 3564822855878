import { Component, OnInit, ViewChild } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { ComponentService } from "../component.service";
import { CdkDragDrop, CdkDragStart, CdkDragMove, CdkDragEnd, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';

import { FormModel } from '../../shared/models/form-model';
import { AuthService, EventEmitterService, AppInfoService, FormService, TaskService } from '../../shared/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DxDrawerComponent } from 'devextreme-angular';


@Component({
    selector: 'dx-builder',
    templateUrl: './formbuilder.html',
    styleUrls: ['./formbuilder.css'],
    preserveWhitespaces: true
})

export class FormBuilder implements OnInit {
    //@ViewChild("HadiLan", { read: ElementRef, static: false }) child: ElementRef;
    @ViewChild(DxDrawerComponent, { static: false }) drawer: DxDrawerComponent;

    languageCode: any;
    componentList: any[];
    history: any = [];
    currentIndex: number = 0;

    showSavePopup: boolean = false;

    renderData: any = [];
    isRender: boolean = false;

    formList: any;

    // liveWebButton: any;

    keyPress(event: any) {

        const pattern = /[A-Za-z_]/;
        const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
    onDragClass: string = "";
    actions = [
        {
            icon: "edit",
            alignment: "left",
            hint: "Edit",
            action: "edit"
        },
        {
            icon: "trash",
            alignment: "right",
            hint: "Delete",
            action: "delete"
        },
    ];
    formColor: any = "#ffffff";
    isEdit: boolean = false;
    currentField: any = {};
    toolbarContent = [];

    category = { parent: 0, sub: 0, isGeneric: true };
    categoryList: any = [];
    subCategoryList: any = [];

    constructor(private route: ActivatedRoute,
        private appInfo: AppInfoService,
        private authService: AuthService,
        private componentService: ComponentService,
        public formService: FormService,
        public taskService: TaskService,
        private eventEmitterService: EventEmitterService,
        private http: HttpClient) {
        this.authService.isFormBuilder = true;
        this.route.queryParams.subscribe(params => {
            this.checkUser(params.username, params.password);
        });
        this.componentService.createCdkList();

        this.setToolbarContext();

    }
    setToolbarContext() {

        this.formColor = this.formColor ? this.formColor : "#ffffff";
        let _this = this;
        this.toolbarContent = [{
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'menu',
                onClick: () => this.drawer.instance.toggle()
            }
        },
        {
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'plus',
                text: "",
                onClick: () => this.createForm()
            }
        },
        {
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'save',
                text: "",
                onClick: () => this.openSavePopup()
            }
        },
        {
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'undo',
                text: "",
                disabled: () => this.disableUndo(),
                onClick: () => this.undo()
            }
        },
        {
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'trash',
                text: "",
                onClick: () => this.deleteForm()
            }
        },
        {
            widget: 'dxColorBox',
            location: 'after',
            options: {
                value: this.formColor,
                onValueChanged(args) {

                    if (args.value) {
                        _this.formColor = args.value;
                    }
                    //   else {
                    //    this.formColor = "#ffffff";
                    //} 
                }
            }
        }
        ];
    }
    emailValue: any = "";
    onKeyPress(e) {

        let keyCode = e.event.key;
        this.componentService.currentForm.variableName = "";
        this.emailValue = "";
    }
    ngOnInit() {
        this.authService.isFormBuilder = true;
        this.languageCode = this.componentService.languageCode;
        this.componentList = this.componentService.componentList;
        this.getFormList();
        this.invokeFunctions();
        this.pushHistory(this.componentService.currentForm);

    }
    getFormStyle() {
        let myStyles = {
            'min-height': "958px", 'background': this.formColor,
        };
        return myStyles;
    }

    invokeFunctions() {
        this.eventEmitterService.invokeHistoryFunction.subscribe(sub => {
            if (sub.action === this.componentService.historyActions.save) {

                this.pushHistory(this.componentService.currentForm);
            }
            else if (sub.action === this.componentService.historyActions.undo) {
                this.undo();
            }
        });
        this.eventEmitterService.invokeFormChangesFunction.subscribe((name: string) => {
            this.componentService.evulateExpretion(this.componentService.currentForm.Expretion);
        });
        this.eventEmitterService.invokeLayoutChangeFunction.subscribe((name: string) => {
            //this.getCdkList();
        });
    }

    //changeFormColor(args)  {
    //    
    //    this.formColor = args.value;
    //}

    createForm() {
        let msg = "<i>Var olan form üzerinde yaptığınız değişiklikler silenecektir.Devam edilsin mi? </i>";
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.resetCurrentForm();
                this.isEdit = false;
            }
        });
    }

    itemClickAccordion(e) {

        if (!(e.component.shouldNotStop)) {
            e.component.shouldNotStop = true;
            e.component.option("selectedItem", this.componentList);
            e.removedItems = [];
            e.component.shouldNotStop = false;
        }
    };

    editForm(e) {

        if (e.itemData.isScheme) {
            if (this.componentService.currentForm && this.componentService.currentForm.components.length > 0) {
                let msg = "<i>Var olan form üzerinde yaptığınız değişiklikler silenecektir.Devam edilsin mi? </i>";
                let result = confirm(msg, "Uyarı!");
                result.then((dialogResult) => {
                    if (dialogResult) {
                        this.formService.getSingleForm(e.itemData.id).subscribe((result: any) => {
                            console.log(result);
                            this.componentService.currentForm = JSON.parse(result.forM_JSON);
                            this.componentService.currentForm.formId = result.id;
                            this.componentService.currentForm.categoryId = result.categorY_ID;
                            this.pushHistory(this.componentService.currentForm);

                            this.formColor = result.formcolor;

                            this.setToolbarContext();

                            this.componentService.currentSystemInfo = [];

                            if (typeof result.usedforms != "undefined")
                                this.componentService.usedForms = result.usedforms != null ? result.usedforms.split(",") : [];
                            else if (typeof result.usedForms != "undefined")
                                this.componentService.usedForms = result.usedforms != null ? result.usedForms.split(",") : [];
                        });
                    }
                });
            }
            else {
                this.formService.getSingleForm(e.itemData.id).subscribe((result: any) => {
                    console.log(result);

                    this.componentService.currentForm = JSON.parse(result.forM_JSON);
                    this.formColor = result.formcolor;

                    this.setToolbarContext();

                    this.componentService.currentForm.formId = result.id;
                    this.componentService.currentForm.categoryId = result.categorY_ID;
                    this.pushHistory(this.componentService.currentForm);


                    this.componentService.currentSystemInfo = [];

                    if (typeof result.usedforms != "undefined")
                        this.componentService.usedForms = result.usedforms != null ? result.usedforms.split(",") : [];
                    else if (typeof result.usedForms != "undefined")
                        this.componentService.usedForms = result.usedforms != null ? result.usedForms.split(",") : [];
                });
            }

        }
    }

    deleteForm() {
        if (this.componentService.currentForm.components.length > 0) {
            let result = confirm("<i>Formu silmek istediğinize emin misiniz?</i>", "Confirm changes");
            result.then((dialogResult) => {
                if (dialogResult) {
                    if (this.componentService.currentForm.formId > 0) {
                        this.formService.deleteForm(this.componentService.currentForm.formId).subscribe(result => {
                            if (result) {
                                this.showToast("Form Silindi", "success");
                                this.getFormList();
                                this.resetCurrentForm();
                            } else {
                                this.showToast("Üzgünüz, işlem sırasında bir hata oluştu", "error");
                            }

                        }, err => this.errorHandler(err));
                    }
                    else {
                        this.resetCurrentForm();
                    }
                }

            });
        }
    }

    checkUser(userName: string, password: string) {
        let params = new HttpParams().set("userName", userName).set("password", password);
        this.http.get<string>(this.appInfo.appUrl + "api/auth/login", { params: params }).subscribe((entity: any) => {
            if (entity.isConnect) {
                this.appInfo.RiverEntity = entity;
                this.appInfo.currentUser = entity.currentUser;
            }
            else {
                this.showToast(entity.message, "warning");
            }
        });
    }

    getFormList() {
        this.formService.getGroupedForms().subscribe(forms => {
            this.formList = forms;
        })
    }

    openSavePopup() {
        this.taskService.getCategoryList().subscribe((result: any) => {
            if (result && result.length > 0) {
                this.categoryList = result;
                if (this.componentService.currentForm.formId > 0) {
                    if (this.componentService.currentForm.categoryId > 0) {
                        let cat = result.find(f => f.id == this.componentService.currentForm.categoryId);
                        if (cat) {
                            this.category.parent = this.componentService.currentForm.categoryId;
                        }
                        else {
                            for (let i = 0; i < result.length; i++) {
                                var item = result[i].items.find(f => f.id == this.componentService.currentForm.categoryId);
                                if (item) {
                                    this.category.parent = this.componentService.currentForm.categoryId;
                                    this.subCategoryList = result[i].items;
                                    this.category.sub = item.id;
                                    break;
                                }
                            }
                        }

                        this.category.isGeneric = false;
                    }
                    else {
                        this.category.isGeneric = true;
                    }
                }
                else {
                    this.category.parent = 0;
                    this.subCategoryList = [];
                    this.category.sub = 0;
                    this.category.isGeneric = false;
                }
            }
            else {
                this.category.parent = 0;
                this.subCategoryList = [];
                this.category.sub = 0;
                this.category.isGeneric = false;
            }

            this.showSavePopup = true;
        });
    }

    onCategoryValueChanged(id) {
        let category = this.categoryList.find(f => f.id == id);
        if (category) this.subCategoryList = category.items;
        else this.subCategoryList = [];
    }

    dragStart(field) {
        this.onDragClass = "active";
        localStorage.setItem("currentField", JSON.stringify(field));
    }

    onDragEnded(event: CdkDragEnd): void {
        this.onDragClass = "";
    }

    cdkDrop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            let type = event.previousContainer.element.nativeElement.dataset.type;
            if (type && type == "copy") {
                let currentField: any = JSON.parse(localStorage.getItem("currentField"));
                //localStorage.removeItem(key);localStorage.clear();
                currentField.id = this.componentService.createId(currentField.type, this.componentService.currentForm.components);
                if (currentField.type == "codeditor") {
                    if (currentField.id == "codeditor1") this.componentService.currentForm.components.splice(this.componentService.currentForm.components.length, 0, currentField);
                    else this.showToast("Codeditor zaten mevcut?", "warning");
                }
                else this.componentService.currentForm.components.splice(event.currentIndex, 0, currentField);

                if (currentField.type == "grid" || currentField.type == "panel" || currentField.type == "tabs") {
                    this.componentService.createCdkList();
                }
                localStorage.removeItem("currentField");

                /*copyArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);*/
            }
            else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
            this.onDragClass = "";
        }

        this.pushHistory(this.componentService.currentForm);
    }


    itemClick(e, item) {
        if (e.itemData.action == "edit") {
            this.eventEmitterService.showPopop(item.uuid);
            //item.showEditPop = true;
        }
        else if (e.itemData.action == "delete") {
            let msg = item.type == "grid" || item.type == "panel" ? "<i>Bu component içerisindeki elemanlar silinecektir?</i>" : "<i>Emin misiniz?</i>"
            let result = confirm(msg, "Uyarı!");
            result.then((dialogResult) => {
                if (dialogResult) this.removeField(item);
            });
        }
    }

    removeField(item) {
        this.componentService.currentForm.components.splice(this.componentService.currentForm.components.indexOf(item), 1);
        this.pushHistory(this.componentService.currentForm);
        this.showToast("Silindi", "success");
    }

    toggleValue(item) {
        item.selected = !item.selected;
    }

    pushHistory(data) {
        let cloneData = this.componentService.cloneModel(data);
        this.history.push(cloneData);
        //console.log(this.history)
    }

    disableUndo(): boolean {
        return this.history.length < 2;
    }

    undo() {
        if (this.history.length > 1) {
            this.history.splice(this.history.length - 1, 1);
            this.componentService.currentForm = {};
            this.componentService.currentForm = this.history[this.history.length - 1];// this.componentService.cloneModel(this.history[this.history.length -1]);
            //console.log(this.history)
        }
        else {
            this.resetCurrentForm();
            this.history[0] = this.componentService.currentForm;
        }
    }

    saveForm() {
        if (this.componentService.currentForm.components.length <= 0) {
            this.showToast("Henuz form elemanları eklenmedi", "warning");
        }
        else if (this.componentService.currentForm.name == "") {
            this.showToast("Lütfen formonuza bir isim veriniz", "warning");
        }
        else {

            this.componentService.currentForm.data = [];
            this.createValuesData(this.componentService.currentForm.components);
            let formData = new FormModel();
            formData.ID = this.componentService.currentForm.formId;
            formData.USER = this.appInfo.currentUser.id;
            formData.NAME = this.componentService.currentForm.name;
            formData.DESCRIPTION = this.componentService.currentForm.description;
            
            let curentFormFiles = this.componentService.getFilesComponentList(this.componentService.currentForm.components);
            let pictureBoxComp = curentFormFiles.filter(x => x.type == "picturebox");
            let pictureBoxTemp = [];
            pictureBoxComp.forEach(pict => {
                let objPict = {
                    valueTemp: pict.valueTemp,
                    id: pict.id
                };
                pictureBoxTemp.push(objPict);
                pict.valueTemp = "";  
            });

            formData.FORM_JSON = JSON.stringify(this.componentService.currentForm, null, 4);
            formData.FORM_VARIABLE = JSON.stringify(this.componentService.currentForm.data, null, 4);

            pictureBoxTemp.forEach(pict => {
                let pictureBoxCompTemp = curentFormFiles.find(x => x.id == pict.id);
                pictureBoxCompTemp.valueTemp = pict.valueTemp;
            });
             
            formData.ISMENU = this.componentService.currentForm.components.filter(x => x.type == "menu").length > 0;

            formData.USEDFORMS = this.componentService.usedForms.toString();


            formData.MENUOPTIONS = JSON.stringify(this.componentService.menuFormNodeOptions);
            formData.FORMCOLOR = this.formColor;

            console.log(this.componentService.currentForm);

            if (this.category.isGeneric) formData.CATEGORY_ID = 0;
            else if (this.category.sub && this.category.sub > 0) formData.CATEGORY_ID = this.category.sub;
            else if (this.category.parent && this.category.parent > 0) formData.CATEGORY_ID = this.category.parent;
            else formData.CATEGORY_ID = 0;

            if (this.componentService.currentForm.formId > 0) {
                this.formService.updateForm(formData).subscribe((result: any) => {
                    if (result > 0) {
                        this.showToast("Form Güncellendi", "success");
                        this.showSavePopup = false;
                        this.getFormList();
                    } else {
                        this.showToast("Üzgünüz, işlem sırasında bir hata oluştu", "error");
                    }

                }, err => this.errorHandler(err));
            }
            else {
                formData.ID = 0;
                this.formService.saveForm(formData).subscribe((result: any) => {
                    if (result > 0) {
                        this.resetCurrentForm();
                        //this.componentService.currentForm.id = result;
                        this.showToast("Kaydedildi", "success");
                        this.showSavePopup = false;
                        this.getFormList();
                    } else {
                        this.showToast("Üzgünüz, işlem sırasında bir hata oluştu", "error");
                    }

                }, err => this.errorHandler(err));
            }

        }


    }

    createValuesData(components) {
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true
                || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].inputType == "menu"
                || components[i].inputType == "map"
                || components[i].type == "gallery") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;

                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;



                if (components[i].type == "livestream") {

                    obj["meetingType"] = components[i].meetingType;
                }
                if (components[i].type == "button") {

                    //  let selectedButton = components[i];//.find(x => x.property.name == this.buttonNameGlobal);
                    obj["name"] = components[i].nameValue;
                    //obj["isLiveWeb"] = false;// selectedButton.isLiveWeb;
                    //obj["liveWeb"] = { isCreateLiveWeb: true, urlHost: "", urlParticipant: "" };//selectedButton.liveWeb;
                }
                if (components[i].type == "textbox") {

                    let systemInfoTemp = this.componentService.currentSystemInfo.find(x => x.id == obj["key"]);
                    obj["isSystemInfo"] = components[i].systemInfoId != 0 ? true : false;
                    obj["value"] = components[i].systemInfoId != 0 ? systemInfoTemp.value.value : obj["value"];
                }


                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;

                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                }
                else if (components[i].type == "map") {
                    obj["markers"] = components[i].markers;
                }
                else {
                    obj["markers"] = [];
                    obj["headers"] = "";
                    obj["headerTypes"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                }
                this.componentService.currentForm.data.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(components[i].items[j].components);
                }
            }
        }
    }

    getGridHeaders(grid) {
        let headers = [];
        let headerTypes = [];

        grid.headers.forEach(header => {

            var obj: any = {};
            if (header.type != "file") {
                headers.push(header.field);
                headerTypes.push(header.type);
            }
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }


    resetCurrentForm() {
        this.componentService.currentForm = {
            formId: 0,
            user: '',
            name: '',
            description: '',
            permitions: '',
            expretion: '',
            components: [],
            data: []
        };
        this.currentIndex = 0;
        this.history = [];

        this.componentService.currentSystemInfo = [];
    }

    render() {
        localStorage.setItem("renderFormComponents", JSON.stringify(this.componentService.currentForm.components));
        this.renderData = JSON.parse(localStorage.getItem("renderFormComponents"));
        this.componentService.checkUserAuthorization(false, this.renderData, this.appInfo.currentUser.id)
        this.isRender = true;
    }

    reset() {
        this.isRender = false;
        this.renderData = [];
        localStorage.removeItem("renderFormComponents");
        this.eventEmitterService.setColumnSumToComponent("sys_re_set_val", 0);
    }

    showToast(message, status) {
        notify(message, status, 3000);
    }

    errorHandler(error) {
        let errorMessage = '';
        errorMessage = error.error instanceof ErrorEvent ? error.error.message : 'Error Code: ${error.status}\nMessage: ${error.message}';
        console.log(errorMessage);
        this.showToast(errorMessage, "error");
    }


}
