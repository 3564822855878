import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'number-component',
    templateUrl: './number.component.html'
})
export class DxNumberComponent implements ComponentParameters, OnDestroy {
    @Input() data: any;
    @Input() lang: any;
    @Input() isEdit: any;
    @Input() disabled: boolean;

    showDisplay: any = "flex";
    showDisplayBoolean = false;

    dataTypes = [{ "name": "Ondalıklı Sayı", "format": "#,##0.00", "type": "int" }, { "name": "Rakam", "format": "#", "type": "decimal" }];

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    isFormatDisabled: boolean = false;
    invokeChangeEventLocal: Subscription;
    constructor(private eventEmitterService: EventEmitterService,
        public changeDetectorRef: ChangeDetectorRef,
        public componentService: ComponentService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            if (sub.elementId == this.data.id) {
                sub.data = sub.data == "" ? 0 : sub.data;
                if (sub.data != null && typeof sub.data != "undefined") this.data.value = sub.data;
                else if (sub.elementId == "clean_element") this.data.value = "";
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                this.changeDetectorRef.detectChanges();
            }
        });

        
    }

    disableElements() {
       return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false; 
    }
    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    onChange() {
        this.eventEmitterService.executeExpretion();
    }

    onValueChanged(event) {
        this.isFormatDisabled = event.value == "#";
        if (this.isFormatDisabled) this.data.inputType = "int";
        else this.data.inputType = "decimal";
    }

}
