import { Component, OnInit, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';

import notify from "devextreme/ui/notify";

@Component({
    selector: 'languagebutton-component',
    templateUrl: './languagebutton.component.html'
})
export class DxLanguageButtonComponent implements OnInit {
    @Input() dataSource: any;

    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enUS",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
        {
            "code": "esES",
            "name": "Español",
        },
        {
            "code": "frFR",
            "name": "Français",
        },
        {
            "code": "bgBG",
            "name": "български",
        }
    ]

    buttonCols: any = [];

    constructor() {

    }

    ngOnInit() {
        
        this.buttonCols = Object.keys(this.dataSource[0]);
        const index_Code = this.buttonCols.findIndex(item => item === "Code");
        if (index_Code != -1)
            this.buttonCols.splice(index_Code, 1);
        const button_Id = this.buttonCols.findIndex(item => item === "buttonId");
        if (button_Id != -1)
            this.buttonCols.splice(button_Id, 1);
        const index_DefaultLabel = this.buttonCols.findIndex(item => item === "DefaultLabel");
        if (index_DefaultLabel != -1)
            this.buttonCols.splice(index_DefaultLabel, 1);
    }

}
