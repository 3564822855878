import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '../app-info.service';
import { SchemeModel } from '../../../shared/models/scheme-model';
import { CurrentFormModel } from '../../models/current-form-model';
import { TaskModel } from '../../models/task-model';
import { CurrentFormDataModel } from '../../models/form-data-model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getCategoryList() {
        return this.http.get(this.appInfo.appUrl + "api/task/getcategorylist");
    }

    getSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschemalist", { params: params });
    }

    getSidebarMenu(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getsidebarmenulist", { params: params });
    }

    SetFavorite(schemeId): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("schemeId", schemeId);
        return this.http.get<any>(this.appInfo.appUrl + "api/task/updatefavorite", { params: params });
    }

    GetFavoriteList(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getfavoritelist", { params: params });
    }

    getTaskList(filter) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("filter", filter);
        return this.http.get(this.appInfo.appUrl + "api/task/gettasks", { params: params });
    }

    getHistoryPage(id) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("type", id);
        return this.http.get(this.appInfo.appUrl + "api/task/gethistorypage", { params: params });
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
