import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '../app-info.service';
import { ReportModel } from '../../models/report-model';
import { NodeModel } from '../../models/node-model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }


    saveNode(node): Observable<NodeModel> {
        return this.http.post<NodeModel>(this.appInfo.appUrl + "api/report/savereportnode", JSON.stringify(node), this.appInfo.httpOptions);
    }

    getParentNodes() {
        return this.http.get(this.appInfo.appUrl + "api/report/getparentnodes");
    }

    getDashboardChart(userId) {
        let params = new HttpParams().set("userId", userId);
        return this.http.get<NodeModel>(this.appInfo.appUrl + "api/report/getdashboardchart", { params: params });
    }

    getNodeById(id): Observable<NodeModel> {
        let params = new HttpParams().set("id", id);
        return this.http.get<NodeModel>(this.appInfo.appUrl + "api/report/getnodebyid", { params: params });
    }

    saveReport(report): Observable<ReportModel> {
        return this.http.post<ReportModel>(this.appInfo.appUrl + "api/report/savereport", JSON.stringify(report), this.appInfo.httpOptions);
    }


    getReportType(id): Observable<any> {
        let params = new HttpParams().set("id", id);
        return this.http.get<any>(this.appInfo.appUrl + "api/report/getreporttype", { params: params });
    }
    updateReportType(_reportId, _reportType, _reportTypeAll): Observable<Boolean> {
        let report = { reportId: _reportId, reportType: _reportType, reportTypeAll: _reportTypeAll };
        return this.http.post<Boolean>(this.appInfo.appUrl + "api/report/savereporttype", JSON.stringify(report), this.appInfo.httpOptions);
    }

    getAllReports() {
        return this.http.get(this.appInfo.appUrl + "api/report/getallreports");
    }


    getReportWithoutCategory() {
        return this.http.get(this.appInfo.appUrl + "api/report/getreportwithoutcategory");
    }

    getReports() {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        return this.http.get(this.appInfo.appUrl + "api/report/getreports", { params: params });
    }

    getReportById(id): Observable<ReportModel> {
        let params = new HttpParams().set("id", id);
        return this.http.get<ReportModel>(this.appInfo.appUrl + "api/report/getreportbyid", { params: params });
    }

    getReportStorage(reportId, reportType) {
        let params = new HttpParams().set("reportId", reportId).set("reportType", reportType);
        return this.http.get(this.appInfo.appUrl + "api/report/getreportstorage", { params: params });
    }

    getReportDataExtraColumns(reportId) {
        let params = new HttpParams().set("reportId", reportId);
        return this.http.get(this.appInfo.appUrl + "api/report/getreportdataextracolumns", { params: params });
    }

    getReportDataFormat(reportId) {
        let params = new HttpParams().set("reportId", reportId);
        return this.http.get(this.appInfo.appUrl + "api/report/getreportdataformat", { params: params });
    }

    updateReportStorage(reportId, reportType, storage, datafilter, dataformat, sumColumn) {
        //let params = new HttpParams().set("reportId", reportId).set("reportType", reportType).set("storage", storage).set("dataFilter", datafilter)
       //     .set("dataFormat", dataformat).set("sumColumn", sumColumn);

        let report = { reportId: reportId, reportType: reportType, storage: storage, dataFilter: datafilter, dataFormat: dataformat, sumColumn: sumColumn };
        return this.http.post<Boolean>(this.appInfo.appUrl + "api/report/updatereportstorage", JSON.stringify(report), this.appInfo.httpOptions);

       // return this.http.get(this.appInfo.appUrl + "api/report/updatereportstorage", { params: params });
    }

    deleteNode(id) {
        let params = new HttpParams().set("id", id);
        return this.http.get(this.appInfo.appUrl + "api/report/deletenode", { params: params });
    }

    deleteReport(id) {
        let params = new HttpParams().set("id", id);
        return this.http.get(this.appInfo.appUrl + "api/report/deletereport", { params: params });
    }

    updateEditMode(id) {
        let params = new HttpParams().set("id", id);
        return this.http.get(this.appInfo.appUrl + "api/report/updateeditmode", { params: params });
    }
    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
