import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';

export class SqlScriptModel {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}


@Component({
    selector: 'selectbox-component',
    templateUrl: './selectbox.component.html',
    styleUrls: ['./selectbox.component.css']
})
export class DxSelectboxComponent implements ComponentParameters, OnInit, OnDestroy {
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;

    showDisplay: any = "flex";
    showDisplayBoolean = false;


    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enUS",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
        {
            "code": "esES",
            "name": "Español",
        }
    ]
    //        { id: 0, text: "Türkçe", val1: "TR", val2: "tr" },
    ////{ id: 1, text: "USA", val1: "US", val2: "en" },
    //{ id: 1, text: "English", val1: "EN", val2: "en" },
    //{ id: 2, text: "Français", val1: "FR", val2: "fr" },
    //{ id: 3, text: "Deutsche", val1: "DE", val2: "de" },
    //{ id: 4, text: "Español", val1: "ES", val2: "es" },
    //{ id: 5, text: "Italiano", val1: "IT", val2: "it" },
    //{ id: 6, text: "Pусский", val1: "RU", val2: "ru" },
    //{ id: 7, text: "日本語", val1: "JP", val2: "ja" },
    //{ id: 8, text: "中文", val1: "CN", val2: "cmn-Hans" },
    //{ id: 9, text: "한국어", val1: "KR", val2: "ko" },
    //{ id: 10, text: "हिन्दी", val1: "IN", val2: "hi" },
    //, almanca, sırpça, basitleştirilmiş çince, ispanyolca

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    invokeChangeEventLocal: Subscription;
    // editorOptions = { theme: 'vs-dark', language: 'sql', automaticLayout: true };
    connections: any = [];
    selecboxComponents: any = [];

    sqlScriptModel: SqlScriptModel;

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {

            if (this.data.id != sub.id && sub.elementId == this.data.id) {

                if (sub.data) {
                    //this.data.dataSource  = sub.data;

                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        let cols = Object.keys(result[0]);
                        if (cols) {
                            result.forEach(item => {
                                if (!item.text) {

                                    if (cols.length == 1) {
                                        item.text = item[cols[0]];
                                    }
                                    else {
                                        let displayList = [];
                                        cols.forEach(f => {
                                            if (f != this.data.valueExpr) {
                                                displayList.push(item[f]);
                                            }
                                        });
                                        item.text = displayList.join(",");
                                    }
                                }
                            });

                        }
                        this.data.dataSource = result;
                        this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;
                        this.changeDetectorRef.detectChanges();

                        let _event_ = { value: this.data.value };
                        this.onValueChanged(_event_);
                    }
                    else {
                        this.data.value = result;
                    }

                }
                else if (sub.elementId == "clean_element") this.data.dataSource = [];

                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;
        console.log("this.isEdit: " + this.isEdit);
        if (!this.isEdit) {
            this.executeScript();
            /*setTimeout(function () {
              this.executeScript();
            }.bind(this), 1000);*/
        }
        else {

            this.fillDesignerDataSource();
        }


        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;

                this.changeDetectorRef.detectChanges();
            }
        });

        if (typeof this.data.isDefaultFirstValue === "undefined")
            this.data.isDefaultFirstValue = false;

        let fff = this.data;
    }


    disableElements() {
        return typeof this.data.disabled != "undefined" ? (this.data.disabled == false ? this.data.outsideDisabled : this.data.disabled) : false;
    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);

            /*if (this.data.dataSourceKey == "" && this.data.dataSource.length <= 0)
              this.componentService.showAlert("Uyarı! Henüz veri eklenmedi.", "warning", 4000);*/
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
        this.showDisplayBoolean = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? true : false) : true;
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = "";
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);

            //  this.designerDatasourceModel = { ID: 0, NAME: "", SQLSTR: "" };

        }
    }


    onValueChanged(event) {
        if (event.value) {
            this.data.value = event.value;
            this.data.sqlScripts.forEach(s => {
                let pref = "{" + this.data.id + "}";
                let value = event.value;
                let query = s.query.replace(pref, value);

                let curentComponents = this.componentService.getComponentList(this.componentService.currentForm.components);
                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = query.replace(f, _value);
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }


                this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
                    console.log(result);
                    if (result) {
                        let element = this.componentService.getById(s.elementId, this.componentService.currentForm.components);
                        if (element.type) {
                            console.log("elementId: " + s.elementId);
                            if (element.component == "DxDatagridSelectComponent" || element.type == "datalist" || element.type == "selectbox" || element.type == "gallery") {
                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, result);
                            }
                            else {
                                let keys = Object.keys(result[0]);
                                if (keys.length > 0) {
                                    let finalData = [];
                                    keys.forEach(f => {
                                        finalData.push(result[0][f]);
                                    });
                                    let dataText = finalData.join(",");
                                    this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, dataText);
                                }
                            }
                        }
                    }
                });
            });
        }
        else {
            this.eventEmitterService.selectboxChangeEvent(this.data.id, "clean_element", null, null);
        }
    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }

    public executeScript() {

        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0) {
                this.data.dataSource = filteredData;
                this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;

                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            }//displayExpr
        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {

            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {
                    result.forEach(item => {
                        if (cols.length == 1) {
                            item.text = item[cols[0]];
                        }
                        else {
                            let displayList = [];
                            cols.forEach(f => {
                                if (f != this.data.valueExpr) {
                                    displayList.push(item[f]);
                                }
                            });
                            item.text = displayList.join(",");
                        }
                    });
                }
                this.data.dataSource = result;
                this.data.value = this.data.isDefaultFirstValue && this.data.dataSource.length > 0 && this.data.value == "" ? this.data.dataSource[0][this.data.valueExpr] : this.data.value;
                this.changeDetectorRef.detectChanges();

                let _event_ = { value: this.data.value };
                this.onValueChanged(_event_);
            }
            else {
                this.data.dataSource = [];
            }
        });
    }


    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox");
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            }
            //this.dataSources = this.componentService.getComponentsByType("datasource");
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>Manuel veri eklediğiniz için sql script ve bağlantı adresi silinecektir! Devam edilsin mi?</i>";
            let result = confirm(msg, "Uyarı!");
            result.then((dialogResult) => {
                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    resetDataSources() {
        this.data.dataSourceKey = "";
        this.data.dataSource = [];

        notify("Data Sıfırlandı", "success", 3000);
    }

    apply() {
        this.executeScript();
    }

    prepareGrid() {
        new CustomStore({
            key: "Id",
            load: () => this.data.dataSource,
            insert: (values) => this.insert(JSON.stringify(values)),
            update: (key, values) => this.update(key, JSON.stringify(values)),
            remove: (key) => this.remove(key)
        });
    }

    executeExp() {
        this.eventEmitterService.executeExpretion();
    }

    insert(values: any = {}): any {
        this.data.dataSource.push(values);
    }

    update(key, values: any = {}): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource[index] = values;
    }

    remove(key): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource.splice(index, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let elements = this.componentService.getComponentList(this.componentService.currentForm.components).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    formElements: any;
    openChamgeEventPopup() {
        this.isMonacoLoaded = false;
        let elements = this.componentService.getComponentList(this.componentService.currentForm.components).map(m => m.id);
        this.formElements = elements.filter(f => f != this.data.id);
        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
        this.showChamgeEventPopup = true;
    }

    saveSqlScript() {
        let model = this.componentService.cloneModel(this.sqlScriptModel);

        if (model.id > 0) {
            let scr = this.data.sqlScripts.find(f => f.id == model.id);
            scr.connectionId = model.connectionId;
            scr.elementId = model.elementId;
            scr.query = model.query;
        }
        else {
            model.id = this.data.sqlScripts.length + 1;
            this.data.sqlScripts.push(model);
        }

        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.query = 'SELECT * FROM TABLE WHERE KEY={' + this.data.id + '}';
    }

    updateSqlScript(i) {
        let scr = this.data.sqlScripts[i];
        this.sqlScriptModel = new SqlScriptModel();
        this.sqlScriptModel.id = scr.id;
        this.sqlScriptModel.connectionId = scr.connectionId;
        this.sqlScriptModel.elementId = scr.elementId;
        this.sqlScriptModel.query = scr.query;
    }

    deleteSqlScript(i) {
        this.data.sqlScripts.splice(i, 1);
    }


}
