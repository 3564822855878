import { Component, OnInit,Input } from '@angular/core';
import { ComponentParameters } from '../component.parameters';

@Component({
  selector: 'app-htmleditor',
  templateUrl: './htmleditor.component.html',
  styleUrls: ['./htmleditor.component.scss']
})
export class DxHtmleditorComponent implements ComponentParameters {
  @Input() data: any;
  @Input() isEdit: any;
   @Input() lang: string;

  constructor() { }

  ngOnInit() {
  }

}
