import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { ComponentService } from '../component.service';
import { confirm } from 'devextreme/ui/dialog';
import { EventEmitterService } from '../../shared/services';
import { Subscription } from 'rxjs';
import { InstanceService } from '../../shared/services/data/instance.service';

@Component({
    selector: 'app-picturebox',
    templateUrl: './picturebox.component.html',
    styleUrls: ['./picturebox.component.css']
})
export class DxPictureBoxComponent implements ComponentParameters, OnInit {
    @Input() data: any;
    @Input() lang: string;
    @Input() isEdit: boolean;

    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showSettingPopup: boolean = false;
    showFilesPopup: boolean = false;

    showDisplay: any = "flex";

    uploadedFiles: any = [];
    uploadResult = { isShow: false, status: "success", message: "" };
    totalSize: any = 0;

    invokeChangeEventLocal: Subscription;

    constructor(private eventEmitterService: EventEmitterService, public changeDetectorRef: ChangeDetectorRef, private componentService: ComponentService,
        public instanceService: InstanceService) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });

        //this.data.valueTemp = [];
        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            
            if (sub.elementId == this.data.id) {
                
                if (sub.data) {
                    this.data.valueTemp = [];
                    this.data.valueTemp.push(sub.data);
                }
                else this.data.valueTemp = [];
                //setTimeout(() => {
                //    try {
                this.changeDetectorRef.detectChanges();
                //    } catch{ }
                //}, 300);

            }
        });

    }


    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);

        if (typeof this.data.show === "undefined")
            this.data.show = true;
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";

        this.data.width = this.data.width > 0 ? this.data.width : 90;
        this.data.height = this.data.height > 0 ? this.data.height : 90;

        if (typeof this.data.valueTemp == "undefined")
            this.data.valueTemp = [];
    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {
        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide ? "flex" : "none") : "flex";
        //this.storage.save(this.model.uuid, JSON.stringify(this.model));
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    openPermissionPopup() {
        this.permissionPopup = true;
    }

    onValueChanged = (e: any): void => {
        let errors = [];
        this.data.valueTemp = [];
        if (e.value.length) {
            //this.data.valu e = [];
            this.totalSize = 0;
            this.uploadResult.isShow = true;
            this.uploadResult.status = "primary";
            this.uploadResult.message = "Dosyalar yükleniyor...";
            //  for (let i = 0; i < .length; i++) {
            let checkSum = this.totalSize + e.value[0].size;
            if (checkSum > (this.data.maxFileSize * 1000000)) {
                let msg = e.value[0].name + " yüklenemedi."
                errors.push(msg);
                return;
            }

            this.getDocument(e.value[0]).then(document => {
                this.data.valueTemp.push(document);//.push(document);
                this.totalSize += e.value[0].size;
                //if (i === (e.value.length - 1)) {
                if (errors.length) {
                    this.uploadResult.status = "danger";
                    this.uploadResult.message = "Dosya boyutu aşıldı: " + errors.join(", ");
                }
                else {
                    this.uploadResult.status = "success";
                    this.uploadResult.message = "Dosyalar yüklendi";
                }

                if (this.isEdit) {

                    let objArray = [];
                    let obj: any = {
                        componentId: this.data.id,
                        rowId: "-1",
                        columnId: "-1",
                        fileData: this.data.valueTemp[0].base64BlobData,
                        fileExtension: this.data.valueTemp[0].extension,
                        fileType: this.data.valueTemp[0].fileType,
                        isImage: this.data.valueTemp[0].isImg,
                        name: this.data.valueTemp[0].name,
                        size: this.data.valueTemp[0].size
                    };

                    objArray.push(obj);

                    this.instanceService.saveFormFilesData(objArray, 0, this.data.value).subscribe((s: any) => {

                        let _fileVal = s.files.filter(x => x.ComponentId == this.data.id);
                        if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                            this.data.value = _fileVal.map(ele => ele.Id);

                        }

                    });

                }

                this.changeDetectorRef.detectChanges();
                // }
            });

            // }
        }
    }

    getDocument(file) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = file.name;
                    let fileType: string = file.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = file.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        src: 'data:' + fileType + ';base64,' + base64BlobData,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: true
                    };
                    resolve(document);

                }
            }

        });
    }
    imageUploadVisible = false;
    onBlur() {
        this.imageUploadVisible = false;
    }
    onFocus() {
        this.imageUploadVisible = true;
    }
    imageDivStyleFunc() {

        var width = this.data.width > 0 ? this.data.width + 'px' : '90px';
        var height = this.data.height > 0 ? this.data.height + 'px' : '92px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay, 'position': 'relative' };
        return styles;
    }
    imageStyleFunc() {

        var width = this.data.width > 0 ? this.data.width + 'px' : '90px';
        var height = this.data.height > 0 ? (this.data.height - 2) + 'px' : '88px';
        const styles = { 'width': width, 'height': height, 'display': this.showDisplay };
        return styles;
    }

    showFiles() {
        for (let i = 0; i < this.data.valueTemp.length; i++) {
            this.data.valueTemp[i].src = 'data:' + this.data.valueTemp[i].fileType + ';base64,' + this.data.valueTemp[i].base64BlobData;
        }
        this.showFilesPopup = true;
    }

    deleteFiles() {
        let result = confirm("<i>Dosyaları silmek istediğinize emin misiniz?</i>", "Dosyalar Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.uploadedFiles = [];
                for (let i = 0; i < this.data.valueTemp.length; i++) {
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                }
                this.data.valueTemp = [];
                this.uploadResult.isShow = false;
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    deleteFile(i) {
        let result = confirm("<i>Dosya silinsin mi?</i>", "Dosya Silinecek!");
        result.then((dialogResult) => {
            if (dialogResult) {
                if (this.data.valueTemp && this.data.valueTemp.length > 0 && this.data.valueTemp[i])
                    this.eventEmitterService.setDeletedFileIds(this.data.valueTemp[i].id);
                this.data.valueTemp.splice(i, 1);
                this.changeDetectorRef.detectChanges();
                this.showFiles();
            }
        });

    }

}
