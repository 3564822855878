import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, ContentChild, ElementRef } from '@angular/core';
import { ComponentParameters } from '../component.parameters';
import { EventEmitterService, FormService, GenericService, AppInfoService } from '../../shared/services';
import CustomStore from 'devextreme/data/custom_store';
import { ComponentService } from '../component.service';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { EditorComponent } from 'ngx-monaco-editor';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';

export class SqlScriptModelListDatagrid {
    id: number;
    connectionId: number;
    elementId: string;
    query: string;
}


@Component({
    selector: 'datalist-component',
    templateUrl: './datalist.component.html',
    styleUrls: ['./datalist.component.css']
})
export class DxDataListComponent implements ComponentParameters, OnInit, OnDestroy, AfterViewInit {


    @ViewChild(DxDataGridComponent, { static: false }) gridContainer;
    @Input() data: any;
    @Input() isEdit: any;
    @Input() lang: string;

    //dropDownOptions: object;

    allMode: string;
    checkBoxesMode: string;

     
    showDisplay: any = "flex";


    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enUS",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
        {
            "code": "esES",
            "name": "Español",
        }
    ]

    designerDataSourceList: any;
    designerDatasourceModel: any;

    dataSources: any;
    dataSourcePopup: boolean = false;
    isFromDataSource: boolean;
    showSettingPopup: boolean = false;
    defaultLanguage: any = {};
    permissionPopup: boolean = false;
    languagePopup: boolean = false;
    showChamgeEventPopup: boolean = false;
    isMonacoLoaded: boolean = false;

    invokeChangeEventLocal: Subscription;

    connections: any = [];
    selecboxComponents: any = [];

    sqlScriptModel: SqlScriptModelListDatagrid;
     

    constructor(public appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef) {
        this.eventEmitterService.invokeShowPopopFunction.subscribe(sub => {
            if (sub.key === this.data.uuid) {
                this.showSettingPopup = true;
            }
        });
        
        this.invokeChangeEventLocal = this.eventEmitterService.invokeChangeEvent.subscribe(sub => {
            
            if (this.data.id != sub.id && sub.elementId == this.data.id) {
                if (sub.data) {
                   
                    let result = sub.data;
                    if (result && result.length > 0 && Array.isArray(result)) {
                        //let cols = Object.keys(result[0]);
                        //if (cols) {
                            
                        //    result.forEach(item => {
                        //        if (!item.text) {

                        //            if (cols.length == 1) {
                        //                item.text = item[cols[0]];
                        //            }
                        //            else {
                        //                let displayList = [];
                        //                cols.forEach(f => {
                        //                    if (f != this.data.valueExpr) {
                        //                        displayList.push(item[f]);
                        //                    }
                        //                });
                        //                item.text = displayList.join(",");
                        //            }
                        //        }
                        //    });

                        //}
                        this.data.dataSource = result;
                        this.changeDetectorRef.detectChanges();
                    }
                    else {
                        this.data.value = result;
                    }

                }
                else if (sub.elementId == "clean_element") this.data.dataSource = []; 
                this.changeDetectorRef.detectChanges();
            }
        });
        this.allMode = 'allPages';
        this.checkBoxesMode = 'onClick'
    }

    ngOnDestroy() {
        this.invokeChangeEventLocal.unsubscribe();
    }
    ngOnInit() {
        this.data.uuid = this.componentService.createuuid();
        this.defaultLanguage = this.componentService.getLang(this.lang, this.data.language);
        this.connections = this.appInfo.RiverEntity.connections;
        console.log("this.isEdit: " + this.isEdit);
        if (!this.isEdit) {
            this.executeScript();
           
        }
        else {

            this.fillDesignerDataSource();
        }


        if (typeof this.data.show === "undefined")
            this.data.show = true;

        this.data.outsideDisabled = typeof this.data.outsideDisabled == "undefined" ? false : this.data.outsideDisabled;
        this.data.outsideVisible = typeof this.data.outsideVisible == "undefined" ? true : this.data.outsideVisible;

        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";


        this.eventEmitterService.invokeChangeVisibleFunction.subscribe(sub => {
            if (sub.data.key === this.data.uuid) {
                if (typeof sub.data.outsideVisible != "undefined")
                    this.data.outsideVisible = sub.data.outsideVisible;
                this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
                 
                this.changeDetectorRef.detectChanges();
            }
        });

        let fff = this.data;

      //  this.dropDownOptions = { width: this.data.width };
    }


    ngAfterViewInit() {
        if (!this.isEdit) { 
         
        };
    }
    onSelectionChanged(event) {
        
        this.data.value = event.selectedRowKeys;
        //if (event.currentSelectedRowKeys.length > 0) {

        //    let _data = event.selectedRowsData;
        //    if (_data) { 
        //        this.data.value = _data[0][this.data.valueExpr]; 
        //        dropDow nBoxComponent.close();
                 
        //        this.data.sqlScripts.forEach(s => {
        //            let pref = "{" + this.data.id + "}";
        //            let value = this.data.value;
        //            let query = s.query.replace(pref, value);
        //            this.genericService.executeQuerySecurited(query, s.connectionId).subscribe((result: any) => {
        //                console.log(result);
        //                if (result) {
        //                    let element = this.componentService.getById(s.elementId, this.componentService.currentForm.components);
        //                    if (element.type) {
        //                        console.log("elementId: " + s.elementId);
        //                        if (element.component == "DxDatagridSelectComponent" || element.type == "gallery") {
        //                            this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, result);
        //                        }
        //                        else {
        //                            let keys = Object.keys(result[0]);
        //                            if (keys.length > 0) {
        //                                let finalData = [];
        //                                keys.forEach(f => {
        //                                    finalData.push(result[0][f]);
        //                                });
        //                                let dataText = finalData.join(",");
        //                                this.eventEmitterService.selectboxChangeEvent(this.data.id, s.elementId, event.value, dataText);
        //                            }
        //                        }
        //                    }
        //                }
        //            });
        //        });


        //    }
        //    else {
        //        this.eventEmitterService.selectboxChangeEvent(this.data.id, "clean_element", null, null);
        //    }

        //}
         
    }

    fillDesignerDataSource() {
        this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };

        this.genericService.fillDesignerDataSource().subscribe((result: any) => {

            this.designerDataSourceList = result;
            this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            if (this.data.desktopDatasource != null) {
                this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == this.data.desktopDatasource);

                if (typeof this.designerDatasourceModel.ID === "undefined")
                    this.designerDatasourceModel = { ID: this.data.desktopDatasource, NAME: "", SQLSTR: "" };
            }

            if (!this.isEdit) {

                this.genericServiceExecuteScript(this.designerDatasourceModel.SQLSTR, this.designerDatasourceModel.CONNID);
            }

        });

    }

    onEditPopopShown() {
        this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
    }

    saveSetting() {

        this.data.desktopDatasource = this.designerDatasourceModel.ID;

        let isAvalible = this.componentService.isIdAvalible(this.data.id, this.data.uuid);
        if (isAvalible) {
            this.showSettingPopup = false;
            this.eventEmitterService.applyHistory(this.componentService.historyActions.save);
 
        }
        else {
            this.componentService.showAlert("Bu id başka bir yerde kullanılıyor, lütfen farklı bir id belirtiniz.", "warning", 5000)
        }
        this.showDisplay = !this.isEdit ? (this.data.show && !this.data.hide && this.data.outsideVisible ? "flex" : "none") : "flex";
    }

    cancelSetting() {
        let msg = "<i>Yaptığınız değişikleri kaydetmeden çıkmak istediğinizden emin misiniz?</i>"
        let result = confirm(msg, "Uyarı!");
        result.then((dialogResult) => {
            if (dialogResult) {
                this.showSettingPopup = false;
                this.eventEmitterService.applyHistory(this.componentService.historyActions.undo);
            }
        });
    }

    resetDataSource() {
        this.data.dataSource = [];
        this.data.dataSourceKey = ""; 
    }

    onChangeDatasourceValue(event) {
        if (event.value) {

            this.designerDatasourceModel = this.designerDataSourceList.find(x => x.ID == event.value);
             
        }
    }


    onValueChanged(event) {
         
    }

    onDataSourceValueChanged(event) {
        this.data.dataSourceKey = event.value
    }

    public executeScript() {

        if (this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            this.genericServiceExecuteScript(this.data.dataStorage.query, this.data.dataStorage.connectionId);
        }
        else if (this.data.dataSource && this.data.dataSource.length > 0) {

            let filteredData = this.data.dataSource.filter(f => f.lang == this.lang);
            if (filteredData && filteredData.length > 0)
                this.data.dataSource = filteredData;
             
        }
        else if (this.data.desktopDatasource != null) {

            this.fillDesignerDataSource();

        }
    }

    public genericServiceExecuteScript(_Query, _ConnectionId) {
        

        let curentComponents = this.componentService.getComponentList(this.componentService.currentForm.components);
        var pattern = /\{(\w)+\}/ig;
        var patt = new RegExp(pattern);
        if (patt.test(_Query)) {
            let _match = _Query.match(pattern);
            if (_match.length > 0) {
                _match.forEach(f => {
                    let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (_k) {
                        let _value = _k.value == null ? "" : _k.value;
                        _Query = _Query.replace(f, _value);
                    }
                });
            }
            //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
        }


        this.genericService.executeQuerySecurited(_Query, _ConnectionId).subscribe((result: any) => {

            if (result && result.length > 0) {
                let cols = Object.keys(result[0]);
                if (cols) {
                  
                }
                this.data.dataSource = result;
                this.changeDetectorRef.detectChanges();
            }
            else {
                this.data.dataSource = [];
            } 
        });
    }


    openDataPopup(type) {
        this.isFromDataSource = type == "1";
        if (this.isFromDataSource) {
            this.data.isManual = false;
            let selectboxes = this.componentService.getComponentsByType("selectbox");
            this.selecboxComponents = [];
            if (selectboxes) {
                selectboxes.forEach(item => {
                    if (item.id != this.data.id) {
                        let element: any = {};
                        element.id = item.id;
                        element.name = this.componentService.getLang(this.lang, item.language).Label;
                        this.selecboxComponents.push(element);
                    }
                });
            } 
        }
        else {
            this.data.isManual = true;
        }

        this.dataSourcePopup = true;
    }

    saveDataSource() {
        if (this.data.isManual && this.data.dataStorage.connectionId > 0 && this.data.dataStorage.query != "") {
            let msg = "<i>Manuel veri eklediğiniz için sql script ve bağlantı adresi silinecektir! Devam edilsin mi?</i>";
            let result = confirm(msg, "Uyarı!");
            result.then((dialogResult) => {
                
                if (dialogResult) {
                    this.data.dataStorage.connectionId = 0;
                    this.data.dataStorage.query = "";
                    this.dataSourcePopup = false;
                    this.data.valueExpr = "value";
                    //this.data.displayExpr = "text";
                }
            });
        }
        else {

            if (this.data.isManual) {
                this.data.valueExpr = "value";
                //this.data.displayExpr = "text";
            }

            this.dataSourcePopup = false;
        }
    }

    resetDataSources() {
        this.data.dataSourceKey = "";
        this.data.dataSource = []; 

        notify("Data Sıfırlandı", "success", 3000);
    }

    apply() {
        this.executeScript();
    }

    prepareGrid() {
        new CustomStore({
            key: "Id",
            load: () => this.data.dataSource,
            insert: (values) => this.insert(JSON.stringify(values)),
            update: (key, values) => this.update(key, JSON.stringify(values)),
            remove: (key) => this.remove(key)
        });
    }

    executeExp() {
        this.eventEmitterService.executeExpretion();
    }

    insert(values: any = {}): any {
        this.data.dataSource.push(values);
    }

    update(key, values: any = {}): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource[index] = values;
    }

    remove(key): any {
        const index = this.data.dataSource.findIndex(item => item.key === key);
        this.data.dataSource.splice(index, 1);
    }

    monacoOnInit() {
        this.isMonacoLoaded = true;
        let monaco = window['monaco'];
        this.setCustomKeywords(monaco);
    }

    registeredLanguage: any;
    setCustomKeywords(monaco) {
        if (this.registeredLanguage)
            this.registeredLanguage.dispose();

        this.registeredLanguage = monaco.languages.registerCompletionItemProvider('plaintext', {
            provideCompletionItems: () => {
                var suggestions = [];
                let elements = this.componentService.getComponentList(this.componentService.currentForm.components).map(m => m.id);
                for (let i = 0; i < elements.length; i++) {
                    suggestions.push({
                        label: elements[i],
                        kind: monaco.languages.CompletionItemKind.Snippet,
                        insertText: "" + elements[i] + "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        documentation: 'method'
                    })
                }
                return { suggestions: suggestions };
            }
        });
    }

    formElements: any;
 

}
