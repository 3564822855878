import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { EventEmitterService, AppInfoService, TaskService, ReportService, AuthService, GenericService } from "../../shared/services";
import { InstanceService } from "../../shared/services/data/instance.service";
import { TaskModel } from "../../shared/models/task-model";
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import { ComponentService } from "../../formdesigner/component.service";
import { userInfo } from "os";
import { NodeModel } from "src/app/shared/models/node-model";
import { ReportModel } from "src/app/shared/models/report-model";
import { parseBool } from "devexpress-dashboard/model/index.metadata";

var misc: any = {
    sidebar_mini_active: true
}

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsedProcess?: boolean;
    isCollapsedReport?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: "/report",
        title: "Rapor",
        type: "link",
        icontype: ""
    },
    {
        path: "/reportdesigner",
        title: "Rapor Tasarım",
        type: "link",
        icontype: ""
    },
    {
        path: "/calendar",
        title: "Takvim",
        type: "link",
        icontype: ""
    },
    {
        path: "/home",
        title: "",
        type: "link",
        icontype: ""
    },

    //{
    //  path: "/pages",
    //  title: "Pages",
    //  type: "sub",
    //  icontype: "design_image",
    //  collapse: "pages",
    //  isCollapsed: true,
    //  children: [
    //    { path: "pricing", title: "Pricing Page", ab: "PP" },
    //    { path: "timeline", title: "Timeline Page", ab: "TP" },
    //    { path: "login", title: "Login Page", ab: "LP" },
    //    { path: "register", title: "Register Page", ab: "RP" },
    //    { path: "lock", title: "Lock Screen Page", ab: "LSP" },
    //    { path: "user", title: "User Page", ab: "UP" }
    //  ]
    //}
    //{
    //  path: "/calendar",
    //  title: "Calendar",
    //  type: "link",
    //  icontype: "media-1_album"
    //}
];


@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    reportModel: ReportModel;
    public isCollapsed = true;
    public isCollapsedProcess = true;
    public isCollapsedReport = true;
    nodeModel: NodeModel;

    selectedTask: any;
    selectedSchemaId: number;

    curerentInstanceId: any;
    showFormPopup: boolean = false;
    showNodeFormPopop: boolean = false;
    selectedForm: any;
    sqlParseParametersValues: any = [];

    selectedReportId: any = 0;
    selectedReportName: string = "Rapor Seçilmedi";
    showReportFilterPopup: any = false;
    showReportTypePopup: any = false;
    selectedReportType: number = 0;
    selectedReportTypeName: string = "Rapor Tipi Seçilmedi";
    reportTypes = {
        grid: 1,
        pivot: 2,
        white: 3,
        dashboard: 4
    }
    connections: any = [];

    designerMenuList: any = [];
    designerMenuList1: any[] = [

        {
            id: 1,
            isCollapsed: true,
            name: "Menu 1",
            items: [
                {
                    id: 1,
                    name: "Alt Kırılım 1",
                    isBrowser: false,
                    isCollapsed: true,
                    items: [
                        {
                            id: "10631366-b211-4d40-8ea7-8615920b2637",
                            formId: 66,
                            name: "Browser 1",
                            isBrowser: true
                        },
                        {
                            id: 2,
                            name: "Browser 2",
                            isBrowser: true
                        }
                    ]
                },

                {
                    id: 2,
                    name: "Alt Kırılım 2",
                    isBrowser: false,
                    isCollapsed: true,
                    items: [
                        {
                            id: 1,
                            name: "Browser 3",
                            isBrowser: true
                        },
                        {
                            id: 2,
                            name: "Browser 4",
                            isBrowser: true
                        }
                    ]
                },




            ]

        }

    ];


    constructor(private toastr: ToastrService, public taskService: TaskService,
        public instanceService: InstanceService, public authService: AuthService,
        private reportingService: ReportService,
        public appInfo: AppInfoService,
        private genericService: GenericService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private componentService: ComponentService, public changeDetectorRef: ChangeDetectorRef) {

        this.reportModel = new ReportModel();
        this.reportModel.useInWeb = true;
        this.reportModel.useInMobile = true;

        this.nodeModel = new NodeModel();
        this.nodeModel.orderNo = 1;
    }

    schemeList: any = [];
    reports: any = [];
    sidebarLogoImage: any = "";
    profileImageLink: any;
    ngOnInit() {
        let userSession = localStorage.getItem("localUserSession");
        let entity = JSON.parse(userSession);

        this.sidebarLogoImage = entity.systemInfos.CompanyLogo == null || entity.systemInfos.CompanyLogo == "null" || entity.systemInfos.CompanyLogo == "" ? "./assets/img/logo/river_logo.svg" : entity.systemInfos.CompanyLogo;
        this.connections = entity.connections;
        this.reportModel.connectionId = this.connections ? this.connections[0].id : 0;

        this.changeSidebarColor(this.appInfo.currentUser.color, false)

        this.sidebarSizeStatu();

        this.getReportList();
        this.getMenuList();

        this.eventEmitterService.invokeGetAllSchemaListEvent.subscribe(sub => {
            this.getSchemaList(sub.data);
            setTimeout(() => {
                try {
                    this.changeDetectorRef.detectChanges();
                } catch { }
            }, 300);
        });

        this.eventEmitterService.subsStartInstance = this.eventEmitterService.invokeStartMenuFunction.subscribe(sub => {
            this.selectItemDesignerMenu(null, sub.data.menuFormGuid, sub.data.name, sub.data.formId, true, sub.data.schemeId, true);
        });

        //  this.invokeFunctions();
        this.fillFavorite();
    }
    getFormStyle() {
        let myStyles = {
            'background': "url(" + (this.appInfo.currentUser.photo != null &&
                this.appInfo.currentUser.photo != "" &&
                this.appInfo.currentUser.photo != "ZABhAHQAYQA6AGkAbQBhAGcAZQAvAHAAbgBnADsAYgBhAHMAZQA2ADQALABpAFYAQgA=" ?
                'data:image/png;base64,' + this.appInfo.currentUser.photo :
                "/assets/img/team2.jpg") + ") no-repeat #fff",
            'background-size': "cover"
        };
        return myStyles;
    }
    mouseEnterProcess(itemIndex, menuId) {
        // 
        var display = this.schemeList.find(x => x.id == menuId).items[itemIndex].isDisplay;
        this.schemeList.find(x => x.id == menuId).items[itemIndex].isDisplay = true;
    }

    mouseLeaveProcess(itemIndex, menuId) {
        // 
        var display = this.schemeList.find(x => x.id == menuId).items[itemIndex].isDisplay;
        this.schemeList.find(x => x.id == menuId).items[itemIndex].isDisplay = false;
    }

    getClassByValue(itemIndex, menuId, isFav) {
        //
        var display = this.schemeList.find(x => x.id == menuId).items[itemIndex].isDisplay;
        if (isFav) {
            return "star-display-show"
        }
        else {
            if (typeof display === "undefined" || !display) {
                return "star-display-hide"
            }
            else {
                return "star-display-show"
            }
        }

    }

    getMenuList() {
        this.taskService.getSidebarMenu().subscribe(data => {

            this.designerMenuList = [];
            for (var i = 0; i < data.length; i++) {
                //this.designerMenuList = data;
                let _obj = { schemaId: data[i].schemaId, data: JSON.parse(data[i].sidebarJson) };
                this.designerMenuList.push(_obj);
            }
        });
    }

    getReportList() {
        this.reportingService.getReports().subscribe(data => {

            this.reports = data;
            for (var i = 0; i < this.reports.length; i++) {
                this.reports[i].isCollapsed = true;
            }
        });
    }

    getSchemaList(allSchemaList) {
        //this.schemeList = this.componentService.allSchemaList;

        this.schemeList = allSchemaList;

        this.schemeList = this.schemeList.filter(x => x.isMenu == false);
        for (var i = 0; i < this.schemeList.length; i++) {
            this.schemeList[i].isCollapsed = true;

            if (this.schemeList[i].items.length > 0) {
                //isMenu    
                this.schemeList[i].items.forEach(function (item, index, object) {
                    if (item.isMenu == true) {
                        object.splice(index, 1);
                    }
                });

            }

        }

    }



    favoriteMenuItems: any;
    fillFavorite() {

        this.taskService.GetFavoriteList().subscribe((r: any) => {
            this.favoriteMenuItems = r;
        }, error => {
            // this.componentService.showAlert("Üzgünüz, beklenmedik bir hata oluştu,", "error", 2000);
        });
    }

    selectItem(e, id, name, isScheme) {


        var isStarElement = e.target.className.indexOf("star-element") > -1;
        var unStarElementClick = e.target.className.indexOf("fa-star star-element") > -1;
        if (isStarElement /*&& e.itemData.isScheme*/) {

            // this.starChange(e);

            if (this.favoriteMenuItems.length > 2 && !unStarElementClick) {
                let msg = "<i> En fazla üç tane favori ekleyebilirsiniz. İlk favoriyi silmek istiyor musunuz?</i>"
                let result = confirm(msg, "Favori Eklenecek!");
                result.then((dialogResult) => {
                    if (dialogResult) {
                        this.starChange(id, true);
                    }
                });
            }
            else
                this.starChange(id, false);

        } else if (isScheme) {
            this.selectedTask = name;
            this.selectedSchemaId = parseInt(id);
            this.componentService.selectedSchemaId = this.selectedSchemaId;
            if (this.router.url.toLowerCase() == "/home") {
                let data_scheme = {
                    id: id,
                    name: name
                };
                this.eventEmitterService.createTaskAndStartForm(data_scheme);

            } else {
                this.router.navigate(['home'], { state: { id: id, name: name, isScheme: isScheme } });
            }

            // this.createTask();
        }
    }
    //firstFavorite: any;
    starChange(id, deleteFirstFavorite) {
        var _this = this;
        this.selectedSchemaId = parseInt(id);
        this.componentService.selectedSchemaId = this.selectedSchemaId;
        this.taskService.SetFavorite(this.selectedSchemaId).subscribe((k: any) => {
            var r = k.list;
            this.eventEmitterService.updateFavoriteChange(r);

            //this.getSchemaLis t();

            if (deleteFirstFavorite) {
                var firstFavorite = r[0].id;

                //var firstFavorite = -1;
                //for (var i = 0; i <.length; i++) {
                //  for (var k = 0; k < r.length; k++) {
                //    var itemNo = _this.favoriteMenuItems[i].items.findIndex(x => x.id == r[k].id);
                //    if (itemNo > -1) {
                //      firstFavorite = _this.favoriteMenuItems[i];
                //    }
                //  }
                //}

                //   var objIndex = _this.schemeList[0].items.findIndex((x => x.id == firstFavorite));

                /*
                 var objIndex = _this.schemeList.findIndex((obj => obj.items.findIndex(x => x.id == firstFavorite)));
                var itemIndex = _this.schemeList[objIndex].items.findIndex(x => x.id == firstFavorite);
                */
                var objItem = 0;
                var indexItem = 0;
                for (var i = 0; i < this.schemeList.length; i++) {

                    var itemNo = this.schemeList[i].items.findIndex(x => x.id == k.remove);
                    if (itemNo > -1) {
                        _this.schemeList[i].items[itemNo].isFavorite = false;
                        break;
                    }

                }



                //  var objIndex = _this.schemeList.findIndex(this.checkSchemeList);
                // var itemIndex = _this.schemeList[objIndex].items.findIndex(x => x.id == this.firstFavorite);
                //  _this.schemeList[objIndex].items[itemIndex].isFavorite = false;
                //this.firstFavorite = -1;
            }

            for (var i = 0; i < this.schemeList.length; i++) {

                var itemNo = this.schemeList[i].items.findIndex(x => x.id == this.selectedSchemaId);
                if (itemNo > -1) {
                    var selectedScheme = r.find(x => x.id === _this.selectedSchemaId);
                    this.schemeList[i].items[itemNo].isFavorite = (typeof selectedScheme !== "undefined" && selectedScheme !== null);
                    //break;
                }

            }



            //var selectedScheme = r.find(x => x.id === _this.selectedSchemeI d);
            _this.favoriteMenuItems = r;
            //// this.schemeList = r.filter(x => x.id === this.selectedSchemeI d);
            //e.event.target.className = selectedScheme ? "fas fa-star star-element" : "fas fa-star unstar-element";


        }, error => {
            this.componentService.showAlert("Üzgünüz, beklenmedik bir hata oluştu,", "error", 2000);
        });

    }
    //createTask() {
    //    //this.showCreateTaskPopup = true;
    //    let msg = "<i>Yeni talep oluşturulacak. Devam edilsin mi?</i>"
    //    let result = confirm(msg, "Talep Oluşturulacak!");
    //    result.then((dialogResult) => {
    //        if (dialogResult) {
    //            this.startTask();
    //        }
    //    });

    //}


    //startTask() {
    //    this.spinner.show("mainspinner");
    //    let model = new TaskModel();
    //    model.UserId = this.appInfo.currentUser.id;
    //    model.SchemeId = this.selectedSchemeI d;
    //    this.instanceService.startInstance(model).subscribe((instanceId: any) => {
    //        if (instanceId > 0) {
    //            this.curerentInstanceId = instanceId;
    //            let requestCount = 1;
    //            let requestInterval = setInterval(f => {
    //                requestCount++;
    //                this.instanceService.getCurrentForm(instanceId, 0, false).subscribe((form: any) => {
    //                    //this.spinner.hide("mainspinner");
    //                    if (form.hasForm) {
    //                        this.selectedForm = form;
    //                        this.selectedForm.readonly = false;
    //                        this.componentService.currentForm.isReadonly = false;
    //                        this.componentService.isSentFormToDb = false;
    //                        this.showFormPopup = true;
    //                        this.spinner.hide("mainspinner");
    //                        clearInterval(requestInterval);
    //                    }
    //                }, error => {
    //                    console.log(error);
    //                    //this.spinner.hide("mainspinner");
    //                    this.componentService.showAlert("Üzgünüz, beklenmedik bir hata oluştu,", "error", 2000);
    //                });

    //                if (requestCount >= 30) {
    //                    this.spinner.hide("mainspinner");
    //                    this.componentService.showAlert("Süreç başlatıldı. Görev listenizden takip edebilirsiniz,", "success", 2000);
    //                    clearInterval(requestInterval);
    //                }
    //            }, 500);
    //            //this.showForm();
    //        }
    //        else {
    //            this.componentService.showAlert("Süreç başlatılırken bir hata oluştu,", "error", 2000);
    //            this.spinner.hide("mainspinner");
    //        }
    //    }, error => {
    //        this.spinner.hide("mainspinner");
    //        this.componentService.showAlert("Üzgünüz, beklenmedik bir hata oluştu,", "error", 2000);
    //    });
    //}


    convertBinaryToBase64() {
        let TYPED_ARRAY = new Uint8Array(this.appInfo.currentUser.photoByte); //binary data gelecek
        const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
        let base64String = btoa(STRING_CHAR);
        return 'data:image/jpg;base64,' + this.appInfo.currentUser.photo; //this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64String);
        /*const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
            return data + String.fromCharCode(byte);
        }, ''));*/
    }


    ///////////////////////////
    ////////// MENU /////////
    /////////////////////////
    selectItemDesignerMenu(event, id, name, formId, isBrowser, schemaId, isOpenForm = false) {
        
        if (isBrowser) {
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            }
            this.sidebarSizeStatu();
            this.router.onSameUrlNavigation = 'reload';
            //  this.router.runGuardsAndResolvers= 'always'
            this.router.navigate(['/menu/' + id + "/" + formId + "/" + schemaId], { state: { formName: name, isOpenForm: isOpenForm } });
        }
    }






    ///////////////////////////
    ////////// RAPOR /////////
    /////////////////////////

    selectItemReport(event, id, name, isReport) {


        if (this.router.url.toLowerCase() == "/report") {
            let r = {
                event: event, id: id, name: name, isReport: isReport
            };
            this.eventEmitterService.pageWithRouteChange(r);
        } else {
            this.router.navigate(['report'], { state: { id: id, name: name, isReport: isReport } });
        }

        //  this.eventEmitterService.updateReportClickChang e(r);
        //this.router.navigateByUrl('/reportdesigner'); 
        //   this.router.navigateByUrl('/reportdesigner'); 
        // this.reportDesignerComponent.selectIte mReport(event, id, name, isReport);
    }














    ///////////////////////////
    /// Tema Fonksiyonları ///
    /////////////////////////


    sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if (new Date().getTime() - start > milliseconds) {
                break;
            }
        }
    }
    handleValueChange(e) {
        this.changeSidebarColor(e.value, true)
        // Event handling commands go here
    }
    currentColor: string = "#fffffff";
    public sidebarColor: string = "red";
    changeSidebarColor(color, userColorService = true) {

        let dataColor =//color;#A7925B
            color == "yellow" ? "#A7925B" : (color == "blue" ? "#0D87C0" : (color == "orange" ? "#DB2F00" :
                (color == "red" ? "#87222C" : (color == "green" ? "#097735" : (color == "black" ? "#292929" : "#DB2F00")))));

        this.currentColor = color;

        //color="#4CAF50";
        var sidebar = <HTMLElement>document.querySelector('.sidebar');

        if (sidebar != undefined) {
            if (userColorService) {//updateColor
                let model = { id: this.appInfo.currentUser.id, color: color };

                this.authService.updateColor(model).subscribe((result: any) => {

                    if (result) {
                        this.appInfo.currentUser.color = color;
                        this.sidebarColor = color;
                        sidebar.setAttribute('data-color', dataColor);
                        sidebar.setAttribute('style', " --tooltip-color: " + dataColor);

                        let entity = JSON.parse(localStorage.getItem("localUserSession"));
                        entity.currentUser.color = color;
                        localStorage.setItem("localUserSession", JSON.stringify(entity));
                    }
                    else {
                        this.sidebarColor = color;
                        sidebar.setAttribute('data-color', dataColor);
                        sidebar.setAttribute('style', " --tooltip-color: " + dataColor);
                    }
                    this.eventEmitterService.colorSetData(null);
                });

            }
            else {
                this.sidebarColor = color;
                sidebar.setAttribute('data-color', dataColor);
                sidebar.setAttribute('style', " --tooltip-color: " + dataColor);
                this.eventEmitterService.colorSetData(null);
            }
        }
        else
            this.eventEmitterService.colorSetData(null);
    }

    myFunc(event, menuitem) {

        event.preventDefault();
        event.stopPropagation();
        this.sleep(10);
        //let f = 0;
        if (menuitem)
            if (menuitem.isCollapsing === undefined) {
                menuitem.isCollapsing = true;

                menuitem.isCollapsed = typeof menuitem.isCollapsed === "undefined" ? true : !menuitem.isCollapsed;

                var element = event.target;
                while (
                    element.getAttribute("data-toggle") != "collapse" &&
                    element != document.getElementsByTagName("html")[0]
                ) {
                    element = element.parentNode;
                }
                element = element.parentNode.children[1];

                if (
                    element.classList.contains("collapse") &&
                    !element.classList.contains("show")
                ) {
                    element.classList = "before-collapsing";
                    var style = element.scrollHeight;

                    element.classList = "collapsing";
                    setTimeout(function () {
                        element.setAttribute("style", "height:" + style + "px");
                    }, 1);
                    setTimeout(function () {
                        element.classList = "collapse show";
                        element.removeAttribute("style");
                        menuitem.isCollapsing = undefined;
                    }, 350);
                } else {
                    var style = element.scrollHeight;
                    setTimeout(function () {
                        element.setAttribute("style", "height:" + (style + 20) + "px");
                    }, 3);
                    setTimeout(function () {
                        element.classList = "collapsing";
                    }, 3);
                    setTimeout(function () {
                        element.removeAttribute("style");
                    }, 20);
                    setTimeout(function () {
                        element.classList = "collapse";
                        menuitem.isCollapsing = undefined;
                    }, 400);
                }
            }
    }

    logoMiniDisplay: any = false;
    sidebarSizeStatu() {
        const body = document.getElementsByTagName('body')[0];
        let sidebarMinimize = sessionStorage.getItem("sessionSidebarMinimize");
        if (sidebarMinimize == null) {
            this.minimizeSidebar();
        } else {
            misc.sidebar_mini_active = (sessionStorage.getItem("sessionSidebarMinimize") == "true");
            this.logoMiniDisplay = (sessionStorage.getItem("sessionSidebarMinimize") == "true");
            if (misc.sidebar_mini_active)
                body.classList.add('sidebar-mini');
            else
                body.classList.remove('sidebar-mini');
        }
    }

    minimizeSidebar() {

        const body = document.getElementsByTagName('body')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
            this.logoMiniDisplay = false;
            // this.showSidebarMessage('Sidebar mini deactivated...');
        } else {
            body.classList.add('sidebar-mini');
            //   this.showSidebarMessage('Sidebar mini activated...');
            misc.sidebar_mini_active = true;
            this.logoMiniDisplay = true;
        }

        sessionStorage.setItem("sessionSidebarMinimize", misc.sidebar_mini_active);

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    showSidebarMessage(message) {
        this.toastr.show(
            '<span class="now-ui-icons ui-1_bell-53"></span>', message,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-danger alert-with-icon",
                positionClass: "toast-top-right"
            }
        );
    }
}
